import { MutationTree } from 'vuex';
import { CourseState } from './types';
import { CourseDefinition } from '@/domain/Course';

export const mutations: MutationTree<CourseState> = {
  setHasDownloaded(state: CourseState, flag: boolean) {
    state.hasDownloaded = flag;
  },
  setIsDownloading(state: CourseState, flag: boolean) {
    state.isDownloading = flag;
  },
  setCourseList(state: CourseState, newList: Array<CourseDefinition>) {
    state.courses = newList;
  },
};
