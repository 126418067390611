var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.view == _setup.View.TABLE)?_c('v-data-table',{staticClass:"black--text",attrs:{"headers":_setup.headers,"items":_setup.items,"fixed-header":"","hide-default-footer":"","disable-pagination":"","data-cy":"open-responses-table"},scopedSlots:_vm._u([{key:`item.${_setup.Header.NAME}`,fn:function({ item }){return [_c(_setup.StudentName,{attrs:{"student":_setup.studentsMap[item[_setup.Header.NAME]],"show":_vm.options.includes(_setup.Option.NAME)}})]}},{key:`item.${_setup.Header.RESPONSE}`,fn:function({ item }){return [_c('span',{staticClass:"content-body",staticStyle:{"cursor":"pointer"},domProps:{"innerHTML":_vm._s(item[_setup.Header.RESPONSE])},on:{"click":function($event){return _setup.emit('openResponse', $event)}}})]}},{key:`item.${_setup.Header.SCORE}`,fn:function({ item }){return [_c('v-select',{staticClass:"my-2",attrs:{"value":item[_setup.Header.SCORE],"items":_setup.scale,"filled":"","hide-details":"","menu-props":{
        offsetY: true,
        offsetOverflow: true,
      },"disabled":_vm.updating == _vm.logActionsMap[item[_setup.Header.NAME]]?.prLog?.id || _vm.disabled,"loading":_vm.updating == _vm.logActionsMap[item[_setup.Header.NAME]]?.prLog?.id,"data-cy":"score-selector"},on:{"input":function($event){return _setup.updateScore(
          item[_setup.Header.NAME],
          _vm.logActionsMap[item[_setup.Header.NAME]]?.prLog,
          $event
        )}}})]}},{key:`item.${_setup.Header.TEACHER_COMMENT}`,fn:function({ item }){return [_c('v-autocomplete',{staticClass:"my-2",attrs:{"items":item.suggestComments,"filled":"","flat":"","hide-details":"","hide-no-data":"","value":item[_setup.Header.TEACHER_COMMENT],"disabled":_vm.updating == _vm.logActionsMap[item[_setup.Header.NAME]]?.prLog?.id || _vm.disabled,"loading":_vm.updating == _vm.logActionsMap[item[_setup.Header.NAME]]?.prLog?.id,"data-cy":"comment-input"},on:{"blur":function($event){return _setup.updateComment(
          item[_setup.Header.NAME],
          _vm.logActionsMap[item[_setup.Header.NAME]]?.prLog,
          $event.target.value
        )},"keydown":function($event){$event.stopPropagation();}}})]}}],null,true)}):(_vm.view == _setup.View.CARD)?_c('v-row',[_vm._l((_setup.items),function(item,i){return [_c('v-col',{key:i,attrs:{"cols":"12","md":"4"}},[_c('v-card',{attrs:{"loading":_vm.updating == _vm.logActionsMap[item[_setup.Header.NAME]]?.prLog?.id,"data-cy":"open-response-card"}},[_c('div',{staticClass:"d-flex align-center justify-space-between text-subtitle-1 black--text neutral lighten-2 pa-2"},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"gap":"4px"}},[_c('v-icon',[_vm._v("mdi-account")]),_c(_setup.StudentName,{attrs:{"student":_setup.studentsMap[item[_setup.Header.NAME]],"show":_vm.options.includes(_setup.Option.NAME)}})],1),(item[_setup.Header.SCORE] || item[_setup.Header.SCORE] == 0)?_c(_setup.ScoreChip,{attrs:{"score":item[_setup.Header.SCORE],"color":"userDroppable","textColor":"black","data-cy":"response-score-chip"}}):_vm._e()],1),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-card',{attrs:{"elevation":"0","color":"transparent","height":"350","tile":"","data-cy":"response-card-body"}},[_c('v-card-text',{staticClass:"content-body",domProps:{"innerHTML":_vm._s(item[_setup.Header.RESPONSE])}}),(hover)?_c('v-fade-transition',[_c('v-overlay',{staticClass:"d-block",attrs:{"absolute":"","id":`log-${item[_setup.Header.NAME]}`}},[_c('div',{staticClass:"d-flex flex-column",staticStyle:{"height":"100%"}},[_c('v-card-text',[_c(_setup.OpenResponseScoreChipGroup,{staticClass:"white--text",attrs:{"value":item[_setup.Header.SCORE],"disabled":_vm.updating ==
                          _vm.logActionsMap[item[_setup.Header.NAME]]?.prLog?.id ||
                        _vm.disabled},on:{"input":function($event){return _setup.updateScore(
                          item[_setup.Header.NAME],
                          _vm.logActionsMap[item[_setup.Header.NAME]]?.prLog,
                          $event
                        )}}})],1),_c('v-spacer'),_c('v-card-actions',[_c('v-spacer'),_c('v-menu',{attrs:{"attach":`#log-${item[_setup.Header.NAME]}`,"close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"white","disabled":_vm.updating ==
                              _vm.logActionsMap[item[_setup.Header.NAME]]?.prLog?.id ||
                            _vm.disabled,"data-cy":"comment-button"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"bluePrimary700"}},[_vm._v(" mdi-comment-outline ")])],1)]}}],null,true)},[_c('v-card',[_c('v-card-text',[_c(_setup.OpenResponseComment,{attrs:{"value":item[_setup.Header.TEACHER_COMMENT],"comments":item.suggestComments,"loading":_vm.updating ==
                              _vm.logActionsMap[item[_setup.Header.NAME]]?.prLog?.id,"disabled":_vm.updating ==
                                _vm.logActionsMap[item[_setup.Header.NAME]]?.prLog?.id ||
                              _vm.disabled},on:{"input":function($event){return _setup.updateComment(
                                item[_setup.Header.NAME],
                                _vm.logActionsMap[item[_setup.Header.NAME]]?.prLog,
                                $event,
                                item
                              )}}})],1)],1)],1)],1)],1)])],1):_vm._e()],1)]}}],null,true)})],1)],1)]})],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }