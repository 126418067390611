import { ProblemSetDefinition, ProblemSetType } from '@/domain/ProblemSet';
import { getContentType } from './builder.util';
import { ContentType } from '@/domain/Content';
import { ProblemDefinition } from '@/domain/Problem';
import { ProblemSetNode } from '@/composables/builder';

export interface ProblemSetTypeItem {
  text: string;
  value: ProblemSetType;
}

const isSkillBuilder = (ps: ProblemSetDefinition): boolean => {
  // Pseudo Skillbuilders (SHOW_AS_SKILL_BUILDER) param is migrated as the
  // isResearch attribute.
  // FIXME: Make the distinction between research vs pseudo?
  return (
    ps.problemSetType === ProblemSetType.SKILL_BUILDER_LINEAR ||
    ps.problemSetType === ProblemSetType.SKILL_BUILDER_RANDOM ||
    ps.attributes?.isResearch === true
  );
};

const getProblemSetTypeItems = (
  problemSetTypes: ProblemSetType[]
): ProblemSetTypeItem[] => {
  return problemSetTypes.map((problemSetType) => ({
    text: getProblemSetTypeDisplayName(problemSetType),
    value: problemSetType,
  }));
};

// FIXME: Finalize a list of SDK3 PS Types.
const getProblemSetTypeDisplayName = (
  problemSetType: ProblemSetType
): string => {
  let name = '';
  switch (problemSetType) {
    case ProblemSetType.LINEAR_COMPLETE_ALL:
      name = 'Complete all (linear)';
      break;
    case ProblemSetType.RANDOM_COMPLETE_ALL:
      name = 'Complete all (random)';
      break;
    case ProblemSetType.MULTI_PART_PROBLEM_SET:
      name = 'Multi Part Problem';
      break;
    case ProblemSetType.SKILL_BUILDER_RANDOM:
      name = 'Skillbuilder (random)';
      break;
    case ProblemSetType.SKILL_BUILDER_LINEAR:
      name = 'Skillbuilder (linear)';
      break;
    case ProblemSetType.RANDOM_COMPLETE_ONE:
      name = 'Choose condition (random)';
      break;
    case ProblemSetType.IF_THEN_ELSE:
      name = 'If-Then-Else';
      break;
    case ProblemSetType.STUDENT_CHOICE:
      name = 'Complete all (student choice)';
      break;
    default:
      break;
  }
  return name;
};

const getProblemCerisFromStructure = (
  structure: ProblemSetNode[]
): string[] => {
  const problems = [];
  for (const node of structure) {
    if (getContentType(node.xref) == ContentType.PROBLEM) {
      problems.push(node.xref);
    } else if (node.children) {
      problems.push(...getProblemCerisFromStructure(node.children));
    }
  }
  return problems;
};

const getProblemDefinitionsFromStructure = (
  problemSet: ProblemSetDefinition,
  problemSetMap: Record<string, ProblemSetDefinition>,
  problemMap: Record<string, ProblemDefinition>
): ProblemDefinition[] => {
  const problems: ProblemDefinition[] = [];
  const children = problemSet.children ?? [];
  for (const child of children) {
    const definition: ProblemDefinition | ProblemSetDefinition | undefined =
      problemSetMap[child] ?? problemMap[child];
    // eslint-disable-next-line
    // @ts-ignore
    if (definition?.contentType == ContentType.PROBLEM) {
      problems.push(definition);
    } else if (definition?.contentType == ContentType.PROBLEM_SET) {
      const subproblems = getProblemDefinitionsFromStructure(
        definition,
        problemSetMap,
        problemMap
      );
      problems.push(...subproblems);
    }
  }
  return problems;
};

// FIXME: Figure out IF this should be a count of UNIQUE Problems? For example, a Problem
// found in multiple child Problem Sets?
const getProblemCountFromStructure = (structure: ProblemSetNode[]): number => {
  const problems = getProblemCerisFromStructure(structure);
  return problems.length;
};

export {
  isSkillBuilder,
  getProblemSetTypeItems,
  getProblemSetTypeDisplayName,
  getProblemCerisFromStructure,
  getProblemCountFromStructure,
  getProblemDefinitionsFromStructure,
};
