import { AssignmentDefinition } from '@/domain/Assignment';
import { ActionType, IAction } from '@/domain/Action';

const hasSeenAnswer = (actions: IAction[]): boolean => {
  return actions.some((action) => {
    return [
      ActionType.ANSWER_REQUESTED_ACTION,
      ActionType.EXPLANATION_REQUESTED_ACTION,
    ].includes(action.actionType);
  });
};

const isInTestMode = (assignment: AssignmentDefinition): boolean => {
  const settings = assignment.settings;
  const correctnessFeedback = settings?.correctnessFeedback;
  const tutoringAccessible = settings?.tutoringAccessible;
  // If the properties exist then make sure they are set to FALSE.
  // If they do not exist or are not set to FALSE then the Assignment is NOT in test mode.
  return correctnessFeedback === false && tutoringAccessible === false;
};

//////////////////////////
// Numbers & Formatting //
//////////////////////////
// Multiplies by 100 and rounds to (max) two decimal places
// https://stackoverflow.com/questions/11832914/how-to-round-to-at-most-2-decimal-places-if-necessary
const decimalToPercent = (decimal: number): number => {
  return Math.round((decimal + Number.EPSILON) * 10000) / 100;
};

const formatPercentage = (decimal: number): string => {
  const percent = Math.round(decimalToPercent(decimal));
  return `${percent}%`;
};

export { hasSeenAnswer, isInTestMode, formatPercentage };
