import { IFolderMember } from '@/domain/Folder';
import { IProblemSetMember } from '@/domain/ProblemSet';

export enum PageView {
  LESSON_VIEW = 1,
  SEARCH_RESULTS = 2,
  MAIN_HIERARCHY = 3,
}

export function getPathParam(
  path: (IFolderMember | IProblemSetMember)[]
): string {
  return path?.map((element) => element.xref).join(',');
}
