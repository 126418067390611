import { render, staticRenderFns } from "./OpenResponseCommentGroup.vue?vue&type=template&id=1a22075c&scoped=true&"
import script from "./OpenResponseCommentGroup.vue?vue&type=script&lang=ts&setup=true&"
export * from "./OpenResponseCommentGroup.vue?vue&type=script&lang=ts&setup=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1a22075c",
  null
  
)

export default component.exports