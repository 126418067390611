import { AssignmentDefinition } from '@/domain/Assignment';
import { computed, Ref } from 'vue';

interface AssignmentReportLogic {
  timeLimitInMinutes: Ref<number | undefined>;
}

export function useAssignmentReportLogic(
  assignment?: Ref<AssignmentDefinition | undefined>
): AssignmentReportLogic {
  const timeLimit = computed((): number | undefined => {
    return assignment?.value?.settings?.timeLimit;
  });

  const timeLimitInMinutes = computed((): number | undefined => {
    return timeLimit.value ? timeLimit.value / 60 : undefined;
  });

  return {
    timeLimitInMinutes,
  };
}
