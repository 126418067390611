import { LmsProviderType } from '@/domain/LmsProviderType';
import { AuthenticatedUser, UserRole } from '@/domain/User';
import { useStore } from '@/store';
import { computed } from 'vue';

// I'm not 100% sure if this is the correct setup but it seems to be working for us.
const store = useStore();

export const isAuthenticated = computed((): boolean => {
  return store.getters['auth/isAuthenticated'];
});
export const getCurrentUser = computed((): AuthenticatedUser => {
  return store.getters['auth/getCurrentUser'];
});
export const isLtiUser = computed((): boolean => {
  return getCurrentUser.value?.lmsProviderType === LmsProviderType.LTI_ENABLED;
});
export const isServiceAdminUser = computed((): boolean => {
  const roles = getCurrentUser.value?.roles ?? [];
  return roles.includes(UserRole.SERVICE_ADMIN);
});
export const isTeacherUser = computed((): boolean => {
  const roles = getCurrentUser.value?.roles ?? [];
  return isServiceAdminUser.value || roles.includes(UserRole.TEACHER);
});
export const isContentAdminUser = computed((): boolean => {
  const roles = getCurrentUser.value?.roles ?? [];
  return isServiceAdminUser.value || roles.includes(UserRole.CONTENT_ADMIN);
});
export const isTrustedBuilderUser = computed((): boolean => {
  const roles = getCurrentUser.value?.roles ?? [];
  return isServiceAdminUser.value || roles.includes(UserRole.TRUSTED_BUILDER);
});
// No "special" roles.
export const isStudentUser = computed((): boolean => {
  const roles = getCurrentUser.value?.roles ?? [];
  return ![
    UserRole.SERVICE_ADMIN,
    UserRole.CONTENT_ADMIN,
    UserRole.TRUSTED_BUILDER,
    UserRole.TEACHER,
    UserRole.MENTOR,
  ].some((role) => roles.includes(role));
});
