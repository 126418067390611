import { GetterTree } from 'vuex';
import { CourseDefinition } from '@/domain/Course';
import { CourseState } from './types';
import { RootState } from '../types';

export const getters: GetterTree<CourseState, RootState> = {
  getIdToClassMap(state: CourseState) {
    return state.courses.reduce<Record<string, CourseDefinition>>(
      (acc, course) => {
        acc[course.xref] = course;
        return acc;
      },
      {}
    );
  },
};
