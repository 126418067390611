import { CourseDefinition } from '@/domain/Course';
import { GroupType } from '@/domain/Group';
import { LmsProviderType } from '@/domain/LmsProviderType';

//////////
// DTOs //
//////////

interface CourseDefinitionDTO {
  courseXref: string;
  courseName: string;
  ownerXref: string;
  groupType: GroupType;
  endTime: number;
  lmsUid: string;
  lmsProviderType: string;
  isActive: boolean;
  createdAt: number;
}

const buildCourseDefinition = (
  courseDTO: CourseDefinitionDTO
): CourseDefinition => {
  const { courseXref, courseName, lmsProviderType, ...rest } = courseDTO;
  return {
    xref: courseXref,
    name: courseName,
    lmsProviderType: LmsProviderType.findByName(lmsProviderType),
    ...rest,
  };
};

export { buildCourseDefinition };
