/* eslint-disable @typescript-eslint/ban-types */
import { Server } from 'miragejs';
import dayjs from 'dayjs';
import { faker } from '@faker-js/faker';
import { ActionType } from '@/domain/Action';

faker.seed(0);

function randomScore(): number | null {
  // Want a random score but want it weighted toward getting a number
  // 50% chance of random number, 30% chance of 1, 10% chance of 0, 10% chance of null
  const possibleScores = [
    faker.number.float({
      max: 1,
      precision: 0.01,
    }),
    faker.number.float({
      max: 1,
      precision: 0.01,
    }),
    faker.number.float({
      max: 1,
      precision: 0.01,
    }),
    faker.number.float({
      max: 1,
      precision: 0.01,
    }),
    faker.number.float({
      max: 1,
      precision: 0.01,
    }),
    1,
    1,
    1,
    0,
    null,
  ];
  return faker.helpers.arrayElement(possibleScores);
}

export default function (server: Server): void {
  const users = server.schema.db.users;

  server.create('assignmentReport', {
    contentInfo: {
      xref: 'Or1tJNwlmCkwGjnUcbvX',
      itemType: 'ASSIGNMENT',
      active: true,
    },
    summaryStatsAll: {
      avgScore: 0.8345,
      avgTime: 60 * 60 * 1000,
      studentStats: [
        {
          studentXref: 'C9rUPIqxW7q2x9oLpSDG',
          timeSpent: (21 * 40 + 13) * 1000,
          score: 0.9,
        },
        {
          studentXref: 'wNmUOMlw2AIqZ8bKUF9',
          timeSpent: null,
          score: 0.675,
        },
        {
          studentXref: 'J5ihYVyU3yihj14QGdd5',
          timeSpent: (24 * 60 * 60 + (9 * 60 + 5)) * 1000,
          score: 0.6,
        },
        {
          studentXref: 'qCEq425AKdzg4liLV7jV',
          score: 0.5,
        },
      ],
    },
    summaryStatsSubset: null,
    prAllStats: [
      {
        prCeri: 'PRABGYJ8',
        cwas: [
          {
            answer: '1/2',
            percent: 0.675,
          },
          {
            answer: '2/4',
            percent: 0.6,
          },
          {
            answer:
              '<img src="https://resources.assistments.org/fetch/SW/bcfa0b0f-db73-4dcc-8686-4d71f68d6cff.jpeg" />',
            percent: 0.65,
          },
        ],
        avgScore: 0.8723,
        avgTime: 5 * 60 * 1000,
        problemDbid: 1,
      },
    ], // End prAllStats
    prSubsetStats: null,
    studentLogs: [
      {
        studentXref: 'C9rUPIqxW7q2x9oLpSDG',
        asStartTime: dayjs().subtract(1, 'week').valueOf(),
        asEndTime: dayjs().subtract(4, 'day').valueOf(),
        psActions: [],
        problemLogAndActions: [
          {
            prLog: {
              id: 1,
              prCeri: 'PRABGYJ8',
              startTime: dayjs().subtract(1, 'week').valueOf(),
              endTime: dayjs().subtract(1, 'week').add(10, 'minute').valueOf(),
              continuousScore: 0,
              discreteScore: 0, // Open response grading?
              answerText: '<i>y</i> = m<i>x</i> + <i>b</i>',
              firstActionType: ActionType.STUDENT_RESPONSE_ACTION,
              attemptCount: 1,
              hintCount: 0,
              sawAnswer: false,
              pathInfo: '/problems/PRABGYJ8', // What's this?
              firstResponseTime: 4 * 60 * 1000,
              // teacherComment: 'Please give more explanation to your answer.',
              problemDbid: 1,
            },
            actions: [
              {
                actionType: ActionType.STUDENT_RESPONSE_ACTION,
                correct: false,
                response: '<i>x</i> = m<i>* -x</i> + <i>3b</i>',
                studentWork: {
                  type: 'TEACHLEY',
                  value:
                    '<img src="https://app.assistments.org/images/assistments/157267.jpg" />',
                },
              },
              {
                actionType: ActionType.PROBLEM_FINISHED_ACTION,
              },
            ],
          },
        ], // End student 1 prLogsAndActions
      }, // End student 1 data
      {
        studentXref: 'wNmUOMlw2AIqZ8bKUF9',
        asStartTime: dayjs().subtract(5, 'day').valueOf(),
        asEndTime: dayjs().subtract(2, 'day').valueOf(),
        psActions: null,
        problemLogAndActions: [
          {
            prLog: {
              id: 2,
              prCeri: 'PRABGYJ8',
              startTime: dayjs().subtract(4, 'day').valueOf(),
              endTime: dayjs()
                .subtract(3, 'day')
                .add(3, 'hour')
                .add(5, 'second')
                .valueOf(),
              continuousScore: 1,
              discreteScore: 1, // Open response grading?
              answerText: '<p>a<i>x</i> + b<i>y</i> + <i>c</i></p>',
              firstActionType: ActionType.STUDENT_RESPONSE_ACTION, // FIXME: Is this correct?
              attemptCount: 3,
              hintCount: 2,
              sawAnswer: false,
              pathInfo: '/problems/PRABGYJ8', // What's this?
              firstResponseTime: 3 * 60 * 1000,
              teacherComment: 'I was expecting this to be inside a table',
              problemDbid: 1,
            },
            actions: [
              {
                actionType: ActionType.STUDENT_RESPONSE_ACTION,
                correct: true,
                response: 'Something smart and correct',
                studentWork: {
                  type: 'TEACHLEY',
                  value:
                    '<img src="https://app.assistments.org/images/assistments/157267.jpg" />',
                },
              },
              {
                actionType: ActionType.PROBLEM_FINISHED_ACTION,
              },
            ],
          },
        ],
      }, // End student 2 data
      // No scores - has not completed assignment yet
      {
        studentXref: 'J5ihYVyU3yihj14QGdd5',
        asStartTime: dayjs().subtract(1, 'day').valueOf(),
        // asEndTime: null, // In progress
        psActions: [],
        problemLogAndActions: [
          {
            prLog: {
              id: 3,
              prCeri: 'PRASRIVQ',
              startTime: dayjs()
                .subtract(1, 'day')
                .subtract(10, 'minute')
                .valueOf(),
              endTime: dayjs().subtract(1, 'day').valueOf(),
              continuousScore: 1,
              discreteScore: 1,
              answerText: '<p>0</p>',
              firstActionType: ActionType.STUDENT_RESPONSE_ACTION, // FIXME: Is this correct?
              attemptCount: 1,
              hintCount: 0,
              sawAnswer: false,
              pathInfo: '/problems/PRASRIVQ', // What's this?
              firstResponseTime: 2 * 60 * 1000,
              teacherComment:
                'This is the single most beautiful thing I have ever seen',
              problemDbid: 3,
            },
            actions: [
              {
                actionType: ActionType.STUDENT_RESPONSE_ACTION,
                correct: true,
                response: 'Something smart and correct',
                studentWork: {
                  type: 'TEACHLEY',
                  value:
                    '<img src="https://app.assistments.org/images/assistments/157267.jpg" />',
                },
              },
            ], // TODO
          },
          {
            prLog: {
              id: 4,
              prCeri: 'PRABGYJ8',
              startTime: dayjs().subtract(1, 'day').valueOf(),
              // endTime: null,
              continuousScore: 0,
              discreteScore: 0, // Open response grading?
              answerText: '<p>a<i>x</i> + b<i>y</i> + <i>c</i></p>',
              firstActionType: ActionType.STUDENT_RESPONSE_ACTION, // FIXME: Is this correct?
              attemptCount: 1,
              hintCount: 0,
              sawAnswer: true,
              pathInfo: '/problems/PRABGYJ8', // What's this?
              firstResponseTime: 2 * 60 * 1000,
              teacherComment: null,
              problemDbid: 1,
            },
            actions: [
              {
                actionType: ActionType.STUDENT_RESPONSE_ACTION,
                timestamp: dayjs().valueOf(),
                studentWork: {
                  type: 'TEACHLEY',
                  value:
                    '<img src="https://app.assistments.org/images/assistments/157267.jpg" />',
                },
              },
              {
                actionType: ActionType.PROBLEM_FINISHED_ACTION,
              },
            ],
          },
        ],
      },
      {
        studentXref: 'qCEq425AKdzg4liLV7jV',
        asStartTime: dayjs().subtract(2, 'day').valueOf(),
        asEndTime: null, // In progress
        psActions: null,
        problemLogAndActions: [
          {
            prLog: {
              id: 5,
              prCeri: 'PRABGYJ8',
              startTime: dayjs().subtract(1, 'day').valueOf(),
              // endTime: dayjs().valueOf(),
              endTime: null,
              continuousScore: 1,
              // discreteScore: null, // Open response grading?
              answerText: '<p>0</p>',
              firstActionType: ActionType.STUDENT_RESPONSE_ACTION, // FIXME: Is this correct?
              attemptCount: 1,
              hintCount: 0,
              sawAnswer: false,
              pathInfo: '/problems/PRABGYJ8', // What's this?
              firstResponseTime: 5 * 60 * 1000,
              teacherComment: null,
              problemDbid: 1,
            },
            actions: null, // TODO
          },
        ],
      }, // End student 3 data
    ], // End studentLogs
  } as object);
  server.create('assignmentReport', {
    contentInfo: {
      xref: 'wpiG6PSFf0aguy2P0bw9',
      itemType: 'ASSIGNMENT',
      active: true,
    },
    summaryStatsAll: {
      avgScore: 0.8345,
      avgTime: 60 * 60 * 1000,
      studentStats: [
        {
          studentXref: 'C9rUPIqxW7q2x9oLpSDG',
          timeSpent: (21 * 60 + 13) * 1000,
          score: 0.9,
        },
        {
          studentXref: 'wNmUOMlw2AIqZ8bKUF9',
          timeSpent: null,
          score: 0.675,
        },
        {
          studentXref: 'J5ihYVyU3yihj14QGdd5',
          timeSpent: (24 * 60 * 60 + (9 * 60 + 5)) * 1000,
          score: 0.6,
        },
        {
          studentXref: 'qCEq425AKdzg4liLV7jV',
          score: 0.5,
        },
      ],
    },
    summaryStatsSubset: null,
    prAllStats: [
      {
        prCeri: 'PRABGYJ8',
        cwas: [
          {
            answer: '1/2',
            percent: 0.675,
          },
          {
            answer: '2/4',
            percent: 0.6,
          },
        ],
        avgScore: 0.8723,
        avgTime: 5 * 60 * 1000,
        problemDbid: 1,
      },
      {
        prCeri: 'PRABM',
        cwas: [
          {
            answer: 'PRABM',
            percent: 0.675,
          },
        ],
        avgScore: 0.25,
        avgTime: 5 * 60 * 1000,
        problemDbid: 27,
      },
      {
        prCeri: 'PRABN',
        cwas: [
          {
            answer: 'PRABN',
            percent: 0.66,
          },
        ],
        avgScore: 0.5,
        avgTime: 5 * 60 * 1000,
        problemDbid: 28,
      },
      {
        prCeri: 'PRAZ',
        cwas: [
          {
            answer: 'PRAZ',
            percent: 0.675,
          },
        ],
        avgScore: 0.25,
        avgTime: 5 * 60 * 1000,
        problemDbid: 26,
      },
      {
        prCeri: 'PRABL',
        cwas: [
          {
            answer: 'PRABL',
            percent: 0.675,
          },
        ],
        avgScore: 0.25,
        avgTime: 5 * 60 * 1000,
        problemDbid: 24,
      },
      {
        prCeri: 'PRABK',
        cwas: [
          {
            answer: 'PRABK',
            percent: 0.66,
          },
        ],
        avgScore: 0.5,
        avgTime: 5 * 60 * 1000,
        problemDbid: 25,
      },
      {
        prCeri: 'PRABPFDP',
        cwas: null, // Can this happen?
        avgScore: 0.6345,
        avgTime: 15 * 60 * 1000,
        problemDbid: 2,
      },
      {
        prCeri: 'PRASRIVQ',
        cwas: [],
        avgScore: 0.9343,
        avgTime: 3 * 60 * 1000,
        problemDbid: 3,
      },
      {
        prCeri: 'PRABGYJ8',
        cwas: [
          {
            answer: '3/5',
            percent: 0.1,
          },
          {
            answer: '88',
            percent: 0.05,
          },
        ],
        avgScore: 0.8745,
        avgTime: 5 * 60 * 1000,
        problemDbid: 4,
      },
      {
        prCeri: 'PRABPFDP',
        cwas: null, // Can this happen?
        avgScore: 0.845,
        avgTime: 20 * 60 * 1000,
        problemDbid: 5,
      },
      {
        prCeri: 'PRASRIVQ',
        cwas: [
          {
            answer:
              '<img src="https://resources.assistments.org/fetch/SW/bcfa0b0f-db73-4dcc-8686-4d71f68d6cff.jpeg" />',
            percent: 0.65,
          },
        ],
        avgScore: 0.9356,
        avgTime: 3 * 60 * 1000,
        problemDbid: 6,
      },
      {
        prCeri: 'PRABGYJ8',
        cwas: [],
        avgScore: 0.8756,
        avgTime: 5 * 60 * 1000,
        problemDbid: 7,
      },
      {
        prCeri: 'PRABPFDP',
        cwas: null,
        avgScore: 0.6225,
        avgTime: 15 * 60 * 1000,
        problemDbid: 8,
      },
      {
        prCeri: 'PRASRIVQ',
        cwas: [],
        avgScore: 0.9333,
        avgTime: 3 * 60 * 1000,
        problemDbid: 9,
      },
      {
        prCeri: 'PRABGYJ8',
        cwas: [
          {
            answer: '2',
            percent: 0.1,
          },
          {
            answer: 'two',
            percent: 0.15,
          },
        ],
        avgScore: 0.6456,
        avgTime: 5 * 60 * 1000,
        problemDbid: 10,
      },
      {
        prCeri: 'PRABPFDP',
        cwas: null, // Can this happen?
        avgScore: 0.449,
        avgTime: 20 * 60 * 1000,
        problemDbid: 11,
      },
      {
        prCeri: 'PRASRIVQ',
        cwas: [],
        avgScore: 0.5768,
        avgTime: 3 * 60 * 1000,
        problemDbid: 12,
      },
      {
        prCeri: 'PRABGYJ8.1',
        cwas: [],
        avgScore: 0.3738,
        avgTime: 4 * 60 * 1000,
        problemDbid: 13,
      },
      {
        prCeri: 'PRABPFDP.4',
        cwas: [],
        avgScore: 0.9484,
        avgTime: 5 * 60 * 1000,
        problemDbid: 14,
      },
      {
        prCeri: 'PRABSXNX.2009420',
        cwas: [
          {
            answer: '1/2',
            percent: 0.675,
          },
          {
            answer: '2/4',
            percent: 0.6,
          },
          {
            answer:
              '<img src="https://resources.assistments.org/fetch/SW/bcfa0b0f-db73-4dcc-8686-4d71f68d6cff.jpeg" />',
            percent: 0.65,
          },
        ],
        avgScore: 0.8723,
        avgTime: 5 * 60 * 1000,
        problemDbid: 1,
      },
    ],
    prSubsetStats: null,
    studentLogs: [
      {
        studentXref: 'C9rUPIqxW7q2x9oLpSDG',
        asStartTime: dayjs().subtract(1, 'week').valueOf(),
        asEndTime: dayjs().subtract(4, 'day').valueOf(),
        psActions: [
          {
            actionType: ActionType.ASSIGNMENT_STARTED_ACTION,
          },
          {
            actionType: ActionType.TIMER_STARTED_ACTION,
            timestamp: 1669111200000,
          },
          {
            actionType: ActionType.ASSIGNMENT_RESUMED_ACTION,
          },
          {
            actionType: ActionType.TIMER_PAUSED_ACTION,
            timestamp: 1669111260000,
          },
          {
            actionType: ActionType.TIMER_RESUMED_ACTION,
            timestamp: 1669111320000,
          },
          {
            actionType: ActionType.ASSIGNMENT_RESUMED_ACTION,
          },
          {
            actionType: ActionType.TIMER_RESUMED_ACTION,
            timestamp: 1669111380000,
          },
          {
            actionType: ActionType.TIMER_PAUSED_ACTION,
            timestamp: 1669111440000,
          },
          {
            actionType: ActionType.PROBLEM_FINISHED_ACTION,
          },
          {
            actionType: ActionType.TIMER_FINISHED_ACTION,
            timestamp: 1669111440000,
            timeLeft: 120, // finished with 2 min remaining
          },
        ],
        problemLogAndActions: [
          {
            prLog: {
              id: 6,
              prCeri: 'PRABGYJ8.1',
              startTime: dayjs().subtract(1, 'week').valueOf(),
              endTime: dayjs().subtract(1, 'week').add(10, 'minute').valueOf(),
              continuousScore: 0.67,
              discreteScore: null, // Open response grading?
              answerText: '<i>y</i> = m<i>x</i> + <i>b</i>',
              firstActionType: ActionType.STUDENT_RESPONSE_ACTION,
              attemptCount: 1,
              hintCount: 0,
              sawAnswer: false,
              pathInfo: '/problems/PRABGYJ8', // What's this?
              firstResponseTime: 4 * 60 * 1000,
              // teacherComment: 'Please give more explanation to your answer.',
              problemDbid: 1,
            },
            actions: [
              {
                actionType: ActionType.STUDENT_RESPONSE_ACTION,
                correct: false,
                response: '<i>x</i> = m<i>* -x</i> + <i>3b</i>',
                studentWork: {
                  type: 'TEACHLEY',
                  value:
                    '<img src="https://app.assistments.org/images/assistments/157267.jpg" />',
                },
              },
              {
                actionType: ActionType.STUDENT_RESPONSE_ACTION,
                correct: true,
                response: '<i>y</i> = m<i>x</i> + <i>b</i>',
                studentWork: {
                  type: 'TEACHLEY',
                  value:
                    '<img src="https://app.assistments.org/images/assistments/157267.jpg" />',
                },
              },
            ],
          },

          //
          {
            prLog: {
              id: 7,
              prCeri: 'PRABPFDP',
              startTime: dayjs().subtract(5, 'day').valueOf(),
              endTime: dayjs().subtract(5, 'day').add(15, 'second').valueOf(),
              continuousScore: 0.67,
              discreteScore: 0, // Open response grading?
              answerText:
                '<img src="https://resources.assistments.org/fetch/SW/bcfa0b0f-db73-4dcc-8686-4d71f68d6cff.jpeg" />',
              firstActionType: ActionType.STUDENT_SUBMISSION_ACTION, // FIXME: Is this correct?
              attemptCount: 1,
              hintCount: 1,
              sawAnswer: false,
              pathInfo: '/problems/PRABPFDP', // What's this?
              firstResponseTime: 13 * 60 * 1000,
              teacherComment: 'Excellent!',
              problemDbid: 2,
            },
            actions: [
              {
                actionType: ActionType.HINT_REQUESTED_ACTION,
                hintId: 1,
              },
              {
                actionType: ActionType.STUDENT_RESPONSE_ACTION,
                correct: true,
                response: 'Something smart and correct',
                studentWork: {
                  type: 'TEACHLEY',
                  value:
                    '<img src="https://app.assistments.org/images/assistments/157267.jpg" />',
                },
              },
            ], // TODO
          },

          {
            prLog: {
              id: 8,
              prCeri: 'PRASRIVQ',
              startTime: dayjs().subtract(4, 'day').valueOf(),
              endTime: dayjs().subtract(4, 'day').add(1, 'minute').valueOf(),
              continuousScore: 1,
              // discreteScore: null, // Open response grading?
              answerText: '<p>0</p>',
              firstActionType: ActionType.STUDENT_RESPONSE_ACTION, // FIXME: Is this correct?
              attemptCount: 1,
              hintCount: 0,
              sawAnswer: false,
              pathInfo: '/problems/PRASRIVQ', // What's this?
              firstResponseTime: 2 * 60 * 1000,
              teacherComment:
                'This is the single most beautiful thing I have ever seen',
              problemDbid: 3,
            },
            actions: [], // TODO
          },
          {
            prLog: {
              id: 9,
              prCeri: 'PRABGYJ8',
              startTime: dayjs().subtract(1, 'week').valueOf(),
              endTime: dayjs().subtract(1, 'week').add(2, 'minute').valueOf(),
              continuousScore: 0.67,
              discreteScore: null, // Open response grading?
              answerText: '<i>y</i> = m<i>x</i> + <i>b</i>',
              firstActionType: ActionType.STUDENT_RESPONSE_ACTION, // FIXME: Is this correct?
              attemptCount: 1,
              hintCount: 0,
              sawAnswer: false,
              pathInfo: '/problems/PRABGYJ8', // What's this?
              firstResponseTime: 4 * 60 * 1000,
              // teacherComment: 'Please give more explanation to your answer.',
              problemDbid: 4,
            },
            actions: [], // TODO
          },
          {
            prLog: {
              id: 10,
              prCeri: 'PRABPFDP',
              startTime: dayjs().subtract(5, 'day').valueOf(),
              endTime: dayjs().subtract(5, 'day').add(1, 'hour').valueOf(),
              continuousScore: null,
              discreteScore: 4, // Open response grading?
              answerText:
                '<img src="https://resources.assistments.org/fetch/SW/bcfa0b0f-db73-4dcc-8686-4d71f68d6cff.jpeg" />',
              firstActionType: ActionType.STUDENT_SUBMISSION_ACTION, // FIXME: Is this correct?
              attemptCount: 1,
              hintCount: 0,
              sawAnswer: false,
              pathInfo: '/problems/PRABPFDP', // What's this?
              firstResponseTime: 13 * 60 * 1000,
              teacherComment: 'Excellent!',
              problemDbid: 5,
            },
            actions: [], // TODO
          },
          {
            prLog: {
              id: 11,
              prCeri: 'PRASRIVQ',
              startTime: dayjs().subtract(4, 'day').valueOf(),
              endTime: dayjs().subtract(4, 'day').add(3, 'minute').valueOf(),
              continuousScore: 1,
              // discreteScore: null, // Open response grading?
              answerText: '<p>0</p>',
              firstActionType: ActionType.STUDENT_RESPONSE_ACTION, // FIXME: Is this correct?
              attemptCount: 1,
              hintCount: 0,
              sawAnswer: false,
              pathInfo: '/problems/PRASRIVQ', // What's this?
              firstResponseTime: 2 * 60 * 1000,
              teacherComment: null,
              problemDbid: 6,
            },
            actions: [], // TODO
          },
          {
            prLog: {
              id: 12,
              prCeri: 'PRABGYJ8',
              startTime: dayjs().subtract(1, 'week').valueOf(),
              endTime: dayjs().subtract(1, 'week').add(7, 'minute').valueOf(),
              continuousScore: 0.67,
              discreteScore: null, // Open response grading?
              answerText: '<i>y</i> = m<i>x</i> + <i>b</i>',
              firstActionType: ActionType.STUDENT_RESPONSE_ACTION, // FIXME: Is this correct?
              attemptCount: 1,
              hintCount: 0,
              sawAnswer: false,
              pathInfo: '/problems/PRABGYJ8', // What's this?
              firstResponseTime: 4 * 60 * 1000,
              // teacherComment: 'Please give more explanation to your answer.',
              problemDbid: 7,
            },
            actions: [],
          },
          {
            prLog: {
              id: 13,
              prCeri: 'PRABPFDP',
              startTime: dayjs().subtract(5, 'day').valueOf(),
              endTime: dayjs().subtract(5, 'day').add(5, 'second').valueOf(),
              continuousScore: null,
              discreteScore: 4, // Open response grading?
              answerText:
                '<img src="https://resources.assistments.org/fetch/SW/bcfa0b0f-db73-4dcc-8686-4d71f68d6cff.jpeg" />',
              firstActionType: ActionType.STUDENT_SUBMISSION_ACTION, // FIXME: Is this correct?
              attemptCount: 1,
              hintCount: 0,
              sawAnswer: false,
              pathInfo: '/problems/PRABPFDP', // What's this?
              firstResponseTime: 13 * 60 * 1000,
              teacherComment: 'Excellent!',
              problemDbid: 8,
            },
            actions: [], // TODO
          },
          {
            prLog: {
              id: 14,
              prCeri: 'PRASRIVQ',
              startTime: dayjs().subtract(4, 'day').valueOf(),
              endTime: dayjs().subtract(4, 'day').add(2, 'hour').valueOf(),
              continuousScore: 1,
              // discreteScore: null, // Open response grading?
              answerText: '<p>0</p>',
              firstActionType: ActionType.STUDENT_RESPONSE_ACTION, // FIXME: Is this correct?
              attemptCount: 1,
              hintCount: 0,
              sawAnswer: false,
              pathInfo: '/problems/PRASRIVQ', // What's this?
              firstResponseTime: 2 * 60 * 1000,
              teacherComment: null,
              problemDbid: 9,
            },
            actions: [], // TODO
          },
          {
            prLog: {
              id: 15,
              prCeri: 'PRABGYJ8',
              startTime: dayjs().subtract(1, 'week').valueOf(),
              endTime: dayjs().subtract(1, 'week').add(15, 'minute').valueOf(),
              continuousScore: 0.67,
              discreteScore: null, // Open response grading?
              answerText: '<i>y</i> = m<i>x</i> + <i>b</i>',
              firstActionType: ActionType.STUDENT_RESPONSE_ACTION, // FIXME: Is this correct?
              attemptCount: 1,
              hintCount: 0,
              sawAnswer: false,
              pathInfo: '/problems/PRABGYJ8', // What's this?
              firstResponseTime: 4 * 60 * 1000,
              // teacherComment: 'Please give more explanation to your answer.',
              problemDbid: 10,
            },
            actions: [], // TODO
          },
          {
            prLog: {
              id: 16,
              prCeri: 'PRABPFDP',
              startTime: dayjs().subtract(5, 'day').valueOf(),
              endTime: dayjs().subtract(5, 'day').add(10, 'second').valueOf(),
              continuousScore: null,
              discreteScore: 1, // Open response grading?
              answerText:
                '<img src="https://resources.assistments.org/fetch/SW/bcfa0b0f-db73-4dcc-8686-4d71f68d6cff.jpeg" />',
              firstActionType: ActionType.STUDENT_SUBMISSION_ACTION, // FIXME: Is this correct?
              attemptCount: 1,
              hintCount: 0,
              sawAnswer: false,
              pathInfo: '/problems/PRABPFDP', // What's this?
              firstResponseTime: 13 * 60 * 1000,
              teacherComment: 'Excellent!',
              problemDbid: 11,
            },
            actions: [], // TODO
          },
          {
            prLog: {
              id: 17,
              prCeri: 'PRASRIVQ',
              startTime: dayjs().subtract(4, 'day').valueOf(),
              endTime: dayjs().subtract(4, 'day').add(3, 'second').valueOf(),
              continuousScore: 1,
              // discreteScore: null, // Open response grading?
              answerText: '<p>0</p>',
              firstActionType: ActionType.STUDENT_RESPONSE_ACTION, // FIXME: Is this correct?
              attemptCount: 1,
              hintCount: 0,
              sawAnswer: false,
              pathInfo: '/problems/PRASRIVQ', // What's this?
              firstResponseTime: 2 * 60 * 1000,
              teacherComment: null,
              problemDbid: 12,
            },
            actions: [], // TODO
          },
          {
            prLog: {
              id: 51,
              prCeri: 'PRABL',
              startTime: dayjs().subtract(1, 'week').valueOf(),
              endTime: dayjs().subtract(1, 'week').add(10, 'minute').valueOf(),
              continuousScore: 0.9,
              discreteScore: null, // Open response grading?
              answerText: '<i>y</i> = m<i>x</i> + <i>b</i>',
              firstActionType: ActionType.STUDENT_RESPONSE_ACTION,
              attemptCount: 1,
              hintCount: 0,
              redoParentXref: 'PRABGYJ8.1',
              sawAnswer: false,
              pathInfo: '/problems/PRABGYJ8', // What's this?
              firstResponseTime: 4 * 60 * 1000,
              problemDbid: 24,
            },
            actions: [
              {
                actionType: ActionType.STUDENT_RESPONSE_ACTION,
                correct: false,
                response: '<i>x</i> = m<i>* -x</i> + <i>3b</i>',
                studentWork: {
                  type: 'TEACHLEY',
                  value:
                    '<img src="https://app.assistments.org/images/assistments/157267.jpg" />',
                },
              },
              {
                actionType: ActionType.STUDENT_RESPONSE_ACTION,
                correct: true,
                response: '<i>y</i> = m<i>x</i> + <i>b</i>',
              },
            ],
          },
          {
            prLog: {
              id: 52,
              prCeri: 'PRABK',
              startTime: dayjs().subtract(1, 'week').valueOf(),
              endTime: dayjs().subtract(1, 'week').add(10, 'minute').valueOf(),
              continuousScore: 0.1,
              discreteScore: null, // Open response grading?
              answerText: '<i>y</i> = m<i>x</i> + <i>b</i>',
              firstActionType: ActionType.STUDENT_RESPONSE_ACTION,
              attemptCount: 1,
              hintCount: 0,
              redoParentXref: 'PRABGYJ8.1',
              sawAnswer: false,
              pathInfo: '/problems/PRABGYJ8', // What's this?
              firstResponseTime: 4 * 60 * 1000,
              problemDbid: 25,
            },
            actions: [
              {
                actionType: ActionType.STUDENT_RESPONSE_ACTION,
                correct: false,
                response: '<i>x</i> = m<i>* -x</i> + <i>3b</i>',
                studentWork: {
                  type: 'TEACHLEY',
                  value:
                    '<img src="https://app.assistments.org/images/assistments/157267.jpg" />',
                },
              },
              {
                actionType: ActionType.STUDENT_RESPONSE_ACTION,
                correct: true,
                response: '<i>y</i> = m<i>x</i> + <i>b</i>',
              },
            ],
          },
          // {
          //   prLog: {
          //     id: 53,
          //     prCeri: 'PRAZ',
          //     startTime: dayjs().subtract(1, 'week').valueOf(),
          //     endTime: dayjs().subtract(1, 'week').add(10, 'minute').valueOf(),
          //     continuousScore: 0.7,
          //     discreteScore: null, // Open response grading?
          //     answerText: '<i>y</i> = m<i>x</i> + <i>b</i>',
          //     firstActionType: ActionType.STUDENT_RESPONSE_ACTION,
          //     attemptCount: 1,
          //     hintCount: 0,
          //     redoParentXref: 'PRABPFDP',
          //     sawAnswer: false,
          //     pathInfo: '/problems/PRASRIVQ', // What's this?
          //     firstResponseTime: 4 * 60 * 1000,
          //     problemDbid: 26,
          //   },
          //   actions: [
          //     {
          //       actionType: ActionType.STUDENT_RESPONSE_ACTION,
          //       correct: false,
          //       response: '<i>x</i> = m<i>* -x</i> + <i>3b</i>',
          //     },
          //     {
          //       actionType: ActionType.STUDENT_RESPONSE_ACTION,
          //       correct: true,
          //       response: '<i>y</i> = m<i>x</i> + <i>b</i>',
          //     },
          //   ],
          // },
          {
            prLog: {
              id: 54,
              prCeri: 'PRABM',
              startTime: dayjs().subtract(1, 'week').valueOf(),
              endTime: dayjs().subtract(1, 'week').add(10, 'minute').valueOf(),
              continuousScore: 1,
              discreteScore: null, // Open response grading?
              answerText: '<i>y</i> = m<i>x</i> + <i>b</i>',
              firstActionType: ActionType.STUDENT_RESPONSE_ACTION,
              attemptCount: 1,
              hintCount: 0,
              redoParentXref: 'PRABPFDP',
              sawAnswer: false,
              pathInfo: '/problems/PRASRIVQ', // What's this?
              firstResponseTime: 4 * 60 * 1000,
              problemDbid: 27,
            },
            actions: [
              {
                actionType: ActionType.STUDENT_RESPONSE_ACTION,
                correct: false,
                response: '<i>x</i> = m<i>* -x</i> + <i>3b</i>',
              },
              {
                actionType: ActionType.STUDENT_RESPONSE_ACTION,
                correct: true,
                response: '<i>y</i> = m<i>x</i> + <i>b</i>',
              },
            ],
          },
          {
            prLog: {
              id: 55,
              prCeri: 'PRABN',
              startTime: dayjs().subtract(1, 'week').valueOf(),
              endTime: dayjs().subtract(1, 'week').add(10, 'minute').valueOf(),
              continuousScore: 1,
              discreteScore: null, // Open response grading?
              answerText: '<i>y</i> = m<i>x</i> + <i>b</i>',
              firstActionType: ActionType.STUDENT_RESPONSE_ACTION,
              attemptCount: 1,
              hintCount: 0,
              redoParentXref: 'PRABPFDP',
              sawAnswer: false,
              pathInfo: '/problems/PRASRIVQ', // What's this?
              firstResponseTime: 4 * 60 * 1000,
              problemDbid: 28,
            },
            actions: [
              {
                actionType: ActionType.STUDENT_RESPONSE_ACTION,
                correct: false,
                response: '<i>x</i> = m<i>* -x</i> + <i>3b</i>',
              },
              {
                actionType: ActionType.STUDENT_RESPONSE_ACTION,
                correct: true,
                response: '<i>y</i> = m<i>x</i> + <i>b</i>',
              },
            ],
          },
        ], // End student 1 prLogsAndActions
      },
      {
        studentXref: 'wNmUOMlw2AIqZ8bKUF9',
        asStartTime: dayjs().subtract(5, 'day').valueOf(),
        asEndTime: dayjs().subtract(2, 'day').valueOf(),
        psActions: [
          {
            actionType: ActionType.ASSIGNMENT_STARTED_ACTION,
          },
          {
            actionType: ActionType.TIMER_STARTED_ACTION,
            timeLeft: 500,
          },
          {
            actionType: ActionType.TIMER_FINISHED_ACTION,
            timeLeft: 0,
          },
        ],
        problemLogAndActions: [
          {
            prLog: {
              id: 18,
              prCeri: 'PRABGYJ8',
              startTime: dayjs().subtract(4, 'day').valueOf(),
              endTime: dayjs()
                .subtract(3, 'day')
                .add(3, 'hour')
                .add(5, 'second')
                .valueOf(),
              continuousScore: 0.33,
              discreteScore: null, // Open response grading?
              answerText: '<p>a<i>x</i> + b<i>y</i> + <i>c</i></p>',
              firstActionType: ActionType.STUDENT_RESPONSE_ACTION, // FIXME: Is this correct?
              attemptCount: 3,
              hintCount: 2,
              sawAnswer: false,
              pathInfo: '/problems/PRABGYJ8', // What's this?
              firstResponseTime: 3 * 60 * 1000,
              teacherComment: 'I was expecting this to be inside a table',
              problemDbid: 1,
            },
            actions: [
              {
                actionType: ActionType.HINT_REQUESTED_ACTION,
                hintId: 1,
              },
              {
                actionType: ActionType.HINT_REQUESTED_ACTION,
                hintId: 2,
              },
              {
                actionType: ActionType.STUDENT_RESPONSE_ACTION,
                correct: false,
                response: 'Something wrong but close',
                studentWork: {
                  type: 'TEACHLEY',
                  value:
                    '<img src="https://app.assistments.org/images/assistments/157267.jpg" />',
                },
              },
              {
                actionType: ActionType.STUDENT_RESPONSE_ACTION,
                correct: false,
                response: 'Still wrong but close',
                studentWork: {
                  type: 'TEACHLEY',
                  value:
                    '<img src="https://app.assistments.org/images/assistments/157267.jpg" />',
                },
              },
              {
                actionType: ActionType.STUDENT_RESPONSE_ACTION,
                correct: true,
                response: 'Something smart and correct',
                studentWork: {
                  type: 'TEACHLEY',
                  value:
                    '<img src="https://app.assistments.org/images/assistments/157267.jpg" />',
                },
              },
              {
                actionType: ActionType.LIVE_TUTORING_REQUESTED_ACTION,
              },
            ], // TODO
          },
          {
            prLog: {
              id: 19,
              prCeri: 'PRABPFDP',
              startTime: dayjs().subtract(3, 'day').valueOf(),
              endTime: dayjs()
                .subtract(2, 'day')
                .add(2, 'minute')
                .add(23, 'second')
                .valueOf(),
              continuousScore: null,
              discreteScore: 3, // Open response grading?
              answerText:
                '<img src="https://resources.assistments.org/fetch/SW/bcfa0b0f-db73-4dcc-8686-4d71f68d6cff.jpeg" />',
              firstActionType: ActionType.STUDENT_SUBMISSION_ACTION, // FIXME: Is this correct?
              attemptCount: 1,
              hintCount: 0,
              sawAnswer: false,
              pathInfo: '/problems/PRABPFDP', // What's this?
              firstResponseTime: 10 * 60 * 1000,
              teacherComment:
                'Very confusing to follow. Please give more explanation to your answer.',
              problemDbid: 2,
            },
            actions: [], // TODO
          },
          {
            prLog: {
              id: 20,
              prCeri: 'PRASRIVQ',
              startTime: dayjs().subtract(2, 'day').valueOf(),
              endTime: dayjs().subtract(2, 'day').add(5, 'minute').valueOf(),
              continuousScore: 0.33,
              // discreteScore: null, // Open response grading?
              answerText: '<p>3</p>',
              firstActionType: ActionType.STUDENT_RESPONSE_ACTION, // FIXME: Is this correct?
              attemptCount: 2,
              hintCount: 0,
              sawAnswer: false,
              pathInfo: '/problems/PRASRIVQ', // What's this?
              firstResponseTime: 1 * 60 * 1000,
              teacherComment: null,
              problemDbid: 3,
            },
            // actions: [], // TODO
          },
          {
            prLog: {
              id: 21,
              prCeri: 'PRABPFDP.4',
              startTime: dayjs().subtract(3, 'day').valueOf(),
              endTime: dayjs().subtract(2, 'day').add(9, 'second').valueOf(),
              continuousScore: 0,
              discreteScore: 0, // Open response grading?
              answerText:
                '<img src="https://resources.assistments.org/fetch/SW/bcfa0b0f-db73-4dcc-8686-4d71f68d6cff.jpeg" />',
              firstActionType: ActionType.STUDENT_SUBMISSION_ACTION, // FIXME: Is this correct?
              attemptCount: 1,
              hintCount: 2,
              sawAnswer: true,
              pathInfo: '/problems/PRABPFDP', // What's this?
              firstResponseTime: 10 * 60 * 1000,
              teacherComment:
                'Very confusing to follow. Please give more explanation to your answer.',
              problemDbid: 4,
            },
            actions: [
              {
                actionType: ActionType.HINT_REQUESTED_ACTION,
                hintId: 1,
              },
              {
                actionType: ActionType.HINT_REQUESTED_ACTION,
                hintId: 2,
              },
              {
                actionType: ActionType.ANSWER_REQUESTED_ACTION,
              },
              {
                actionType: ActionType.STUDENT_RESPONSE_ACTION,
                correct: true,
                response: 'Something smart and correct',
              },
            ], // TODO
          },
          {
            prLog: {
              id: 22,
              prCeri: 'PRABPFDP',
              startTime: dayjs().subtract(2, 'day').valueOf(),
              endTime: dayjs()
                .subtract(2, 'day')
                .add(13, 'minute')
                .add(5, 'second')
                .valueOf(),
              continuousScore: 0.67,
              // discreteScore: null, // Open response grading?
              answerText: '<p>3</p>',
              firstActionType: ActionType.STUDENT_RESPONSE_ACTION, // FIXME: Is this correct?
              attemptCount: 2,
              hintCount: 0,
              sawAnswer: false,
              pathInfo: '/problems/PRABPFDP', // What's this?
              firstResponseTime: 1 * 60 * 1000,
              teacherComment: null,
              problemDbid: 5,
            },
            // actions: [], // TODO
          },
        ],
      },
      // No scores - has not completed assignment yet
      {
        studentXref: 'J5ihYVyU3yihj14QGdd5',
        asStartTime: dayjs().subtract(1, 'day').valueOf(),
        // asEndTime: null, // In progress
        psActions: [],
        problemLogAndActions: [
          {
            prLog: {
              id: 23,
              prCeri: 'PRASRIVQ',
              startTime: dayjs()
                .subtract(1, 'day')
                .subtract(10, 'minute')
                .valueOf(),
              endTime: dayjs().subtract(1, 'day').valueOf(),
              continuousScore: null, // Needs scoring
              // discreteScore: null, // Open response grading?
              answerText: '<p>0</p>',
              firstActionType: ActionType.STUDENT_RESPONSE_ACTION, // FIXME: Is this correct?
              attemptCount: 1,
              hintCount: 0,
              sawAnswer: false,
              pathInfo: '/problems/PRASRIVQ', // What's this?
              firstResponseTime: 2 * 60 * 1000,
              teacherComment:
                'This is the single most beautiful thing I have ever seen',
              problemDbid: 3,
            },
            actions: [
              {
                actionType: ActionType.STUDENT_RESPONSE_ACTION,
                correct: true,
                response: 'Something smart and correct',
                partData: {
                  '1': {
                    response: ['12', '42'],
                    correct: true,
                    responseCorrectness: [true],
                    score: {
                      continuousScore: 0.0,
                      discreteScore: 0,
                    },
                    answerType: null,
                  },
                  '2': {
                    response: ['11', '44', '99'],
                    correct: true,
                    responseCorrectness: [true],
                    score: {
                      continuousScore: 0.0,
                      discreteScore: 0,
                    },
                    answerType: null,
                  },
                  '3': {
                    response: ['123', '456', '789'],
                    correct: true,
                    responseCorrectness: [true],
                    score: {
                      continuousScore: 0.0,
                      discreteScore: 0,
                    },
                    answerType: null,
                  },
                },
                studentWork: {
                  type: 'TEACHLEY',
                  value:
                    '<img src="https://app.assistments.org/images/assistments/157267.jpg" />',
                },
              },
            ], // TODO
          },
          {
            prLog: {
              id: 24,
              prCeri: 'PRABGYJ8',
              startTime: dayjs().subtract(1, 'day').valueOf(),
              // endTime: null,
              continuousScore: 0,
              discreteScore: null, // Open response grading?
              answerText: '<p>a<i>x</i> + b<i>y</i> + <i>c</i></p>',
              firstActionType: ActionType.STUDENT_RESPONSE_ACTION, // FIXME: Is this correct?
              attemptCount: 1,
              hintCount: 0,
              sawAnswer: true,
              pathInfo: '/problems/PRABGYJ8', // What's this?
              firstResponseTime: 2 * 60 * 1000,
              teacherComment: null,
              problemDbid: 1,
            },
            actions: [
              {
                actionType: ActionType.HINT_REQUESTED_ACTION,
                hintId: 1,
                timestamp: dayjs().valueOf(),
              },
            ],
          },
        ],
      },
      {
        studentXref: 'qCEq425AKdzg4liLV7jV',
        asStartTime: dayjs().subtract(2, 'day').valueOf(),
        asEndTime: null, // In progress
        psActions: null,
        problemLogAndActions: [
          {
            prLog: {
              id: 25,
              prCeri: 'PRABGYJ8',
              startTime: dayjs().subtract(1, 'day').valueOf(),
              // endTime: dayjs().valueOf(),
              endTime: null,
              continuousScore: 1,
              // discreteScore: null, // Open response grading?
              answerText: '<p>0</p>',
              firstActionType: ActionType.STUDENT_RESPONSE_ACTION, // FIXME: Is this correct?
              attemptCount: 1,
              hintCount: 0,
              sawAnswer: false,
              pathInfo: '/problems/PRABGYJ8', // What's this?
              firstResponseTime: 5 * 60 * 1000,
              teacherComment: null,
              problemDbid: 1,
            },
            actions: null, // TODO
          },
          {
            prLog: {
              id: 26,
              prCeri: 'PRASRIVQ',
              startTime: dayjs().valueOf(),
              // endTime: null, // Could this happen?
              // continuousScore: null,
              discreteScore: null, // Open response grading?
              // answerText: '<p>a<i>x</i> + b<i>y</i> + <i>c</i></p>',
              // firstActionType: ActionType.STUDENT_RESPONSE_ACTION, // FIXME: Is this correct?
              attemptCount: 0,
              hintCount: 0,
              sawAnswer: false,
              pathInfo: '/problems/PRASRIVQ', // What's this?
              firstResponseTime: 20 * 60 * 1000,
              // teacherComment: null,
              problemDbid: 3,
            },
            actions: [], // TODO
          },
        ],
      },
      // Not started assignment. No record whatsoever
      // Student users:
      // hLVmvz6zgr6nmyEMW9qt
      // v0GfOR8e7W2v1EhL2iU1
    ],
  } as object); // End assignment 2 report data
  server.create('assignmentReport', {
    contentInfo: {
      xref: '9JnFP7A4K853S3d9HsF2',
      itemType: 'HOMEWORK',
      active: true,
    },
    summaryStatsAll: {
      avgScore: 0.8345,
      avgTime: 60 * 60 * 1000,
      studentStats: [
        {
          studentXref: 'C9rUPIqxW7q2x9oLpSDG',
          timeSpent: (21 * 40 + 13) * 1000,
          score: 0.9,
        },
        {
          studentXref: 'wNmUOMlw2AIqZ8bKUF9',
          timeSpent: null,
          score: 0.675,
        },
        {
          studentXref: 'J5ihYVyU3yihj14QGdd5',
          timeSpent: (24 * 60 * 60 + (9 * 60 + 5)) * 1000,
          score: 0.6,
        },
        {
          studentXref: 'qCEq425AKdzg4liLV7jV',
          score: 0.5,
        },
      ],
    },
    summaryStatsSubset: null,
    prAllStats: [
      {
        prCeri: 'PRABGYJ8',
        cwas: [
          {
            answer: '1/2',
            percent: 0.675,
          },
          {
            answer: '2/4',
            percent: 0.6,
          },
          {
            answer:
              '<img src="https://resources.assistments.org/fetch/SW/bcfa0b0f-db73-4dcc-8686-4d71f68d6cff.jpeg" />',
            percent: 0.65,
          },
        ],
        avgScore: 0.8723,
        avgTime: 5 * 60 * 1000,
        problemDbid: 1,
      },
    ], // End prAllStats
    prSubsetStats: null,
    studentLogs: [
      {
        studentXref: 'C9rUPIqxW7q2x9oLpSDG',
        asStartTime: dayjs().subtract(1, 'week').valueOf(),
        asEndTime: dayjs().subtract(4, 'day').valueOf(),
        psActions: [
          {
            actionType: ActionType.ASSIGNMENT_STARTED_ACTION,
          },
          {
            actionType: ActionType.TIMER_STARTED_ACTION,
            timestamp: 1668601027885,
          },
          {
            actionType: ActionType.TIMER_PAUSED_ACTION,
            timestamp: 1668601047657,
          },
          {
            actionType: ActionType.TIMER_RESUMED_ACTION,
            timestamp: 1668601089977,
          },
          {
            actionType: ActionType.TIMER_FINISHED_ACTION,
            timestamp: 1668601099977,
            timeLeft: 120, // Finished with 2min remaining
          },
          {
            actionType: ActionType.ASSIGNMENT_STARTED_ACTION,
          },
        ],
        problemLogAndActions: [
          {
            prLog: {
              id: 27,
              prCeri: 'PRABGYJ8',
              startTime: dayjs().subtract(1, 'week').valueOf(),
              endTime: dayjs().subtract(1, 'week').add(10, 'minute').valueOf(),
              continuousScore: 0.75,
              discreteScore: 0, // Open response grading?
              answerText: '<i>y</i> = m<i>x</i> + <i>b</i>',
              firstActionType: ActionType.STUDENT_RESPONSE_ACTION,
              attemptCount: 1,
              hintCount: 0,
              sawAnswer: false,
              pathInfo: '/problems/PRABGYJ8', // What's this?
              firstResponseTime: 4 * 60 * 1000,
              // teacherComment: 'Please give more explanation to your answer.',
              problemDbid: 1,
            },
            actions: [
              {
                actionType: ActionType.STUDENT_RESPONSE_ACTION,
                correct: false,
                response: '<i>x</i> = m<i>* -x</i> + <i>3b</i>', //<------------------------------------------------
              },
              {
                actionType: ActionType.PROBLEM_FINISHED_ACTION,
              },
            ],
          },
        ], // End student 1 prLogsAndActions
      }, // End student 1 data
      {
        studentXref: 'wNmUOMlw2AIqZ8bKUF9',
        asStartTime: dayjs().subtract(5, 'day').valueOf(),
        asEndTime: dayjs().subtract(2, 'day').valueOf(),
        psActions: null,
        problemLogAndActions: [
          {
            prLog: {
              id: 28,
              prCeri: 'PRABGYJ8',
              startTime: dayjs().subtract(4, 'day').valueOf(),
              endTime: dayjs()
                .subtract(3, 'day')
                .add(3, 'hour')
                .add(5, 'second')
                .valueOf(),
              continuousScore: 1,
              discreteScore: 1, // Open response grading?
              answerText: '<p>a<i>x</i> + b<i>y</i> + <i>c</i></p>',
              firstActionType: ActionType.STUDENT_RESPONSE_ACTION, // FIXME: Is this correct?
              attemptCount: 3,
              hintCount: 2,
              sawAnswer: false,
              pathInfo: '/problems/PRABGYJ8', // What's this?
              firstResponseTime: 3 * 60 * 1000,
              teacherComment: 'I was expecting this to be inside a table',
              problemDbid: 1,
            },
            actions: [
              {
                actionType: ActionType.STUDENT_RESPONSE_ACTION,
                correct: true,
                response: 'Something smart and correct',
              },
              {
                actionType: ActionType.PROBLEM_FINISHED_ACTION,
              },
            ],
          },
        ],
      }, // End student 2 data
      // No scores - has not completed assignment yet
      {
        studentXref: 'J5ihYVyU3yihj14QGdd5',
        asStartTime: dayjs().subtract(1, 'day').valueOf(),
        // asEndTime: null, // In progress
        psActions: [
          {
            actionType: 'PROBLEM_SET_LIMIT_EXCEEDED_ACTION',
            timestamp: dayjs().subtract(1, 'day').valueOf(),
            pathInfo: 'stuff',
            scaffolding: false,
          },
        ],
        problemLogAndActions: [
          {
            prLog: {
              id: 29,
              prCeri: 'PRASRIVQ',
              startTime: dayjs()
                .subtract(1, 'day')
                .subtract(10, 'minute')
                .valueOf(),
              endTime: dayjs().subtract(1, 'day').valueOf(),
              continuousScore: 1,
              discreteScore: 1,
              answerText: '<p>0</p>',
              firstActionType: ActionType.STUDENT_RESPONSE_ACTION, // FIXME: Is this correct?
              attemptCount: 1,
              hintCount: 0,
              sawAnswer: false,
              pathInfo: '/problems/PRASRIVQ', // What's this?
              firstResponseTime: 2 * 60 * 1000,
              teacherComment:
                'This is the single most beautiful thing I have ever seen',
              problemDbid: 3,
            },
            actions: [], // TODO
          },
          {
            prLog: {
              id: 30,
              prCeri: 'PRASRIVQ',
              startTime: dayjs()
                .subtract(1, 'day')
                .subtract(10, 'minute')
                .valueOf(),
              endTime: dayjs().subtract(1, 'day').valueOf(),
              continuousScore: 1,
              discreteScore: 1,
              answerText: '<p>0</p>',
              firstActionType: ActionType.STUDENT_RESPONSE_ACTION, // FIXME: Is this correct?
              attemptCount: 1,
              hintCount: 0,
              sawAnswer: false,
              pathInfo: '/problems/PRASRIVQ', // What's this?
              firstResponseTime: 2 * 60 * 1000,
              teacherComment:
                'This is the single most beautiful thing I have ever seen',
              problemDbid: 3,
            },
            actions: [], // TODO
          },
          {
            prLog: {
              id: 31,
              prCeri: 'PRASRIVQ',
              startTime: dayjs()
                .subtract(1, 'day')
                .subtract(10, 'minute')
                .valueOf(),
              endTime: dayjs().subtract(1, 'day').valueOf(),
              continuousScore: 1,
              discreteScore: 1,
              answerText: '<p>0</p>',
              firstActionType: ActionType.STUDENT_RESPONSE_ACTION, // FIXME: Is this correct?
              attemptCount: 1,
              hintCount: 0,
              sawAnswer: false,
              pathInfo: '/problems/PRASRIVQ', // What's this?
              firstResponseTime: 2 * 60 * 1000,
              teacherComment:
                'This is the single most beautiful thing I have ever seen',
              problemDbid: 3,
            },
            actions: [], // TODO
          },
          {
            prLog: {
              id: 32,
              prCeri: 'PRASRIVQ',
              startTime: dayjs()
                .subtract(1, 'day')
                .subtract(10, 'minute')
                .valueOf(),
              endTime: dayjs().subtract(1, 'day').valueOf(),
              continuousScore: 1,
              discreteScore: 1,
              answerText: '<p>0</p>',
              firstActionType: ActionType.STUDENT_RESPONSE_ACTION, // FIXME: Is this correct?
              attemptCount: 1,
              hintCount: 0,
              sawAnswer: false,
              pathInfo: '/problems/PRASRIVQ', // What's this?
              firstResponseTime: 2 * 60 * 1000,
              teacherComment:
                'This is the single most beautiful thing I have ever seen',
              problemDbid: 3,
            },
            actions: [], // TODO
          },
          {
            prLog: {
              id: 33,
              prCeri: 'PRASRIVQ',
              startTime: dayjs()
                .subtract(1, 'day')
                .subtract(10, 'minute')
                .valueOf(),
              endTime: dayjs().subtract(1, 'day').valueOf(),
              continuousScore: 1,
              discreteScore: 1,
              answerText: '<p>0</p>',
              firstActionType: ActionType.STUDENT_RESPONSE_ACTION, // FIXME: Is this correct?
              attemptCount: 1,
              hintCount: 0,
              sawAnswer: false,
              pathInfo: '/problems/PRASRIVQ', // What's this?
              firstResponseTime: 2 * 60 * 1000,
              teacherComment:
                'This is the single most beautiful thing I have ever seen',
              problemDbid: 3,
            },
            actions: [], // TODO
          },
          {
            prLog: {
              id: 34,
              prCeri: 'PRASRIVQ',
              startTime: dayjs()
                .subtract(1, 'day')
                .subtract(10, 'minute')
                .valueOf(),
              endTime: dayjs().subtract(1, 'day').valueOf(),
              continuousScore: 1,
              discreteScore: 1,
              answerText: '<p>0</p>',
              firstActionType: ActionType.STUDENT_RESPONSE_ACTION, // FIXME: Is this correct?
              attemptCount: 1,
              hintCount: 0,
              sawAnswer: false,
              pathInfo: '/problems/PRASRIVQ', // What's this?
              firstResponseTime: 2 * 60 * 1000,
              teacherComment:
                'This is the single most beautiful thing I have ever seen',
              problemDbid: 3,
            },
            actions: [], // TODO
          },
          {
            prLog: {
              id: 35,
              prCeri: 'PRASRIVQ',
              startTime: dayjs()
                .subtract(1, 'day')
                .subtract(10, 'minute')
                .valueOf(),
              endTime: dayjs().subtract(1, 'day').valueOf(),
              continuousScore: 1,
              discreteScore: 1,
              answerText: '<p>0</p>',
              firstActionType: ActionType.STUDENT_RESPONSE_ACTION, // FIXME: Is this correct?
              attemptCount: 1,
              hintCount: 0,
              sawAnswer: false,
              pathInfo: '/problems/PRASRIVQ', // What's this?
              firstResponseTime: 2 * 60 * 1000,
              teacherComment:
                'This is the single most beautiful thing I have ever seen',
              problemDbid: 3,
            },
            actions: [], // TODO
          },
          {
            prLog: {
              id: 36,
              prCeri: 'PRASRIVQ',
              startTime: dayjs()
                .subtract(1, 'day')
                .subtract(10, 'minute')
                .valueOf(),
              endTime: dayjs().subtract(1, 'day').valueOf(),
              continuousScore: 1,
              discreteScore: 1,
              answerText: '<p>0</p>',
              firstActionType: ActionType.STUDENT_RESPONSE_ACTION, // FIXME: Is this correct?
              attemptCount: 1,
              hintCount: 0,
              sawAnswer: false,
              pathInfo: '/problems/PRASRIVQ', // What's this?
              firstResponseTime: 2 * 60 * 1000,
              teacherComment:
                'This is the single most beautiful thing I have ever seen',
              problemDbid: 3,
            },
            actions: [], // TODO
          },
          {
            prLog: {
              id: 37,
              prCeri: 'PRASRIVQ',
              startTime: dayjs()
                .subtract(1, 'day')
                .subtract(10, 'minute')
                .valueOf(),
              endTime: dayjs().subtract(2, 'day').valueOf(),
              continuousScore: 1,
              discreteScore: 1,
              answerText: '<p>0</p>',
              firstActionType: ActionType.STUDENT_RESPONSE_ACTION, // FIXME: Is this correct?
              attemptCount: 1,
              hintCount: 0,
              sawAnswer: false,
              pathInfo: '/problems/PRASRIVQ', // What's this?
              firstResponseTime: 2 * 60 * 1000,
              teacherComment:
                'This is the single most beautiful thing I have ever seen',
              problemDbid: 3,
            },
            actions: [], // TODO
          },
          {
            prLog: {
              id: 38,
              prCeri: 'PRASRIVQ',
              startTime: dayjs()
                .subtract(1, 'day')
                .subtract(10, 'minute')
                .valueOf(),
              endTime: dayjs().subtract(2, 'day').valueOf(),
              continuousScore: 1,
              discreteScore: 1,
              answerText: '<p>0</p>',
              firstActionType: ActionType.STUDENT_RESPONSE_ACTION, // FIXME: Is this correct?
              attemptCount: 1,
              hintCount: 0,
              sawAnswer: false,
              pathInfo: '/problems/PRASRIVQ', // What's this?
              firstResponseTime: 2 * 60 * 1000,
              teacherComment:
                'This is the single most beautiful thing I have ever seen',
              problemDbid: 3,
            },
            actions: [
              {
                actionType: ActionType.STUDENT_RESPONSE_ACTION,
                timestamp: dayjs().valueOf(),
              },
              {
                actionType: ActionType.PROBLEM_SET_LIMIT_EXCEEDED_ACTION,
              },
            ],
          },
          {
            prLog: {
              id: 39,
              prCeri: 'PRABGYJ8',
              startTime: dayjs().subtract(3, 'day').valueOf(),
              // endTime: null,
              continuousScore: 0,
              discreteScore: 0, // Open response grading?
              answerText: '<p>a<i>x</i> + b<i>y</i> + <i>c</i></p>',
              firstActionType: ActionType.STUDENT_RESPONSE_ACTION, // FIXME: Is this correct?
              attemptCount: 1,
              hintCount: 0,
              sawAnswer: true,
              pathInfo: '/problems/PRABGYJ8', // What's this?
              firstResponseTime: 2 * 60 * 1000,
              teacherComment: null,
              problemDbid: 1,
            },
            actions: [
              {
                actionType: ActionType.STUDENT_RESPONSE_ACTION,
                timestamp: dayjs().valueOf(),
              },
              {
                actionType: ActionType.PROBLEM_FINISHED_ACTION,
              },
            ],
          },
          {
            prLog: {
              id: 40,
              prCeri: 'PRABGYJ8',
              startTime: dayjs().subtract(3, 'day').valueOf(),
              // endTime: null,
              continuousScore: 0,
              discreteScore: 0, // Open response grading?
              answerText: '<p>a<i>x</i> + b<i>y</i> + <i>c</i></p>',
              firstActionType: ActionType.STUDENT_RESPONSE_ACTION, // FIXME: Is this correct?
              attemptCount: 1,
              hintCount: 0,
              sawAnswer: true,
              pathInfo: '/problems/PRABGYJ8', // What's this?
              firstResponseTime: 2 * 60 * 1000,
              teacherComment: null,
              problemDbid: 1,
            },
            actions: [
              {
                actionType: ActionType.STUDENT_RESPONSE_ACTION,
                timestamp: dayjs().valueOf(),
              },
              {
                actionType: ActionType.PROBLEM_FINISHED_ACTION,
              },
            ],
          },
          {
            prLog: {
              id: 41,
              prCeri: 'PRABGYJ8',
              startTime: dayjs().subtract(4, 'day').valueOf(),
              // endTime: null,
              continuousScore: 0,
              discreteScore: 0, // Open response grading?
              answerText: '<p>a<i>x</i> + b<i>y</i> + <i>c</i></p>',
              firstActionType: ActionType.STUDENT_RESPONSE_ACTION, // FIXME: Is this correct?
              attemptCount: 1,
              hintCount: 0,
              sawAnswer: true,
              pathInfo: '/problems/PRABGYJ8', // What's this?
              firstResponseTime: 2 * 60 * 1000,
              teacherComment: null,
              problemDbid: 1,
            },
            actions: [
              {
                actionType: ActionType.STUDENT_RESPONSE_ACTION,
                timestamp: dayjs().valueOf(),
              },
              {
                actionType: ActionType.PROBLEM_FINISHED_ACTION,
              },
            ],
          },
          {
            prLog: {
              id: 42,
              prCeri: 'PRABGYJ8',
              startTime: dayjs().subtract(4, 'day').valueOf(),
              // endTime: null,
              continuousScore: 0,
              discreteScore: 0, // Open response grading?
              answerText: '<p>a<i>x</i> + b<i>y</i> + <i>c</i></p>',
              firstActionType: ActionType.STUDENT_RESPONSE_ACTION, // FIXME: Is this correct?
              attemptCount: 1,
              hintCount: 0,
              sawAnswer: true,
              pathInfo: '/problems/PRABGYJ8', // What's this?
              firstResponseTime: 2 * 60 * 1000,
              teacherComment: null,
              problemDbid: 1,
            },
            actions: [
              {
                actionType: ActionType.STUDENT_RESPONSE_ACTION,
                timestamp: dayjs().valueOf(),
              },
              {
                actionType: ActionType.PROBLEM_FINISHED_ACTION,
              },
            ],
          },
          {
            prLog: {
              id: 43,
              prCeri: 'PRABGYJ8',
              startTime: dayjs().subtract(5, 'day').valueOf(),
              // endTime: null,
              continuousScore: 0,
              discreteScore: 0, // Open response grading?
              answerText: '<p>a<i>x</i> + b<i>y</i> + <i>c</i></p>',
              firstActionType: ActionType.STUDENT_RESPONSE_ACTION, // FIXME: Is this correct?
              attemptCount: 1,
              hintCount: 0,
              sawAnswer: true,
              pathInfo: '/problems/PRABGYJ8', // What's this?
              firstResponseTime: 2 * 60 * 1000,
              teacherComment: null,
              problemDbid: 1,
            },
            actions: [
              {
                actionType: ActionType.STUDENT_RESPONSE_ACTION,
                timestamp: dayjs().valueOf(),
              },
              {
                actionType: ActionType.PROBLEM_FINISHED_ACTION,
              },
            ],
          },
          {
            prLog: {
              id: 44,
              prCeri: 'PRABGYJ8',
              startTime: dayjs().subtract(6, 'day').valueOf(),
              // endTime: null,
              continuousScore: 0,
              discreteScore: 0, // Open response grading?
              answerText: '<p>a<i>x</i> + b<i>y</i> + <i>c</i></p>',
              firstActionType: ActionType.PROBLEM_SET_LIMIT_EXCEEDED_ACTION, // FIXME: Is this correct?
              attemptCount: 1,
              hintCount: 0,
              sawAnswer: true,
              pathInfo: '/problems/PRABGYJ8', // What's this?
              firstResponseTime: 2 * 60 * 1000,
              teacherComment: null,
              problemDbid: 1,
            },
            actions: [
              {
                actionType: ActionType.STUDENT_RESPONSE_ACTION,
                timestamp: dayjs().valueOf(),
              },
              {
                actionType: ActionType.PROBLEM_FINISHED_ACTION,
              },
            ],
          },
        ],
      },
      {
        studentXref: 'qCEq425AKdzg4liLV7jV',
        asStartTime: dayjs().subtract(2, 'day').valueOf(),
        asEndTime: null, // In progress
        psActions: null,
        problemLogAndActions: [
          {
            prLog: {
              id: 45,
              prCeri: 'PRABGYJ8',
              startTime: dayjs().subtract(1, 'day').valueOf(),
              // endTime: dayjs().valueOf(),
              endTime: null,
              continuousScore: 1,
              // discreteScore: null, // Open response grading?
              answerText: '<p>0</p>',
              firstActionType: ActionType.STUDENT_RESPONSE_ACTION, // FIXME: Is this correct?
              attemptCount: 1,
              hintCount: 0,
              sawAnswer: false,
              pathInfo: '/problems/PRABGYJ8', // What's this?
              firstResponseTime: 5 * 60 * 1000,
              teacherComment: null,
              problemDbid: 1,
            },
            actions: null, // TODO
          },
        ],
      }, // End student 3 data
    ], // End studentLogs
  } as object); // End assignment 3 report data
  server.create('assignmentReport', {
    contentInfo: {
      xref: 'TEST_XREF_1234',
      itemType: 'ASSIGNMENT',
      active: true,
    },
    summaryStatsAll: {
      avgScore: 0.8345,
      avgTime: 60 * 60 * 1000,
      studentStats: [
        {
          studentXref: 'C9rUPIqxW7q2x9oLpSDG',
          timeSpent: (21 * 40 + 13) * 1000,
          score: 0.9,
        },
        {
          studentXref: 'wNmUOMlw2AIqZ8bKUF9',
          timeSpent: null,
          score: 0.675,
        },
        {
          studentXref: 'J5ihYVyU3yihj14QGdd5',
          timeSpent: (24 * 60 * 60 + (9 * 60 + 5)) * 1000,
          score: 0.6,
        },
        {
          studentXref: 'qCEq425AKdzg4liLV7jV',
          score: 0.5,
        },
      ],
    },
    summaryStatsSubset: null,
    prAllStats: [
      {
        prCeri: 'PRABGYJ8',
        cwas: [
          {
            answer: '1/2',
            percent: 0.675,
          },
          {
            answer: '2/4',
            percent: 0.6,
          },
          {
            answer:
              '<img src="https://resources.assistments.org/fetch/SW/bcfa0b0f-db73-4dcc-8686-4d71f68d6cff.jpeg" />',
            percent: 0.65,
          },
        ],
        avgScore: 0.8723,
        avgTime: 5 * 60 * 1000,
        problemDbid: 1,
      },
    ], // End prAllStats
    prSubsetStats: null,
    studentLogs: [
      {
        studentXref: 'C9rUPIqxW7q2x9oLpSDG',
        asStartTime: dayjs().subtract(1, 'week').valueOf(),
        asEndTime: dayjs().subtract(4, 'day').valueOf(),
        psActions: [],
        problemLogAndActions: [
          {
            prLog: {
              id: 46,
              prCeri: 'PRABGYJ8',
              startTime: dayjs().subtract(1, 'week').valueOf(),
              endTime: dayjs().subtract(1, 'week').add(10, 'minute').valueOf(),
              continuousScore: 0,
              discreteScore: 0, // Open response grading?
              answerText:
                '<math xmlns="http://www.w3.org/1998/Math/MathML"><mrow><mi>x</mi><mo>=</mo><mfrac><mrow><mo form="prefix">−</mo><mi>b</mi><mo>±</mo><msqrt><msup><mi>b</mi><mn>2</mn></msup><mo>−</mo><mn>4</mn><mo>⁢</mo><mi>a</mi><mo>⁢</mo><mi>c</mi></msqrt></mrow><mrow><mn>2</mn><mo>⁢</mo><mi>a</mi></mrow></mfrac></mrow></math>',
              firstActionType: ActionType.STUDENT_RESPONSE_ACTION,
              attemptCount: 1,
              hintCount: 0,
              sawAnswer: false,
              pathInfo: '/problems/PRABGYJ8', // What's this?
              firstResponseTime: 4 * 60 * 1000,
              // teacherComment: 'Please give more explanation to your answer.',
              problemDbid: 1,
            },
            actions: [
              {
                actionType: ActionType.STUDENT_SUBMISSION_ACTION,
                correct: false,
                response:
                  '<math xmlns="http://www.w3.org/1998/Math/MathML"><mrow><mi>x</mi><mo>=</mo><mfrac><mrow><mo form="prefix">−</mo><mi>b</mi><mo>±</mo><msqrt><msup><mi>b</mi><mn>2</mn></msup><mo>−</mo><mn>4</mn><mo>⁢</mo><mi>a</mi><mo>⁢</mo><mi>c</mi></msqrt></mrow><mrow><mn>2</mn><mo>⁢</mo><mi>a</mi></mrow></mfrac></mrow></math>',
              },
              {
                actionType: ActionType.PROBLEM_FINISHED_ACTION,
              },
            ],
          },
        ], // End student 1 prLogsAndActions
      }, // End student 1 data
      {
        studentXref: 'wNmUOMlw2AIqZ8bKUF9',
        asStartTime: dayjs().subtract(5, 'day').valueOf(),
        asEndTime: dayjs().subtract(2, 'day').valueOf(),
        psActions: null,
        problemLogAndActions: [
          {
            prLog: {
              id: 47,
              prCeri: 'PRABGYJ8',
              startTime: dayjs().subtract(4, 'day').valueOf(),
              endTime: dayjs()
                .subtract(3, 'day')
                .add(3, 'hour')
                .add(5, 'second')
                .valueOf(),
              continuousScore: 1,
              discreteScore: 1, // Open response grading?
              answerText: '<p>a<i>x</i> + b<i>y</i> + <i>c</i></p>',
              firstActionType: ActionType.STUDENT_RESPONSE_ACTION, // FIXME: Is this correct?
              attemptCount: 3,
              hintCount: 2,
              sawAnswer: false,
              pathInfo: '/problems/PRABGYJ8', // What's this?
              firstResponseTime: 3 * 60 * 1000,
              teacherComment: 'I was expecting this to be inside a table',
              problemDbid: 1,
            },
            actions: [
              {
                actionType: ActionType.PROBLEM_STARTED_ACTION,
                timestamp: dayjs().subtract(5, 'day').valueOf(),
              },
              {
                actionType: ActionType.STUDENT_RESPONSE_ACTION,
                correct: true,
                response: 'Something smart and correct',
                timestamp: dayjs(Date.now())
                  .subtract(5, 'day')
                  .add(10, 'minute')
                  .valueOf(),
              },
              {
                actionType: ActionType.PROBLEM_FINISHED_ACTION,
                timestamp: dayjs(Date.now())
                  .subtract(5, 'day')
                  .add(15, 'minute')
                  .valueOf(),
              },
            ],
          },
        ],
      }, // End student 2 data
      // No scores - has not completed assignment yet
      {
        studentXref: 'J5ihYVyU3yihj14QGdd5',
        asStartTime: dayjs().subtract(1, 'day').valueOf(),
        // asEndTime: null, // In progress
        psActions: [],
        problemLogAndActions: [
          {
            prLog: {
              id: 48,
              prCeri: 'PRASRIVQ',
              startTime: dayjs()
                .subtract(1, 'day')
                .subtract(10, 'minute')
                .valueOf(),
              endTime: dayjs().subtract(1, 'day').valueOf(),
              continuousScore: 1,
              discreteScore: 1,
              answerText: '<p>0</p>',
              firstActionType: ActionType.STUDENT_RESPONSE_ACTION, // FIXME: Is this correct?
              attemptCount: 1,
              hintCount: 0,
              sawAnswer: false,
              pathInfo: '/problems/PRASRIVQ', // What's this?
              firstResponseTime: 2 * 60 * 1000,
              teacherComment:
                'This is the single most beautiful thing I have ever seen',
              problemDbid: 3,
            },
            actions: [], // TODO
          },
          {
            prLog: {
              id: 49,
              prCeri: 'PRABGYJ8',
              startTime: dayjs().subtract(1, 'day').valueOf(),
              // endTime: null,
              continuousScore: 0,
              discreteScore: 0, // Open response grading?
              answerText: '<p>a<i>x</i> + b<i>y</i> + <i>c</i></p>',
              firstActionType: ActionType.STUDENT_RESPONSE_ACTION, // FIXME: Is this correct?
              attemptCount: 1,
              hintCount: 0,
              sawAnswer: true,
              pathInfo: '/problems/PRABGYJ8', // What's this?
              firstResponseTime: 2 * 60 * 1000,
              teacherComment: null,
              problemDbid: 1,
            },
            actions: [
              {
                actionType: ActionType.STUDENT_RESPONSE_ACTION,
                timestamp: dayjs().valueOf(),
                studentWork: {
                  type: 'TEACHLEY',
                  value:
                    '<img src="https://app.assistments.org/images/assistments/157267.jpg" />',
                },
              },
              {
                actionType: ActionType.PROBLEM_FINISHED_ACTION,
              },
            ],
          },
        ],
      },
      {
        studentXref: 'qCEq425AKdzg4liLV7jV',
        asStartTime: dayjs().subtract(2, 'day').valueOf(),
        asEndTime: null, // In progress
        psActions: null,
        problemLogAndActions: [
          {
            prLog: {
              id: 50,
              prCeri: 'PRABGYJ8',
              startTime: dayjs().subtract(1, 'day').valueOf(),
              // endTime: dayjs().valueOf(),
              endTime: null,
              continuousScore: 1,
              // discreteScore: null, // Open response grading?
              answerText: '<p>0</p>',
              firstActionType: ActionType.STUDENT_RESPONSE_ACTION, // FIXME: Is this correct?
              attemptCount: 1,
              hintCount: 0,
              sawAnswer: false,
              pathInfo: '/problems/PRABGYJ8', // What's this?
              firstResponseTime: 5 * 60 * 1000,
              teacherComment: null,
              problemDbid: 1,
            },
            actions: null, // TODO
          },
        ],
      }, // End student 3 data
    ], // End studentLogs
  } as object);
  ////////////////////////////////////////////////
  //////////////// LEGACY REPORTS ////////////////
  ////////////////////////////////////////////////

  //////////////
  // REPORT 1 //
  //////////////
  server.create('assignmentReport', {
    contentInfo: {
      xref: 'GnhVZMxlBpkoRViC9KgL',
      itemType: 'ASSIGNMENT',
      active: true,
    },
    summaryStatsAll: {
      avgScore: 0.8345,
      avgTime: 60 * 60 * 1000,
      studentStats: [
        {
          studentXref: 'C9rUPIqxW7q2x9oLpSDG',
          timeSpent: (21 * 40 + 13) * 1000,
          score: 0.9,
        },
        {
          studentXref: 'wNmUOMlw2AIqZ8bKUF9',
          timeSpent: null,
          score: 0.675,
        },
        {
          studentXref: 'J5ihYVyU3yihj14QGdd5',
          timeSpent: (24 * 60 * 60 + (9 * 60 + 5)) * 1000,
          score: 0.6,
        },
        {
          studentXref: 'qCEq425AKdzg4liLV7jV',
          score: 0.5,
        },
      ],
    },
    summaryStatsSubset: null,
    prAllStats: [
      {
        prCeri: 'PRABSXNX.2009420',
        cwas: [
          {
            answer: '1/2',
            percent: 0.675,
          },
          {
            answer: '2/4',
            percent: 0.6,
          },
          {
            answer:
              '<img src="https://resources.assistments.org/fetch/SW/bcfa0b0f-db73-4dcc-8686-4d71f68d6cff.jpeg" />',
            percent: 0.65,
          },
        ],
        avgScore: 0.8723,
        avgTime: 5 * 60 * 1000,
        problemDbid: 1,
      },
    ], // End prAllStats
    prSubsetStats: null,
    studentLogs: [
      {
        studentXref: 'C9rUPIqxW7q2x9oLpSDG',
        asStartTime: dayjs().subtract(1, 'week').valueOf(),
        asEndTime: dayjs().subtract(4, 'day').valueOf(),
        psActions: [],
        problemLogAndActions: [
          {
            prLog: {
              id: 51,
              prCeri: 'PRABSXNX.2009420',
              startTime: dayjs().subtract(1, 'week').valueOf(),
              endTime: dayjs().subtract(1, 'week').add(10, 'minute').valueOf(),
              continuousScore: 0,
              discreteScore: 0, // Open response grading?
              answerText: '<i>y</i> = m<i>x</i> + <i>b</i>',
              firstActionType: ActionType.STUDENT_RESPONSE_ACTION,
              attemptCount: 1,
              hintCount: 0,
              sawAnswer: false,
              firstResponseTime: 4 * 60 * 1000,
              problemDbid: 5,
            },
            actions: [
              {
                actionType: ActionType.STUDENT_RESPONSE_ACTION,
                correct: false,
                response: '<i>x</i> = m<i>* -x</i> + <i>3b</i>',
              },
              {
                actionType: ActionType.PROBLEM_FINISHED_ACTION,
              },
            ],
          },
        ], // End student 1 prLogsAndActions
      }, // End student 1 data
      {
        studentXref: 'wNmUOMlw2AIqZ8bKUF9',
        asStartTime: dayjs().subtract(5, 'day').valueOf(),
        asEndTime: dayjs().subtract(2, 'day').valueOf(),
        psActions: null,
        problemLogAndActions: [
          {
            prLog: {
              id: 52,
              prCeri: 'PRABSXNX.2009420',
              startTime: dayjs().subtract(4, 'day').valueOf(),
              endTime: dayjs()
                .subtract(3, 'day')
                .add(3, 'hour')
                .add(5, 'second')
                .valueOf(),
              continuousScore: 1,
              discreteScore: 1, // Open response grading?
              answerText: '<p>a<i>x</i> + b<i>y</i> + <i>c</i></p>',
              firstActionType: ActionType.STUDENT_RESPONSE_ACTION, // FIXME: Is this correct?
              attemptCount: 3,
              hintCount: 2,
              sawAnswer: false,
              firstResponseTime: 3 * 60 * 1000,
              teacherComment: 'I was expecting this to be inside a table',
              problemDbid: 5,
            },
            actions: [
              {
                actionType: ActionType.STUDENT_RESPONSE_ACTION,
                correct: true,
                response: 'Something smart and correct',
              },
              {
                actionType: ActionType.PROBLEM_FINISHED_ACTION,
              },
            ],
          },
        ],
      }, // End student 2 data
      // No scores - has not completed assignment yet
      {
        studentXref: 'J5ihYVyU3yihj14QGdd5',
        asStartTime: dayjs().subtract(1, 'day').valueOf(),
        // asEndTime: null, // In progress
        psActions: [],
        problemLogAndActions: [
          {
            prLog: {
              id: 53,
              prCeri: 'PRABSXNX.2009420',
              startTime: dayjs()
                .subtract(1, 'day')
                .subtract(10, 'minute')
                .valueOf(),
              endTime: dayjs().subtract(1, 'day').valueOf(),
              continuousScore: 1,
              discreteScore: 1,
              answerText: '<p>0</p>',
              firstActionType: ActionType.STUDENT_RESPONSE_ACTION, // FIXME: Is this correct?
              attemptCount: 1,
              hintCount: 0,
              sawAnswer: false,
              firstResponseTime: 2 * 60 * 1000,
              teacherComment:
                'This is the single most beautiful thing I have ever seen',
              problemDbid: 5,
            },
            actions: [], // TODO
          },
          {
            prLog: {
              id: 54,
              prCeri: 'PRABSXNX.2009420',
              startTime: dayjs().subtract(1, 'day').valueOf(),
              // endTime: null,
              continuousScore: 0,
              discreteScore: 0, // Open response grading?
              answerText: '<p>a<i>x</i> + b<i>y</i> + <i>c</i></p>',
              firstActionType: ActionType.STUDENT_RESPONSE_ACTION, // FIXME: Is this correct?
              attemptCount: 1,
              hintCount: 0,
              sawAnswer: true,
              firstResponseTime: 2 * 60 * 1000,
              teacherComment: null,
              problemDbid: 5,
            },
            actions: [
              {
                actionType: ActionType.STUDENT_RESPONSE_ACTION,
                timestamp: dayjs().valueOf(),
              },
              {
                actionType: ActionType.PROBLEM_FINISHED_ACTION,
              },
            ],
          },
        ],
      },
      {
        studentXref: 'qCEq425AKdzg4liLV7jV',
        asStartTime: dayjs().subtract(2, 'day').valueOf(),
        asEndTime: null, // In progress
        psActions: null,
        problemLogAndActions: [
          {
            prLog: {
              id: 55,
              prCeri: 'PRABSXNX.2009420',
              startTime: dayjs().subtract(1, 'day').valueOf(),
              // endTime: dayjs().valueOf(),
              endTime: null,
              continuousScore: 1,
              // discreteScore: null, // Open response grading?
              answerText: '<p>0</p>',
              firstActionType: ActionType.STUDENT_RESPONSE_ACTION, // FIXME: Is this correct?
              attemptCount: 1,
              hintCount: 0,
              sawAnswer: false,
              firstResponseTime: 5 * 60 * 1000,
              teacherComment: null,
              problemDbid: 5,
            },
            actions: null, // TODO
          },
        ],
      }, // End student 3 data
    ], // End studentLogs
  } as object);
  server.create('assignmentReport', {
    contentInfo: {
      xref: 'kbpqEqpkycnPaAsRhlJ0',
      itemType: 'ASSIGNMENT',
      active: true,
    },
    summaryStatsAll: {
      avgScore: 1,
      avgTime: 56384,
      studentStats: [
        {
          studentXref: 'C9rUPIqxW7q2x9oLpSDG',
          timeSpent: (21 * 60 + 13) * 1000,
          score: 0.9,
          percentComplete: 1,
        },
        {
          studentXref: 'wNmUOMlw2AIqZ8bKUF9',
          timeSpent: null,
          score: 0.675,
          percentComplete: 0.5,
        },
        {
          studentXref: 'J5ihYVyU3yihj14QGdd5',
          timeSpent: (24 * 60 * 60 + (9 * 60 + 5)) * 1000,
          score: 0.6,
          percentComplete: 0.5,
        },
      ],
    },
    prAllStats: [
      {
        prCeri: 'PRABT334.2076300',
        problemDbid: 2076300,
        avgScore: 1,
        avgTime: 19102,
      },
      {
        prCeri: 'PRABSG2H.1986936',
        problemDbid: 1986936,
        avgTime: 7183,
      },
      {
        prCeri: 'PRABSG2H.1986935',
        problemDbid: 1986935,
        avgScore: 0.67,
        avgTime: 21576,
      },
    ],
    studentLogs: [
      /////////////////////////////////
      // INCOMPLETE SCORES FOR TESTS //
      /////////////////////////////////
      {
        studentXref: 'wNmUOMlw2AIqZ8bKUF9',
        asStartTime: 1679679252869,
        asEndTime: 1679679309253,
        psActions: [
          {
            actionType: ActionType.ASSIGNMENT_STARTED_ACTION,
          },
          {
            actionType: ActionType.TIMER_STARTED_ACTION,
            timeLeft: 500,
          },
          {
            actionType: ActionType.TIMER_FINISHED_ACTION,
            timeLeft: 0,
          },
        ],
        problemLogAndActions: [
          {
            prLog: {
              prCeri: 'PRABSG2H.1986935',
              id: 172080545,
              startTime: 1679679276225,
              endTime: 1679679283408,
              pathInfo: '/PSABSY9D.8551476/PSBROFL/PRABSG2H.1986935~8551477',
              firstResponseTime: 7182,
              answerText: '<p>.</p>',
              attemptCount: 1,
              hintCount: 0,
              firstActionType: 'ANSWER',
              problemDbid: 1986935,
              sawAnswer: false,
              partLogData: {
                '1': {
                  response: [
                    '<p>I am wondering about how to answer this problem.</p>',
                  ],
                  teacherComment:
                    'Think about how they can increase and decrease and keep the same ratio',
                  hintCount: null,
                  attemptCount: null,
                  continuousScore: 0.25,
                  discreteScore: null,
                },
              },
            },
            actions: [
              {
                actionType: 'PROBLEM_STARTED_ACTION',
                timestamp: 1679679276225,
                pathInfo: '/PSABSY9D.8551476/PSBROFL/PRABSG2H.1986935~8551477',
                hintId: 0,
                correct: false,
                timeLeft: 0,
              },
              {
                actionType: 'STUDENT_SUBMISSION_ACTION',
                timestamp: 1679679283407,
                pathInfo: '/PSABSY9D.8551476/PSBROFL/PRABSG2H.1986935~8551477',
                hintId: 0,
                response: '<p>.</p>',
                correct: false,
                timeLeft: 0,
              },
            ],
          },
        ],
      },
      ////////////
      // ASSESS //
      ////////////
      {
        studentXref: 'J5ihYVyU3yihj14QGdd5',
        asStartTime: 1679679252869,
        asEndTime: 1679679309253,
        psActions: [
          {
            actionType: ActionType.ASSIGNMENT_STARTED_ACTION,
          },
          {
            actionType: ActionType.TIMER_STARTED_ACTION,
            timeLeft: 500,
          },
          {
            actionType: ActionType.TIMER_FINISHED_ACTION,
            timeLeft: 0,
          },
        ],
        problemLogAndActions: [
          {
            prLog: {
              prCeri: 'PRABSG2H.1986936',
              id: 172080547,
              startTime: 1679679276225,
              endTime: 1679679283408,
              pathInfo: '/PSABSY9D.8551476/PSBROFL/PRABSG2H.1986936~8551477',
              firstResponseTime: 7182,
              answerText: '<p>.</p>',
              attemptCount: 1,
              hintCount: 0,
              firstActionType: 'ANSWER',
              problemDbid: 1986936,
              sawAnswer: false,
              partLogData: {
                '1': {
                  response: ['Shape B'],
                  teacherComment: null,
                  hintCount: null,
                  attemptCount: null,
                  continuousScore: 0.5,
                  discreteScore: 0,
                },
              },
            },
            actions: [
              {
                actionType: 'PROBLEM_STARTED_ACTION',
                timestamp: 1679679276225,
                pathInfo: '/PSABSY9D.8551476/PSBROFL/PRABSG2H.1986936~8551477',
                hintId: 0,
                correct: false,
                timeLeft: 0,
              },
              {
                actionType: 'STUDENT_SUBMISSION_ACTION',
                timestamp: 1679679283407,
                pathInfo: '/PSABSY9D.8551476/PSBROFL/PRABSG2H.1986936~8551477',
                hintId: 0,
                response: '<p>.</p>',
                correct: false,
                timeLeft: 0,
              },
            ],
          },
        ],
      },
      {
        studentXref: 'wNmUOMlw2AIqZ8bKUF9',
        asStartTime: 1679679252869,
        asEndTime: 1679679309253,
        psActions: [
          {
            actionType: ActionType.ASSIGNMENT_STARTED_ACTION,
          },
          {
            actionType: ActionType.TIMER_STARTED_ACTION,
            timeLeft: 500,
          },
          {
            actionType: ActionType.TIMER_FINISHED_ACTION,
            timeLeft: 0,
          },
        ],
        problemLogAndActions: [
          {
            prLog: {
              prCeri: 'PRABSG2H.1986936',
              id: 172080548,
              startTime: 1679679276225,
              endTime: 1679679283408,
              pathInfo: '/PSABSY9D.8551476/PSBROFL/PRABSG2H.1986936~8551477',
              firstResponseTime: 7182,
              answerText: '<p>.</p>',
              attemptCount: 1,
              hintCount: 0,
              firstActionType: 'ANSWER',
              problemDbid: 1986936,
              sawAnswer: false,
              partLogData: {
                '1': {
                  response: ['Shape B'],
                  teacherComment: null,
                  hintCount: null,
                  attemptCount: null,
                  continuousScore: 0.5,
                  discreteScore: 0,
                },
              },
            },
            actions: [
              {
                actionType: 'PROBLEM_STARTED_ACTION',
                timestamp: 1679679276225,
                pathInfo: '/PSABSY9D.8551476/PSBROFL/PRABSG2H.1986936~8551477',
                hintId: 0,
                correct: false,
                timeLeft: 0,
              },
              {
                actionType: 'STUDENT_SUBMISSION_ACTION',
                timestamp: 1679679283407,
                pathInfo: '/PSABSY9D.8551476/PSBROFL/PRABSG2H.1986936~8551477',
                hintId: 0,
                response: '<p>.</p>',
                correct: false,
                timeLeft: 0,
              },
            ],
          },
        ],
      },
      {
        studentXref: 'qCEq425AKdzg4liLV7jV',
        asStartTime: 1679679252869,
        asEndTime: 1679679309253,
        psActions: [
          {
            actionType: ActionType.ASSIGNMENT_STARTED_ACTION,
          },
          {
            actionType: ActionType.TIMER_STARTED_ACTION,
            timeLeft: 500,
          },
          {
            actionType: ActionType.TIMER_FINISHED_ACTION,
            timeLeft: 0,
          },
        ],
        problemLogAndActions: [
          {
            prLog: {
              prCeri: 'PRABSG2H.1986936',
              id: 172080549,
              startTime: 1679679276225,
              endTime: 1679679283408,
              pathInfo: '/PSABSY9D.8551476/PSBROFL/PRABSG2H.1986936~8551477',
              firstResponseTime: 7182,
              answerText: '<p>.</p>',
              attemptCount: 1,
              hintCount: 0,
              firstActionType: 'ANSWER',
              problemDbid: 1986936,
              sawAnswer: false,
              partLogData: {
                '1': {
                  response: ['Shape B'],
                  teacherComment: null,
                  hintCount: null,
                  attemptCount: null,
                  continuousScore: 0.5,
                  discreteScore: 0,
                },
              },
            },
            actions: [
              {
                actionType: 'PROBLEM_STARTED_ACTION',
                timestamp: 1679679276225,
                pathInfo: '/PSABSY9D.8551476/PSBROFL/PRABSG2H.1986936~8551477',
                hintId: 0,
                correct: false,
                timeLeft: 0,
              },
              {
                actionType: 'STUDENT_SUBMISSION_ACTION',
                timestamp: 1679679283407,
                pathInfo: '/PSABSY9D.8551476/PSBROFL/PRABSG2H.1986936~8551477',
                hintId: 0,
                response: '<p>.</p>',
                correct: false,
                timeLeft: 0,
              },
            ],
          },
        ],
      },
      {
        studentXref: 'hLVmvz6zgr6nmyEMW9qt',
        asStartTime: 1679679252869,
        asEndTime: 1679679309253,
        psActions: [
          {
            actionType: ActionType.ASSIGNMENT_STARTED_ACTION,
          },
          {
            actionType: ActionType.TIMER_STARTED_ACTION,
            timeLeft: 500,
          },
          {
            actionType: ActionType.TIMER_FINISHED_ACTION,
            timeLeft: 0,
          },
        ],
        problemLogAndActions: [
          {
            prLog: {
              prCeri: 'PRABSG2H.1986936',
              id: 172080510,
              startTime: 1679679276225,
              endTime: 1679679283408,
              pathInfo: '/PSABSY9D.8551476/PSBROFL/PRABSG2H.1986936~8551477',
              firstResponseTime: 7182,
              answerText: '<p>.</p>',
              attemptCount: 1,
              hintCount: 0,
              firstActionType: 'ANSWER',
              problemDbid: 1986936,
              sawAnswer: false,
              partLogData: {
                '1': {
                  response: ['Shape B'],
                  teacherComment: null,
                  hintCount: null,
                  attemptCount: null,
                  continuousScore: 0.5,
                  discreteScore: 0,
                },
              },
            },
            actions: [
              {
                actionType: 'PROBLEM_STARTED_ACTION',
                timestamp: 1679679276225,
                pathInfo: '/PSABSY9D.8551476/PSBROFL/PRABSG2H.1986936~8551477',
                hintId: 0,
                correct: false,
                timeLeft: 0,
              },
              {
                actionType: 'STUDENT_SUBMISSION_ACTION',
                timestamp: 1679679283407,
                pathInfo: '/PSABSY9D.8551476/PSBROFL/PRABSG2H.1986936~8551477',
                hintId: 0,
                response: '<p>.</p>',
                correct: false,
                timeLeft: 0,
              },
            ],
          },
        ],
      },
      {
        studentXref: 'C9rUPIqxW7q2x9oLpSDG',
        asStartTime: 1679679252869,
        asEndTime: 1679679309253,
        psActions: [
          {
            actionType: 'ASSIGNMENT_STARTED_ACTION',
            timestamp: 1679679252869,
            pathInfo: '/',
            hintId: 0,
            correct: false,
            timeLeft: 0,
          },
          {
            actionType: 'ASSIGNMENT_FINISHED_ACTION',
            timestamp: 1679679309253,
            pathInfo: '/',
            hintId: 0,
            correct: false,
            timeLeft: 0,
          },
        ],
        problemLogAndActions: [
          {
            prLog: {
              prCeri: 'PRABSG2H.1986936',
              id: 172080544,
              startTime: 1679679276225,
              endTime: 1679679283408,
              pathInfo: '/PSABSY9D.8551476/PSBROFL/PRABSG2H.1986936~8551477',
              firstResponseTime: 7182,
              discreteScore: 0,
              continuousScore: 0.5,
              answerText: '<p>.</p>',
              attemptCount: 1,
              hintCount: 0,
              firstActionType: 'ANSWER',
              problemDbid: 1986936,
              sawAnswer: false,
              partLogData: {
                '1': {
                  response: ['Yes'],
                  teacherComment: null,
                  hintCount: null,
                  attemptCount: null,
                  continuousScore: 1.0,
                  discreteScore: 1,
                },
              },
            },
            actions: [
              {
                actionType: 'PROBLEM_STARTED_ACTION',
                timestamp: 1679679276225,
                pathInfo: '/PSABSY9D.8551476/PSBROFL/PRABSG2H.1986936~8551477',
                hintId: 0,
                correct: false,
                timeLeft: 0,
              },
              {
                actionType: 'STUDENT_SUBMISSION_ACTION',
                timestamp: 1679679283407,
                pathInfo: '/PSABSY9D.8551476/PSBROFL/PRABSG2H.1986936~8551477',
                hintId: 0,
                response: '<p>.</p>',
                correct: false,
                timeLeft: 0,
              },
              {
                actionType: 'PROBLEM_FINISHED_ACTION',
                timestamp: 1679679283408,
                pathInfo: '/PSABSY9D.8551476/PSBROFL/PRABSG2H.1986936~8551477',
                hintId: 0,
                correct: false,
                timeLeft: 0,
              },
            ],
          },
          {
            prLog: {
              prCeri: 'PRABSG2H.1986935',
              id: 172080501,
              startTime: 1679679252892,
              endTime: 1679679274468,
              pathInfo: '/PSABSY9D.8551476/PSBROFL/PRABSG2H.1986935~8551477',
              firstResponseTime: 11179,
              discreteScore: 0,
              continuousScore: 0.67,
              answerText: '1',
              attemptCount: 2,
              hintCount: 0,
              firstActionType: 'ANSWER',
              problemDbid: 1986935,
              sawAnswer: false,
              partLogData: {
                '1': {
                  response: ['Yes'],
                  teacherComment: null,
                  hintCount: null,
                  attemptCount: null,
                  continuousScore: 1.0,
                  discreteScore: 1,
                },
              },
            },
            actions: [
              {
                actionType: 'PROBLEM_STARTED_ACTION',
                timestamp: 1679679252892,
                pathInfo: '/PSABSY9D.8551476/PSBROFL/PRABSG2H.1986935~8551477',
                hintId: 0,
                correct: false,
                timeLeft: 0,
              },
              {
                actionType: 'STUDENT_RESPONSE_ACTION',
                timestamp: 1679679264071,
                pathInfo: '/PSABSY9D.8551476/PSBROFL/PRABSG2H.1986935~8551477',
                hintId: 0,
                response: '1',
                correct: false,
                timeLeft: 0,
              },
              {
                actionType: 'CAIT_MESSAGE_REQUESTED_ACTION',
                timestamp: 1679679264072,
                pathInfo: '/PSABSY9D.8551476/PSBROFL/PRABSG2H.1986935~8551477',
                message: 'I need help with this problem.',
                timeLeft: 0,
              },
              {
                actionType: 'CAIT_MESSAGE_RESPONSE_ACTION',
                timestamp: 1679679264073,
                pathInfo: '/PSABSY9D.8551476/PSBROFL/PRABSG2H.1986935~8551477',
                response:
                  "Sure! Let's start by reviewing the problem together.",
              },
              {
                actionType: 'CAIT_MESSAGE_REQUESTED_ACTION',
                timestamp: 1679679264074,
                pathInfo: '/PSABSY9D.8551476/PSBROFL/PRABSG2H.1986935~8551477',
                message: 'I am supposed to do 4 x 2.',
              },
              {
                actionType: 'CAIT_MESSAGE_RESPONSE_ACTION',
                timestamp: 1679679264075,
                pathInfo: '/PSABSY9D.8551476/PSBROFL/PRABSG2H.1986935~8551477',
                response: 'Okay, draw four groups of 2 and what do you get?.',
              },
              {
                actionType: 'STUDENT_RESPONSE_ACTION',
                timestamp: 1679679274467,
                pathInfo: '/PSABSY9D.8551476/PSBROFL/PRABSG2H.1986935~8551477',
                hintId: 0,
                response: '5/6',
                correct: true,
                timeLeft: 0,
              },
              {
                actionType: 'PROBLEM_FINISHED_ACTION',
                timestamp: 1679679274468,
                pathInfo: '/PSABSY9D.8551476/PSBROFL/PRABSG2H.1986935~8551477',
                hintId: 0,
                correct: false,
                timeLeft: 0,
              },
            ],
          },
          {
            prLog: {
              prCeri: 'PRABT334.2076300',
              id: 172080585,
              startTime: 1679679290149,
              endTime: 1679679309251,
              pathInfo: '/PSABSY9D.8551476/PRABT334.2076300',
              firstResponseTime: 19101,
              discreteScore: 1,
              continuousScore: 1,
              answerText: '6/8',
              attemptCount: 1,
              hintCount: 0,
              redoParentXref: 'PSBROFL',
              firstActionType: 'ANSWER',
              problemDbid: 2076300,
              sawAnswer: false,
              partLogData: {
                '1': {
                  response: [
                    '<p>add 50% of each side to itself and it works</p>',
                  ],
                  teacherComment: 'Great thinking!',
                  hintCount: null,
                  attemptCount: null,
                  continuousScore: 0.75,
                  discreteScore: null,
                },
              },
            },
            actions: [
              {
                actionType: 'PROBLEM_STARTED_ACTION',
                timestamp: 1679679290149,
                pathInfo: '/PSABSY9D.8551476/PRABT334.2076300',
                hintId: 0,
                correct: false,
                timeLeft: 0,
              },
              {
                actionType: 'STUDENT_RESPONSE_ACTION',
                timestamp: 1679679309250,
                pathInfo: '/PSABSY9D.8551476/PRABT334.2076300',
                hintId: 0,
                response: '6/8',
                correct: true,
                timeLeft: 0,
              },
              {
                actionType: 'PROBLEM_FINISHED_ACTION',
                timestamp: 1679679309251,
                pathInfo: '/PSABSY9D.8551476/PRABT334.2076300',
                hintId: 0,
                correct: false,
                timeLeft: 0,
              },
            ],
          },
        ],
      },
    ],
  } as object);

  server.create('assignmentReport', {
    contentInfo: {
      xref: 'G0MJJi02j3zSo9tOH2lC',
      itemType: 'ASSIGNMENT',
      active: true,
    },
    summaryStatsAll: {
      avgScore: 1,
      avgTime: 56384,
      studentStats: [
        {
          studentXref: 'C9rUPIqxW7q2x9oLpSDG',
          timeSpent: (21 * 60 + 13) * 1000,
          score: 0.9,
        },
        {
          studentXref: 'wNmUOMlw2AIqZ8bKUF9',
          timeSpent: null,
          score: 0.675,
        },
        {
          studentXref: 'J5ihYVyU3yihj14QGdd5',
          timeSpent: (24 * 60 * 60 + (9 * 60 + 5)) * 1000,
          score: 0.6,
        },
      ],
    },
    prAllStats: [
      {
        prCeri: 'PRABT334.2076300',
        problemDbid: 2076300,
        avgScore: 1,
        avgTime: 19102,
      },
      {
        prCeri: 'PRABSG2H.1986936',
        problemDbid: 1986936,
        avgTime: 7183,
      },
      {
        prCeri: 'PRABSG2H.1986935',
        problemDbid: 1986935,
        avgScore: 0.67,
        avgTime: 21576,
      },
    ],
    studentLogs: [
      /////////////////////////////////
      // INCOMPLETE SCORES FOR TESTS //
      /////////////////////////////////
      {
        studentXref: 'wNmUOMlw2AIqZ8bKUF9',
        asStartTime: 1679679252869,
        asEndTime: 1679679309253,
        psActions: [
          {
            actionType: ActionType.ASSIGNMENT_STARTED_ACTION,
          },
          {
            actionType: ActionType.TIMER_STARTED_ACTION,
            timeLeft: 500,
          },
          {
            actionType: ActionType.TIMER_FINISHED_ACTION,
            timeLeft: 0,
          },
        ],
        problemLogAndActions: [
          {
            prLog: {
              prCeri: 'PRABSG2H.1986935',
              id: 172080545,
              startTime: 1679679276225,
              endTime: 1679679283408,
              pathInfo: '/PSABSY9D.8551476/PSBROFL/PRABSG2H.1986935~8551477',
              firstResponseTime: 7182,
              answerText: '<p>.</p>',
              attemptCount: 1,
              hintCount: 0,
              firstActionType: 'ANSWER',
              problemDbid: 1986935,
              sawAnswer: false,
            },
            actions: [
              {
                actionType: 'PROBLEM_STARTED_ACTION',
                timestamp: 1679679276225,
                pathInfo: '/PSABSY9D.8551476/PSBROFL/PRABSG2H.1986935~8551477',
                hintId: 0,
                correct: false,
                timeLeft: 0,
              },
              {
                actionType: 'STUDENT_SUBMISSION_ACTION',
                timestamp: 1679679283407,
                pathInfo: '/PSABSY9D.8551476/PSBROFL/PRABSG2H.1986935~8551477',
                hintId: 0,
                response: '<p>.</p>',
                correct: false,
                timeLeft: 0,
              },
            ],
          },
        ],
      },
      ////////////
      // ASSESS //
      ////////////
      {
        studentXref: 'J5ihYVyU3yihj14QGdd5',
        asStartTime: 1679679252869,
        asEndTime: 1679679309253,
        psActions: [
          {
            actionType: ActionType.ASSIGNMENT_STARTED_ACTION,
          },
          {
            actionType: ActionType.TIMER_STARTED_ACTION,
            timeLeft: 500,
          },
          {
            actionType: ActionType.TIMER_FINISHED_ACTION,
            timeLeft: 0,
          },
        ],
        problemLogAndActions: [
          {
            prLog: {
              prCeri: 'PRABSG2H.1986936',
              id: 172080547,
              startTime: 1679679276225,
              endTime: 1679679283408,
              pathInfo: '/PSABSY9D.8551476/PSBROFL/PRABSG2H.1986936~8551477',
              firstResponseTime: 7182,
              answerText: '<p>.</p>',
              attemptCount: 1,
              hintCount: 0,
              firstActionType: 'ANSWER',
              problemDbid: 1986936,
              sawAnswer: false,
            },
            actions: [
              {
                actionType: 'PROBLEM_STARTED_ACTION',
                timestamp: 1679679276225,
                pathInfo: '/PSABSY9D.8551476/PSBROFL/PRABSG2H.1986936~8551477',
                hintId: 0,
                correct: false,
                timeLeft: 0,
              },
              {
                actionType: 'STUDENT_SUBMISSION_ACTION',
                timestamp: 1679679283407,
                pathInfo: '/PSABSY9D.8551476/PSBROFL/PRABSG2H.1986936~8551477',
                hintId: 0,
                response: '<p>.</p>',
                correct: false,
                timeLeft: 0,
              },
            ],
          },
        ],
      },
      {
        studentXref: 'C9rUPIqxW7q2x9oLpSDG',
        asStartTime: 1679679252869,
        asEndTime: 1679679309253,
        psActions: [
          {
            actionType: 'ASSIGNMENT_STARTED_ACTION',
            timestamp: 1679679252869,
            pathInfo: '/',
            hintId: 0,
            correct: false,
            timeLeft: 0,
          },
          {
            actionType: 'ASSIGNMENT_FINISHED_ACTION',
            timestamp: 1679679309253,
            pathInfo: '/',
            hintId: 0,
            correct: false,
            timeLeft: 0,
          },
        ],
        problemLogAndActions: [
          {
            prLog: {
              prCeri: 'PRABSG2H.1986936',
              id: 172080544,
              startTime: 1679679276225,
              endTime: 1679679283408,
              pathInfo: '/PSABSY9D.8551476/PSBROFL/PRABSG2H.1986936~8551477',
              firstResponseTime: 7182,
              discreteScore: 0,
              continuousScore: 0.5,
              answerText: '<p>.</p>',
              attemptCount: 1,
              hintCount: 0,
              firstActionType: 'ANSWER',
              problemDbid: 1986936,
              sawAnswer: false,
            },
            actions: [
              {
                actionType: 'PROBLEM_STARTED_ACTION',
                timestamp: 1679679276225,
                pathInfo: '/PSABSY9D.8551476/PSBROFL/PRABSG2H.1986936~8551477',
                hintId: 0,
                correct: false,
                timeLeft: 0,
              },
              {
                actionType: 'STUDENT_SUBMISSION_ACTION',
                timestamp: 1679679283407,
                pathInfo: '/PSABSY9D.8551476/PSBROFL/PRABSG2H.1986936~8551477',
                hintId: 0,
                response: '<p>.</p>',
                correct: false,
                timeLeft: 0,
              },
              {
                actionType: 'PROBLEM_FINISHED_ACTION',
                timestamp: 1679679283408,
                pathInfo: '/PSABSY9D.8551476/PSBROFL/PRABSG2H.1986936~8551477',
                hintId: 0,
                correct: false,
                timeLeft: 0,
              },
            ],
          },
          {
            prLog: {
              prCeri: 'PRABSG2H.1986935',
              id: 172080501,
              startTime: 1679679252892,
              endTime: 1679679274468,
              pathInfo: '/PSABSY9D.8551476/PSBROFL/PRABSG2H.1986935~8551477',
              firstResponseTime: 11179,
              discreteScore: 0,
              continuousScore: 0.67,
              answerText: '1',
              attemptCount: 2,
              hintCount: 0,
              firstActionType: 'ANSWER',
              problemDbid: 1986935,
              sawAnswer: false,
            },
            actions: [
              {
                actionType: 'PROBLEM_STARTED_ACTION',
                timestamp: 1679679252892,
                pathInfo: '/PSABSY9D.8551476/PSBROFL/PRABSG2H.1986935~8551477',
                hintId: 0,
                correct: false,
                timeLeft: 0,
              },
              {
                actionType: 'STUDENT_RESPONSE_ACTION',
                timestamp: 1679679264071,
                pathInfo: '/PSABSY9D.8551476/PSBROFL/PRABSG2H.1986935~8551477',
                hintId: 0,
                response: '1',
                correct: false,
                timeLeft: 0,
              },
              {
                actionType: 'STUDENT_RESPONSE_ACTION',
                timestamp: 1679679274467,
                pathInfo: '/PSABSY9D.8551476/PSBROFL/PRABSG2H.1986935~8551477',
                hintId: 0,
                response: '5/6',
                correct: true,
                timeLeft: 0,
              },
              {
                actionType: 'PROBLEM_FINISHED_ACTION',
                timestamp: 1679679274468,
                pathInfo: '/PSABSY9D.8551476/PSBROFL/PRABSG2H.1986935~8551477',
                hintId: 0,
                correct: false,
                timeLeft: 0,
              },
            ],
          },
          {
            prLog: {
              prCeri: 'PRABT334.2076300',
              id: 172080585,
              startTime: 1679679290149,
              endTime: 1679679309251,
              pathInfo: '/PSABSY9D.8551476/PRABT334.2076300',
              firstResponseTime: 19101,
              discreteScore: 1,
              continuousScore: 1,
              answerText: '6/8',
              attemptCount: 1,
              hintCount: 0,
              redoParentXref: 'PSBROFL',
              firstActionType: 'ANSWER',
              problemDbid: 2076300,
              sawAnswer: false,
            },
            actions: [
              {
                actionType: 'PROBLEM_STARTED_ACTION',
                timestamp: 1679679290149,
                pathInfo: '/PSABSY9D.8551476/PRABT334.2076300',
                hintId: 0,
                correct: false,
                timeLeft: 0,
              },
              {
                actionType: 'STUDENT_RESPONSE_ACTION',
                timestamp: 1679679309250,
                pathInfo: '/PSABSY9D.8551476/PRABT334.2076300',
                hintId: 0,
                response: '6/8',
                correct: true,
                timeLeft: 0,
              },
              {
                actionType: 'PROBLEM_FINISHED_ACTION',
                timestamp: 1679679309251,
                pathInfo: '/PSABSY9D.8551476/PRABT334.2076300',
                hintId: 0,
                correct: false,
                timeLeft: 0,
              },
            ],
          },
        ],
      },
    ],
  } as object);

  const problemCeris = [
    'PRABGYJ8',
    'PRABSG2H.1986935',
    'PRABSG2H.1986936',
    'PSBROFL',
    'PRABSXNX.2009420',
    'PRABSXNW.2009417',
  ];

  for (let i = 1; i <= 30; i++) {
    server.create('assignmentReport', {
      contentInfo: {
        xref: `GeneratedAssignment-${i}`,
        itemType: 'ASSIGNMENT',
        active: true,
      },
      summaryStatsAll: {
        avgScore: 0.8345,
        avgTime: faker.number.int({ min: 60, max: 1000000 }),
        studentStats: users.map((user) => {
          return {
            studentXref: user.xref,
            timeSpent: faker.number.int({ min: 60, max: 1000000 }),
            score: faker.number.float({
              max: 1,
              precision: 0.01,
            }),
          };
        }),
      },
      summaryStatsSubset: null,
      prAllStats: problemCeris.map((ceri) => {
        return {
          prCeri: ceri,
          cwas: [],
          avgScore: faker.number.float({
            max: 1,
            min: 0.5,
            precision: 0.01,
          }),
          avgTime: 5 * 60 * 1000,
        };
      }),
      prSubsetStats: null,
      studentLogs: users.map((user, index) => {
        return {
          studentXref: user.xref,
          asStartTime: dayjs().subtract(i, 'day').valueOf(),
          asEndTime:
            index % 2 == 0 && i % 3 == 0
              ? null
              : dayjs().subtract(i, 'day').valueOf(),
          psActions: [],
          problemLogAndActions: problemCeris.map((ceri) => {
            return {
              prLog: {
                id: 1,
                prCeri: ceri,
                startTime: dayjs().subtract(1, 'day').valueOf(),
                endTime: dayjs()
                  .subtract(1, 'day')
                  .add(faker.number.int(1000), 'second')
                  .valueOf(),
                continuousScore: randomScore(),
                discreteScore: 0, // Open response grading?
                answerText: '<i>y</i> = m<i>x</i> + <i>b</i>',
                firstActionType: ActionType.STUDENT_RESPONSE_ACTION,
                attemptCount: 1,
                hintCount: 0,
                sawAnswer: false,
                pathInfo: `/problems/${ceri}`, // What's this?
                firstResponseTime: 4 * 60 * 1000,
                problemDbid: 1,
              },
              actions: [
                {
                  timestamp: 1710355672073,
                  actionType: ActionType.ASSIGNMENT_STARTED_ACTION,
                  targetXref: 'Not sure what this should be',
                  path: '/',
                  assignmentXref: `GeneratedAssignment-${i}`,
                  userXref: user.xref,
                  tutorUid: 'no idea, a user uuid?',
                  tutorUidSequence: 1,
                },
                // Sometimes add hints requested
                ...(i % 2 == 0
                  ? [
                      {
                        actionType: ActionType.HINT_REQUESTED_ACTION,
                        tutoringCeri:
                          index % 2 == 0 ? 'SomeTutoring' : 'DifferentTutoring',
                        index: 0,
                      },
                      {
                        actionType: ActionType.HINT_REQUESTED_ACTION,
                        tutoringCeri:
                          index % 2 == 0 ? 'SomeTutoring' : 'DifferentTutoring',
                        index: 1,
                      },
                      {
                        actionType: ActionType.HINT_REQUESTED_ACTION,
                        tutoringCeri:
                          index % 2 == 0 ? 'SomeTutoring' : 'DifferentTutoring',
                        index: 2,
                      },
                    ]
                  : []),
                // Sometimes add seen answer
                ...(i % 3 == 0
                  ? [
                      {
                        timestamp: 1710356076267,
                        actionType: ActionType.ANSWER_REQUESTED_ACTION,
                        targetXref: 'PRBKGEX',
                        path: '/',
                        assignmentXref: `GeneratedAssignment-${i}`,
                        userXref: user.xref,
                        tutorUid: 'no idea, a user uuid?',
                        tutorUidSequence: 2,
                        problemId: 1,
                        isFirstResponse: true,
                        problemScore: {
                          continuousScore: 0.0,
                          discreteScore: 0,
                        },
                        partScore: {
                          '1': {
                            continuousScore: 0.0,
                            discreteScore: 0,
                          },
                        },
                        htmlMarker: 0,
                      },
                    ]
                  : []),
                {
                  timestamp: 1710356081200,
                  actionType: ActionType.STUDENT_RESPONSE_ACTION,
                  targetXref: 'PRBKGEX',
                  path: '/',
                  assignmentXref: `GeneratedAssignment-${i}`,
                  userXref: user.xref,
                  tutorUid: 'no idea, a user uuid?',
                  tutorUidSequence: 3,
                  problemId: 1,
                  isFirstResponse: false,
                  partData: {
                    '1': {
                      response: ['3'],
                      correct: true,
                      responseCorrectness: [],
                      score: {
                        continuousScore: 0.0,
                        discreteScore: 0,
                      },
                    },
                    '2': {
                      response: ['5'],
                      correct: true,
                      responseCorrectness: [],
                      score: {
                        continuousScore: 0.0,
                        discreteScore: 0,
                      },
                    },
                  },
                  problemScore: {
                    continuousScore: 0.0,
                    discreteScore: 0,
                  },
                },
                {
                  timestamp: 1710356081201,
                  actionType: ActionType.PROBLEM_FINISHED_ACTION,
                  targetXref: 'PRBKGEX',
                  path: '/PSBD95N/PRBKGEX',
                  assignmentXref: `GeneratedAssignment-${i}`,
                  userXref: user.xref,
                  tutorUid: 'no idea, a user uuid?',
                  tutorUidSequence: 4,
                  problemId: 1,
                  problemScore: {
                    continuousScore: 0.0,
                    discreteScore: 0,
                  },
                  partScore: {
                    '1': {
                      continuousScore: 0.0,
                      discreteScore: 0,
                    },
                  },
                },
              ],
            };
          }),
        };
      }),
    } as object);
    server.create('assignmentReport', {
      contentInfo: {
        xref: 'e6121fbd-c9b5-4c2f-8ba6-83bf80de87da',
        itemType: 'ASSIGNMENT',
        active: true,
      },
      summaryStatsAll: {
        avgScore: 0.9528571428571428,
        avgTime: 37593,
        studentStats: [
          {
            studentXref: 'C9rUPIqxW7q2x9oLpSDG',
            score: 0.9528571428571428,
            timeSpent: 37131,
          },
          {
            studentXref: 'wNmUOMlw2AIqZ8bKUF9',
            score: 0.9528571428571428,
            timeSpent: 38055,
          },
        ],
      },
      prAllStats: [
        {
          prCeri: 'PRABTYXK',
          avgScore: 1,
          avgTime: 2617,
        },
        {
          prCeri: 'PRA2009083',
          avgScore: 0.835,
          avgTime: 1834,
        },
        {
          prCeri: 'PRA2009080',
          avgScore: 1,
          avgTime: 2320,
        },
        {
          prCeri: 'PRA2009081',
          avgScore: 0.665,
          avgTime: 3596,
        },
        {
          prCeri: 'PRABTSUD',
          avgScore: 0.835,
          avgTime: 3829,
        },
        {
          prCeri: 'PRA2070868',
          avgScore: 1,
          avgTime: 2974,
        },
        {
          prCeri: 'PRA2070867',
          avgScore: 1,
          avgTime: 3087,
        },
        {
          prCeri: 'PRA2070866',
          avgScore: 1,
          avgTime: 2899,
        },
        {
          prCeri: 'PRABTSUC',
          avgScore: 0.665,
          avgTime: 2314,
        },
        {
          prCeri: 'PRABTSUB',
          avgScore: 0.835,
          avgTime: 1596,
        },
        {
          prCeri: 'PRABTSUA',
          avgScore: 1,
          avgTime: 3723,
        },
        {
          prCeri: 'PRABTYXS',
          avgScore: 0,
          avgTime: 6298,
        },
        {
          prCeri: 'PRABTYXN',
          avgScore: 0.67,
          avgTime: 5167,
        },
      ],
      studentLogs: [
        {
          studentXref: 'C9rUPIqxW7q2x9oLpSDG',
          asStartTime: 1695777203244,
          asEndTime: 1695777240375,
          psActions: [
            {
              actionType: 'ASSIGNMENT_STARTED_ACTION',
              timestamp: 1695777203244,
              pathInfo: '/',
            },
            {
              actionType: 'ASSIGNMENT_FINISHED_ACTION',
              timestamp: 1695777240375,
              pathInfo: '/',
            },
          ],
          problemLogAndActions: [
            {
              prLog: {
                prCeri: 'PRA2009083',
                id: 177669305,
                sawAnswer: false,
                startTime: 1695777230651,
                endTime: 1695777231944,
                pathInfo: '/PSABXACC/PSBBSXGS/PRA2009083',
                attemptCount: 1,
                continuousScore: 1,
                discreteScore: 1,
                firstResponseTime: 1292,
                hintCount: 0,
                firstActionType: 'ANSWER',
                partLogData: {
                  1: {
                    response: ['4'],
                    // teacherComment: '',
                    hintCount: 0,
                    attemptCount: 1,
                    continuousScore: 1,
                    discreteScore: 1,
                  },
                },
              },
              actions: [
                {
                  actionType: 'PROBLEM_STARTED_ACTION',
                  timestamp: 1695777230651,
                  pathInfo: '/PSABXACC/PSBBSXGS/PRA2009083',
                },
                {
                  actionType: 'STUDENT_RESPONSE_ACTION',
                  timestamp: 1695777231943,
                  pathInfo: '/PSABXACC/PSBBSXGS/PRA2009083',
                  partData: {
                    1: {
                      response: ['4'],
                      correct: true,
                    },
                  },
                },
                {
                  actionType: 'PROBLEM_FINISHED_ACTION',
                  timestamp: 1695777231944,
                  pathInfo: '/PSABXACC/PSBBSXGS/PRA2009083',
                },
              ],
            },
            {
              prLog: {
                prCeri: 'PRA2009081',
                id: 177669296,
                sawAnswer: false,
                startTime: 1695777224402,
                endTime: 1695777230631,
                pathInfo: '/PSABXACC/PSBBSXGS/PRA2009081',
                attemptCount: 3,
                continuousScore: 0.33,
                discreteScore: 0,
                firstResponseTime: 3623,
                hintCount: 0,
                firstActionType: 'ANSWER',
                partLogData: {
                  1: {
                    response: ['2'],
                    // teacherComment: '',
                    hintCount: 0,
                    attemptCount: 3,
                    continuousScore: 0.33,
                    discreteScore: 0,
                  },
                },
              },
              actions: [
                {
                  actionType: 'PROBLEM_STARTED_ACTION',
                  timestamp: 1695777224402,
                  pathInfo: '/PSABXACC/PSBBSXGS/PRA2009081',
                },
                {
                  actionType: 'STUDENT_RESPONSE_ACTION',
                  timestamp: 1695777228025,
                  pathInfo: '/PSABXACC/PSBBSXGS/PRA2009081',
                  partData: {
                    1: {
                      response: ['4'],
                      correct: false,
                    },
                  },
                },
                {
                  actionType: 'STUDENT_RESPONSE_ACTION',
                  timestamp: 1695777229342,
                  pathInfo: '/PSABXACC/PSBBSXGS/PRA2009081',
                  partData: {
                    1: {
                      response: ['5'],
                      correct: false,
                    },
                  },
                },
                {
                  actionType: 'STUDENT_RESPONSE_ACTION',
                  timestamp: 1695777230630,
                  pathInfo: '/PSABXACC/PSBBSXGS/PRA2009081',
                  partData: {
                    1: {
                      response: ['2'],
                      correct: true,
                    },
                  },
                },
                {
                  actionType: 'PROBLEM_FINISHED_ACTION',
                  timestamp: 1695777230631,
                  pathInfo: '/PSABXACC/PSBBSXGS/PRA2009081',
                },
              ],
            },
            {
              prLog: {
                prCeri: 'PRA2009080',
                id: 177669287,
                sawAnswer: false,
                startTime: 1695777221390,
                endTime: 1695777223590,
                pathInfo: '/PSABXACC/PSBBSXGS/PRA2009080',
                attemptCount: 1,
                continuousScore: 1,
                discreteScore: 1,
                firstResponseTime: 2199,
                hintCount: 0,
                firstActionType: 'ANSWER',
                partLogData: {
                  1: {
                    response: ['9'],
                    // teacherComment: '',
                    hintCount: 0,
                    attemptCount: 1,
                    continuousScore: 1,
                    discreteScore: 1,
                  },
                },
              },
              actions: [
                {
                  actionType: 'PROBLEM_STARTED_ACTION',
                  timestamp: 1695777221390,
                  pathInfo: '/PSABXACC/PSBBSXGS/PRA2009080',
                },
                {
                  actionType: 'STUDENT_RESPONSE_ACTION',
                  timestamp: 1695777223589,
                  pathInfo: '/PSABXACC/PSBBSXGS/PRA2009080',
                  partData: {
                    1: {
                      response: ['9'],
                      correct: true,
                    },
                  },
                },
                {
                  actionType: 'PROBLEM_FINISHED_ACTION',
                  timestamp: 1695777223590,
                  pathInfo: '/PSABXACC/PSBBSXGS/PRA2009080',
                },
              ],
            },
            {
              prLog: {
                prCeri: 'PRABTSUD',
                id: 177669282,
                sawAnswer: false,
                startTime: 1695777215809,
                endTime: 1695777218462,
                pathInfo: '/PSABXACC/PRABTSUD',
                attemptCount: 2,
                continuousScore: 0.67,
                discreteScore: 0,
                firstResponseTime: 1630,
                hintCount: 0,
                firstActionType: 'ANSWER',
                partLogData: {
                  1: {
                    response: ['7'],
                    // teacherComment: '',
                    hintCount: 0,
                    attemptCount: 2,
                    continuousScore: 0.67,
                    discreteScore: 0,
                  },
                },
              },
              actions: [
                {
                  actionType: 'PROBLEM_STARTED_ACTION',
                  timestamp: 1695777215809,
                  pathInfo: '/PSABXACC/PRABTSUD',
                },
                {
                  actionType: 'STUDENT_RESPONSE_ACTION',
                  timestamp: 1695777217439,
                  pathInfo: '/PSABXACC/PRABTSUD',
                  partData: {
                    1: {
                      response: ['3'],
                      correct: false,
                    },
                  },
                },
                {
                  actionType: 'STUDENT_RESPONSE_ACTION',
                  timestamp: 1695777218461,
                  pathInfo: '/PSABXACC/PRABTSUD',
                  partData: {
                    1: {
                      response: ['7'],
                      correct: true,
                    },
                  },
                },
                {
                  actionType: 'PROBLEM_FINISHED_ACTION',
                  timestamp: 1695777218462,
                  pathInfo: '/PSABXACC/PRABTSUD',
                },
              ],
            },
            {
              prLog: {
                prCeri: 'PRABTSUC',
                id: 177669281,
                sawAnswer: false,
                startTime: 1695777214317,
                endTime: 1695777215539,
                pathInfo: '/PSABXACC/PRABTSUC',
                attemptCount: 1,
                continuousScore: 1,
                discreteScore: 1,
                firstResponseTime: 1221,
                hintCount: 0,
                firstActionType: 'ANSWER',
                partLogData: {
                  1: {
                    response: ['7'],
                    // teacherComment: '',
                    hintCount: 0,
                    attemptCount: 1,
                    continuousScore: 1,
                    discreteScore: 1,
                  },
                },
              },
              actions: [
                {
                  actionType: 'PROBLEM_STARTED_ACTION',
                  timestamp: 1695777214317,
                  pathInfo: '/PSABXACC/PRABTSUC',
                },
                {
                  actionType: 'STUDENT_RESPONSE_ACTION',
                  timestamp: 1695777215538,
                  pathInfo: '/PSABXACC/PRABTSUC',
                  partData: {
                    1: {
                      response: ['7'],
                      correct: true,
                    },
                  },
                },
                {
                  actionType: 'PROBLEM_FINISHED_ACTION',
                  timestamp: 1695777215539,
                  pathInfo: '/PSABXACC/PRABTSUC',
                },
              ],
            },
            {
              prLog: {
                prCeri: 'PRABTSUB',
                id: 177669259,
                sawAnswer: false,
                startTime: 1695777205713,
                endTime: 1695777207739,
                pathInfo: '/PSABXACC/PRABTSUB',
                attemptCount: 2,
                continuousScore: 0.67,
                discreteScore: 0,
                firstResponseTime: 1150,
                hintCount: 0,
                firstActionType: 'ANSWER',
                partLogData: {
                  1: {
                    response: ['6'],
                    // teacherComment: '',
                    hintCount: 0,
                    attemptCount: 2,
                    continuousScore: 0.67,
                    discreteScore: 0,
                  },
                },
              },
              actions: [
                {
                  actionType: 'PROBLEM_STARTED_ACTION',
                  timestamp: 1695777205713,
                  pathInfo: '/PSABXACC/PRABTSUB',
                },
                {
                  actionType: 'STUDENT_RESPONSE_ACTION',
                  timestamp: 1695777206863,
                  pathInfo: '/PSABXACC/PRABTSUB',
                  partData: {
                    1: {
                      response: ['4'],
                      correct: false,
                    },
                  },
                },
                {
                  actionType: 'STUDENT_RESPONSE_ACTION',
                  timestamp: 1695777207738,
                  pathInfo: '/PSABXACC/PRABTSUB',
                  partData: {
                    1: {
                      response: ['6'],
                      correct: true,
                    },
                  },
                },
                {
                  actionType: 'PROBLEM_FINISHED_ACTION',
                  timestamp: 1695777207739,
                  pathInfo: '/PSABXACC/PRABTSUB',
                },
              ],
            },
            {
              prLog: {
                prCeri: 'PRABTSUA',
                id: 177669258,
                sawAnswer: false,
                startTime: 1695777203265,
                endTime: 1695777205568,
                pathInfo: '/PSABXACC/PRABTSUA',
                attemptCount: 1,
                continuousScore: 1,
                discreteScore: 1,
                firstResponseTime: 2302,
                hintCount: 0,
                firstActionType: 'ANSWER',
                partLogData: {
                  1: {
                    response: ['10'],
                    // teacherComment: '',
                    hintCount: 0,
                    attemptCount: 1,
                    continuousScore: 1,
                    discreteScore: 1,
                  },
                },
              },
              actions: [
                {
                  actionType: 'PROBLEM_STARTED_ACTION',
                  timestamp: 1695777203265,
                  pathInfo: '/PSABXACC/PRABTSUA',
                },
                {
                  actionType: 'STUDENT_RESPONSE_ACTION',
                  timestamp: 1695777205567,
                  pathInfo: '/PSABXACC/PRABTSUA',
                  partData: {
                    1: {
                      response: ['10'],
                      correct: true,
                    },
                  },
                },
                {
                  actionType: 'PROBLEM_FINISHED_ACTION',
                  timestamp: 1695777205568,
                  pathInfo: '/PSABXACC/PRABTSUA',
                },
              ],
            },
            {
              prLog: {
                prCeri: 'PRA2070868',
                id: 177669310,
                sawAnswer: false,
                startTime: 1695777237801,
                endTime: 1695777240372,
                pathInfo: '/PSABXACC/PSBBTYXZ/PRA2070868',
                attemptCount: 1,
                continuousScore: 1,
                discreteScore: 1,
                firstResponseTime: 2570,
                hintCount: 0,
                redoParentXref: 'PSBBSXGS',
                firstActionType: 'ANSWER',
                partLogData: {
                  1: {
                    response: ['1'],
                    // teacherComment: '',
                    hintCount: 0,
                    attemptCount: 1,
                    continuousScore: 1,
                    discreteScore: 1,
                  },
                },
              },
              actions: [
                {
                  actionType: 'PROBLEM_STARTED_ACTION',
                  timestamp: 1695777237801,
                  pathInfo: '/PSABXACC/PSBBTYXZ/PRA2070868',
                },
                {
                  actionType: 'STUDENT_RESPONSE_ACTION',
                  timestamp: 1695777240371,
                  pathInfo: '/PSABXACC/PSBBTYXZ/PRA2070868',
                  partData: {
                    1: {
                      response: ['1'],
                      correct: true,
                    },
                  },
                },
                {
                  actionType: 'PROBLEM_FINISHED_ACTION',
                  timestamp: 1695777240372,
                  pathInfo: '/PSABXACC/PSBBTYXZ/PRA2070868',
                },
              ],
            },
            {
              prLog: {
                prCeri: 'PRA2070867',
                id: 177669309,
                sawAnswer: false,
                startTime: 1695777234959,
                endTime: 1695777237425,
                pathInfo: '/PSABXACC/PSBBTYXZ/PRA2070867',
                attemptCount: 1,
                continuousScore: 1,
                discreteScore: 1,
                firstResponseTime: 2465,
                hintCount: 0,
                redoParentXref: 'PSBBSXGS',
                firstActionType: 'ANSWER',
                partLogData: {
                  1: {
                    response: ['1'],
                    // teacherComment: '',
                    hintCount: 0,
                    attemptCount: 1,
                    continuousScore: 1,
                    discreteScore: 1,
                  },
                },
              },
              actions: [
                {
                  actionType: 'PROBLEM_STARTED_ACTION',
                  timestamp: 1695777234959,
                  pathInfo: '/PSABXACC/PSBBTYXZ/PRA2070867',
                },
                {
                  actionType: 'STUDENT_RESPONSE_ACTION',
                  timestamp: 1695777237424,
                  pathInfo: '/PSABXACC/PSBBTYXZ/PRA2070867',
                  partData: {
                    1: {
                      response: ['1'],
                      correct: true,
                    },
                  },
                },
                {
                  actionType: 'PROBLEM_FINISHED_ACTION',
                  timestamp: 1695777237425,
                  pathInfo: '/PSABXACC/PSBBTYXZ/PRA2070867',
                },
              ],
            },
            {
              prLog: {
                prCeri: 'PRA2070866',
                id: 177669306,
                sawAnswer: false,
                startTime: 1695777232081,
                endTime: 1695777234930,
                pathInfo: '/PSABXACC/PSBBTYXZ/PRA2070866',
                attemptCount: 1,
                continuousScore: 1,
                discreteScore: 1,
                firstResponseTime: 2848,
                hintCount: 0,
                redoParentXref: 'PSBBSXGS',
                firstActionType: 'ANSWER',
                partLogData: {
                  1: {
                    response: ['9'],
                    // teacherComment: '',
                    hintCount: 0,
                    attemptCount: 1,
                    continuousScore: 1,
                    discreteScore: 1,
                  },
                },
              },
              actions: [
                {
                  actionType: 'PROBLEM_STARTED_ACTION',
                  timestamp: 1695777232081,
                  pathInfo: '/PSABXACC/PSBBTYXZ/PRA2070866',
                },
                {
                  actionType: 'STUDENT_RESPONSE_ACTION',
                  timestamp: 1695777234929,
                  pathInfo: '/PSABXACC/PSBBTYXZ/PRA2070866',
                  partData: {
                    1: {
                      response: ['9'],
                      correct: true,
                    },
                  },
                },
                {
                  actionType: 'PROBLEM_FINISHED_ACTION',
                  timestamp: 1695777234930,
                  pathInfo: '/PSABXACC/PSBBTYXZ/PRA2070866',
                },
              ],
            },
            {
              prLog: {
                prCeri: 'PRABTYXK',
                id: 177669286,
                sawAnswer: false,
                startTime: 1695777218630,
                endTime: 1695777221247,
                pathInfo: '/PSABXACC/PRABTYXK',
                attemptCount: 1,
                continuousScore: 1,
                discreteScore: 1,
                firstResponseTime: 2616,
                hintCount: 0,
                redoParentXref: 'PRABTSUD',
                firstActionType: 'ANSWER',
                partLogData: {
                  1: {
                    response: ['6'],
                    // teacherComment: '',
                    hintCount: 0,
                    attemptCount: 1,
                    continuousScore: 1,
                    discreteScore: 1,
                  },
                },
              },
              actions: [
                {
                  actionType: 'PROBLEM_STARTED_ACTION',
                  timestamp: 1695777218630,
                  pathInfo: '/PSABXACC/PRABTYXK',
                },
                {
                  actionType: 'STUDENT_RESPONSE_ACTION',
                  timestamp: 1695777221246,
                  pathInfo: '/PSABXACC/PRABTYXK',
                  partData: {
                    1: {
                      response: ['6'],
                      correct: true,
                    },
                  },
                },
                {
                  actionType: 'PROBLEM_FINISHED_ACTION',
                  timestamp: 1695777221247,
                  pathInfo: '/PSABXACC/PRABTYXK',
                },
              ],
            },
            {
              prLog: {
                prCeri: 'PRABTYXS',
                id: 177669260,
                sawAnswer: false,
                startTime: 1695777207884,
                endTime: 1695777214182,
                pathInfo: '/PSABXACC/PRABTYXS',
                attemptCount: 4,
                continuousScore: 0,
                discreteScore: 0,
                firstResponseTime: 3325,
                hintCount: 0,
                redoParentXref: 'PRABTSUB',
                firstActionType: 'ANSWER',
                partLogData: {
                  1: {
                    response: ['7'],
                    // teacherComment: '',
                    hintCount: 0,
                    attemptCount: 4,
                    continuousScore: 0,
                    discreteScore: 0,
                  },
                },
              },
              actions: [
                {
                  actionType: 'PROBLEM_STARTED_ACTION',
                  timestamp: 1695777207884,
                  pathInfo: '/PSABXACC/PRABTYXS',
                },
                {
                  actionType: 'STUDENT_RESPONSE_ACTION',
                  timestamp: 1695777211209,
                  pathInfo: '/PSABXACC/PRABTYXS',
                  partData: {
                    1: {
                      response: ['9'],
                      correct: false,
                    },
                  },
                },
                {
                  actionType: 'STUDENT_RESPONSE_ACTION',
                  timestamp: 1695777212068,
                  pathInfo: '/PSABXACC/PRABTYXS',
                  partData: {
                    1: {
                      response: ['1'],
                      correct: false,
                    },
                  },
                },
                {
                  actionType: 'STUDENT_RESPONSE_ACTION',
                  timestamp: 1695777212637,
                  pathInfo: '/PSABXACC/PRABTYXS',
                  partData: {
                    1: {
                      response: ['2'],
                      correct: false,
                    },
                  },
                },
                {
                  actionType: 'STUDENT_RESPONSE_ACTION',
                  timestamp: 1695777214181,
                  pathInfo: '/PSABXACC/PRABTYXS',
                  partData: {
                    1: {
                      response: ['7'],
                      correct: true,
                    },
                  },
                },
                {
                  actionType: 'PROBLEM_FINISHED_ACTION',
                  timestamp: 1695777214182,
                  pathInfo: '/PSABXACC/PRABTYXS',
                },
              ],
            },
          ],
        },
        {
          studentXref: 'wNmUOMlw2AIqZ8bKUF9',
          asStartTime: 1695777111152,
          asEndTime: 1695777149207,
          psActions: [
            {
              actionType: 'ASSIGNMENT_STARTED_ACTION',
              timestamp: 1695777111152,
              pathInfo: '/',
            },
            {
              actionType: 'ASSIGNMENT_FINISHED_ACTION',
              timestamp: 1695777149207,
              pathInfo: '/',
            },
          ],
          problemLogAndActions: [
            {
              prLog: {
                prCeri: 'PRA2009083',
                id: 177669204,
                sawAnswer: false,
                startTime: 1695777136338,
                endTime: 1695777138714,
                pathInfo: '/PSABXACC/PSBBSXGS/PRA2009083',
                attemptCount: 2,
                continuousScore: 0.67,
                discreteScore: 0,
                firstResponseTime: 1329,
                hintCount: 0,
                firstActionType: 'ANSWER',
                partLogData: {
                  1: {
                    response: ['4'],
                    // teacherComment: '',
                    hintCount: 0,
                    attemptCount: 2,
                    continuousScore: 0.67,
                    discreteScore: 0,
                  },
                },
              },
              actions: [
                {
                  actionType: 'PROBLEM_STARTED_ACTION',
                  timestamp: 1695777136338,
                  pathInfo: '/PSABXACC/PSBBSXGS/PRA2009083',
                },
                {
                  actionType: 'STUDENT_RESPONSE_ACTION',
                  timestamp: 1695777137667,
                  pathInfo: '/PSABXACC/PSBBSXGS/PRA2009083',
                  partData: {
                    1: {
                      response: ['3'],
                      correct: false,
                    },
                  },
                },
                {
                  actionType: 'STUDENT_RESPONSE_ACTION',
                  timestamp: 1695777138713,
                  pathInfo: '/PSABXACC/PSBBSXGS/PRA2009083',
                  partData: {
                    1: {
                      response: ['4'],
                      correct: true,
                    },
                  },
                },
                {
                  actionType: 'PROBLEM_FINISHED_ACTION',
                  timestamp: 1695777138714,
                  pathInfo: '/PSABXACC/PSBBSXGS/PRA2009083',
                },
              ],
            },
            {
              prLog: {
                prCeri: 'PRA2009081',
                id: 177669199,
                sawAnswer: false,
                startTime: 1695777135231,
                endTime: 1695777136195,
                pathInfo: '/PSABXACC/PSBBSXGS/PRA2009081',
                attemptCount: 1,
                continuousScore: 1,
                discreteScore: 1,
                firstResponseTime: 963,
                hintCount: 0,
                firstActionType: 'ANSWER',
                partLogData: {
                  1: {
                    response: ['2'],
                    // teacherComment: '',
                    hintCount: 0,
                    attemptCount: 1,
                    continuousScore: 1,
                    discreteScore: 1,
                  },
                },
              },
              actions: [
                {
                  actionType: 'PROBLEM_STARTED_ACTION',
                  timestamp: 1695777135231,
                  pathInfo: '/PSABXACC/PSBBSXGS/PRA2009081',
                },
                {
                  actionType: 'STUDENT_RESPONSE_ACTION',
                  timestamp: 1695777136194,
                  pathInfo: '/PSABXACC/PSBBSXGS/PRA2009081',
                  partData: {
                    1: {
                      response: ['2'],
                      correct: true,
                    },
                  },
                },
                {
                  actionType: 'PROBLEM_FINISHED_ACTION',
                  timestamp: 1695777136195,
                  pathInfo: '/PSABXACC/PSBBSXGS/PRA2009081',
                },
              ],
            },
            {
              prLog: {
                prCeri: 'PRA2009080',
                id: 177669198,
                sawAnswer: false,
                startTime: 1695777132648,
                endTime: 1695777135088,
                pathInfo: '/PSABXACC/PSBBSXGS/PRA2009080',
                attemptCount: 1,
                continuousScore: 1,
                discreteScore: 1,
                firstResponseTime: 2439,
                hintCount: 0,
                firstActionType: 'ANSWER',
                partLogData: {
                  1: {
                    response: ['9'],
                    // teacherComment: '',
                    hintCount: 0,
                    attemptCount: 1,
                    continuousScore: 1,
                    discreteScore: 1,
                  },
                },
              },
              actions: [
                {
                  actionType: 'PROBLEM_STARTED_ACTION',
                  timestamp: 1695777132648,
                  pathInfo: '/PSABXACC/PSBBSXGS/PRA2009080',
                },
                {
                  actionType: 'STUDENT_RESPONSE_ACTION',
                  timestamp: 1695777135087,
                  pathInfo: '/PSABXACC/PSBBSXGS/PRA2009080',
                  partData: {
                    1: {
                      response: ['9'],
                      correct: true,
                    },
                  },
                },
                {
                  actionType: 'PROBLEM_FINISHED_ACTION',
                  timestamp: 1695777135088,
                  pathInfo: '/PSABXACC/PSBBSXGS/PRA2009080',
                },
              ],
            },
            {
              prLog: {
                prCeri: 'PRABTSUD',
                id: 177669190,
                sawAnswer: false,
                startTime: 1695777126805,
                endTime: 1695777131810,
                pathInfo: '/PSABXACC/PRABTSUD',
                attemptCount: 1,
                continuousScore: 1,
                discreteScore: 1,
                firstResponseTime: 5004,
                hintCount: 0,
                firstActionType: 'ANSWER',
                partLogData: {
                  1: {
                    response: ['7'],
                    // teacherComment: '',
                    hintCount: 0,
                    attemptCount: 1,
                    continuousScore: 1,
                    discreteScore: 1,
                  },
                },
              },
              actions: [
                {
                  actionType: 'PROBLEM_STARTED_ACTION',
                  timestamp: 1695777126805,
                  pathInfo: '/PSABXACC/PRABTSUD',
                },
                {
                  actionType: 'STUDENT_RESPONSE_ACTION',
                  timestamp: 1695777131809,
                  pathInfo: '/PSABXACC/PRABTSUD',
                  partData: {
                    1: {
                      response: ['7'],
                      correct: true,
                    },
                  },
                },
                {
                  actionType: 'PROBLEM_FINISHED_ACTION',
                  timestamp: 1695777131810,
                  pathInfo: '/PSABXACC/PRABTSUD',
                },
              ],
            },
            {
              prLog: {
                prCeri: 'PRABTSUC',
                id: 177669181,
                sawAnswer: false,
                startTime: 1695777117898,
                endTime: 1695777121304,
                pathInfo: '/PSABXACC/PRABTSUC',
                attemptCount: 3,
                continuousScore: 0.33,
                discreteScore: 0,
                firstResponseTime: 1112,
                hintCount: 0,
                firstActionType: 'ANSWER',
                partLogData: {
                  1: {
                    response: ['7'],
                    // teacherComment: '',
                    hintCount: 0,
                    attemptCount: 3,
                    continuousScore: 0.33,
                    discreteScore: 0,
                  },
                },
              },
              actions: [
                {
                  actionType: 'PROBLEM_STARTED_ACTION',
                  timestamp: 1695777117898,
                  pathInfo: '/PSABXACC/PRABTSUC',
                },
                {
                  actionType: 'STUDENT_RESPONSE_ACTION',
                  timestamp: 1695777119010,
                  pathInfo: '/PSABXACC/PRABTSUC',
                  partData: {
                    1: {
                      response: ['3'],
                      correct: false,
                    },
                  },
                },
                {
                  actionType: 'STUDENT_RESPONSE_ACTION',
                  timestamp: 1695777120096,
                  pathInfo: '/PSABXACC/PRABTSUC',
                  partData: {
                    1: {
                      response: ['6'],
                      correct: false,
                    },
                  },
                },
                {
                  actionType: 'STUDENT_RESPONSE_ACTION',
                  timestamp: 1695777121303,
                  pathInfo: '/PSABXACC/PRABTSUC',
                  partData: {
                    1: {
                      response: ['7'],
                      correct: true,
                    },
                  },
                },
                {
                  actionType: 'PROBLEM_FINISHED_ACTION',
                  timestamp: 1695777121304,
                  pathInfo: '/PSABXACC/PRABTSUC',
                },
              ],
            },
            {
              prLog: {
                prCeri: 'PRABTSUB',
                id: 177669180,
                sawAnswer: false,
                startTime: 1695777116588,
                endTime: 1695777117755,
                pathInfo: '/PSABXACC/PRABTSUB',
                attemptCount: 1,
                continuousScore: 1,
                discreteScore: 1,
                firstResponseTime: 1166,
                hintCount: 0,
                firstActionType: 'ANSWER',
                partLogData: {
                  1: {
                    response: ['6'],
                    // teacherComment: '',
                    hintCount: 0,
                    attemptCount: 1,
                    continuousScore: 1,
                    discreteScore: 1,
                  },
                },
              },
              actions: [
                {
                  actionType: 'PROBLEM_STARTED_ACTION',
                  timestamp: 1695777116588,
                  pathInfo: '/PSABXACC/PRABTSUB',
                },
                {
                  actionType: 'STUDENT_RESPONSE_ACTION',
                  timestamp: 1695777117754,
                  pathInfo: '/PSABXACC/PRABTSUB',
                  partData: {
                    1: {
                      response: ['6'],
                      correct: true,
                    },
                  },
                },
                {
                  actionType: 'PROBLEM_FINISHED_ACTION',
                  timestamp: 1695777117755,
                  pathInfo: '/PSABXACC/PRABTSUB',
                },
              ],
            },
            {
              prLog: {
                prCeri: 'PRABTSUA',
                id: 177669175,
                sawAnswer: false,
                startTime: 1695777111170,
                endTime: 1695777116313,
                pathInfo: '/PSABXACC/PRABTSUA',
                attemptCount: 1,
                continuousScore: 1,
                discreteScore: 1,
                firstResponseTime: 5142,
                hintCount: 0,
                firstActionType: 'ANSWER',
                partLogData: {
                  1: {
                    response: ['10'],
                    // teacherComment: '',
                    hintCount: 0,
                    attemptCount: 1,
                    continuousScore: 1,
                    discreteScore: 1,
                  },
                },
              },
              actions: [
                {
                  actionType: 'PROBLEM_STARTED_ACTION',
                  timestamp: 1695777111170,
                  pathInfo: '/PSABXACC/PRABTSUA',
                },
                {
                  actionType: 'STUDENT_RESPONSE_ACTION',
                  timestamp: 1695777116312,
                  pathInfo: '/PSABXACC/PRABTSUA',
                  partData: {
                    1: {
                      response: ['10'],
                      correct: true,
                    },
                  },
                },
                {
                  actionType: 'PROBLEM_FINISHED_ACTION',
                  timestamp: 1695777116313,
                  pathInfo: '/PSABXACC/PRABTSUA',
                },
              ],
            },
            {
              prLog: {
                prCeri: 'PRA2070868',
                id: 177669208,
                sawAnswer: false,
                startTime: 1695777145826,
                endTime: 1695777149204,
                pathInfo: '/PSABXACC/PSBBTYXZ/PRA2070868',
                attemptCount: 1,
                continuousScore: 1,
                discreteScore: 1,
                firstResponseTime: 3377,
                hintCount: 0,
                redoParentXref: 'PSBBSXGS',
                firstActionType: 'ANSWER',
                partLogData: {
                  1: {
                    response: ['1'],
                    // teacherComment: '',
                    hintCount: 0,
                    attemptCount: 1,
                    continuousScore: 1,
                    discreteScore: 1,
                  },
                },
              },
              actions: [
                {
                  actionType: 'PROBLEM_STARTED_ACTION',
                  timestamp: 1695777145826,
                  pathInfo: '/PSABXACC/PSBBTYXZ/PRA2070868',
                },
                {
                  actionType: 'STUDENT_RESPONSE_ACTION',
                  timestamp: 1695777149203,
                  pathInfo: '/PSABXACC/PSBBTYXZ/PRA2070868',
                  partData: {
                    1: {
                      response: ['1'],
                      correct: true,
                    },
                  },
                },
                {
                  actionType: 'PROBLEM_FINISHED_ACTION',
                  timestamp: 1695777149204,
                  pathInfo: '/PSABXACC/PSBBTYXZ/PRA2070868',
                },
              ],
            },
            {
              prLog: {
                prCeri: 'PRA2070867',
                id: 177669207,
                sawAnswer: false,
                startTime: 1695777141975,
                endTime: 1695777145684,
                pathInfo: '/PSABXACC/PSBBTYXZ/PRA2070867',
                attemptCount: 1,
                continuousScore: 1,
                discreteScore: 1,
                firstResponseTime: 3708,
                hintCount: 0,
                redoParentXref: 'PSBBSXGS',
                firstActionType: 'ANSWER',
                partLogData: {
                  1: {
                    response: ['1'],
                    // teacherComment: '',
                    hintCount: 0,
                    attemptCount: 1,
                    continuousScore: 1,
                    discreteScore: 1,
                  },
                },
              },
              actions: [
                {
                  actionType: 'PROBLEM_STARTED_ACTION',
                  timestamp: 1695777141975,
                  pathInfo: '/PSABXACC/PSBBTYXZ/PRA2070867',
                },
                {
                  actionType: 'STUDENT_RESPONSE_ACTION',
                  timestamp: 1695777145683,
                  pathInfo: '/PSABXACC/PSBBTYXZ/PRA2070867',
                  partData: {
                    1: {
                      response: ['1'],
                      correct: true,
                    },
                  },
                },
                {
                  actionType: 'PROBLEM_FINISHED_ACTION',
                  timestamp: 1695777145684,
                  pathInfo: '/PSABXACC/PSBBTYXZ/PRA2070867',
                },
              ],
            },
            {
              prLog: {
                prCeri: 'PRA2070866',
                id: 177669205,
                sawAnswer: false,
                startTime: 1695777138888,
                endTime: 1695777141837,
                pathInfo: '/PSABXACC/PSBBTYXZ/PRA2070866',
                attemptCount: 1,
                continuousScore: 1,
                discreteScore: 1,
                firstResponseTime: 2948,
                hintCount: 0,
                redoParentXref: 'PSBBSXGS',
                firstActionType: 'ANSWER',
                partLogData: {
                  1: {
                    response: ['9'],
                    // teacherComment: '',
                    hintCount: 0,
                    attemptCount: 1,
                    continuousScore: 1,
                    discreteScore: 1,
                  },
                },
              },
              actions: [
                {
                  actionType: 'PROBLEM_STARTED_ACTION',
                  timestamp: 1695777138888,
                  pathInfo: '/PSABXACC/PSBBTYXZ/PRA2070866',
                },
                {
                  actionType: 'STUDENT_RESPONSE_ACTION',
                  timestamp: 1695777141836,
                  pathInfo: '/PSABXACC/PSBBTYXZ/PRA2070866',
                  partData: {
                    1: {
                      response: ['9'],
                      correct: true,
                    },
                  },
                },
                {
                  actionType: 'PROBLEM_FINISHED_ACTION',
                  timestamp: 1695777141837,
                  pathInfo: '/PSABXACC/PSBBTYXZ/PRA2070866',
                },
              ],
            },
            {
              prLog: {
                prCeri: 'PRABTYXN',
                id: 177669186,
                sawAnswer: false,
                startTime: 1695777121489,
                endTime: 1695777126656,
                pathInfo: '/PSABXACC/PRABTYXN',
                attemptCount: 2,
                continuousScore: 0.67,
                discreteScore: 0,
                firstResponseTime: 4119,
                hintCount: 0,
                redoParentXref: 'PRABTSUC',
                firstActionType: 'ANSWER',
                partLogData: {
                  1: {
                    response: ['8'],
                    // teacherComment: '',
                    hintCount: 0,
                    attemptCount: 2,
                    continuousScore: 0.67,
                    discreteScore: 0,
                  },
                },
              },
              actions: [
                {
                  actionType: 'PROBLEM_STARTED_ACTION',
                  timestamp: 1695777121489,
                  pathInfo: '/PSABXACC/PRABTYXN',
                },
                {
                  actionType: 'STUDENT_RESPONSE_ACTION',
                  timestamp: 1695777125608,
                  pathInfo: '/PSABXACC/PRABTYXN',
                  partData: {
                    1: {
                      response: ['6'],
                      correct: false,
                    },
                  },
                },
                {
                  actionType: 'STUDENT_RESPONSE_ACTION',
                  timestamp: 1695777126655,
                  pathInfo: '/PSABXACC/PRABTYXN',
                  partData: {
                    1: {
                      response: ['8'],
                      correct: true,
                    },
                  },
                },
                {
                  actionType: 'PROBLEM_FINISHED_ACTION',
                  timestamp: 1695777126656,
                  pathInfo: '/PSABXACC/PRABTYXN',
                },
              ],
            },
          ],
        },
      ],
    } as object);
    server.create('assignmentReport', {
      contentInfo: {
        xref: 'ESSAY_SCORING_ASSIGNMENT',
        itemType: 'ASSIGNMENT',
        active: true,
      },
      summaryStatsAll: {
        avgScore: 0.5,
        avgTime: 231853,
        studentStats: [
          {
            studentXref: 'C9rUPIqxW7q2x9oLpSDG',
            score: null,
            timeSpent: 77328,
          },
          {
            studentXref: 'wNmUOMlw2AIqZ8bKUF9',
            score: 0.5,
            timeSpent: 386379,
          },
        ],
      },
      prAllStats: [
        {
          prCeri: 'PRA1982638',
          avgTime: 265930,
        },
        {
          prCeri: 'PRA1982639',
          avgTime: 36115,
        },
        {
          prCeri: 'PRABSEHW',
          avgScore: 0,
          avgTime: 26603,
        },
        {
          prCeri: 'PRA1982637',
          avgScore: 1,
          avgTime: 4221,
        },
        {
          prCeri: 'PRA1982635',
          avgTime: 50407,
        },
      ],
      studentLogs: [
        {
          studentXref: 'C9rUPIqxW7q2x9oLpSDG',
          asStartTime: 1674662837071,
          psActions: [
            {
              actionType: 'ASSIGNMENT_STARTED_ACTION',
              timestamp: 1674662837071,
              pathInfo: '/',
            },
          ],
          problemLogAndActions: [
            {
              prLog: {
                prCeri: 'PRA1982635',
                id: 167859284,
                sawAnswer: false,
                startTime: 1674662837082,
                endTime: 1674662914399,
                pathInfo: '/PSABSVSE/PSA1374465/PRA1982635',
                attemptCount: 1,
                firstResponseTime: 77316,
                hintCount: 0,
                firstActionType: 'ANSWER',
                partLogData: {
                  1: {
                    response: [
                      '<p><img src="//resources.assistments.org/fetch/SW/8788aa8d-9cd0-4dc6-9202-d84a5aa5a023.jpeg" width="75" /></p>',
                    ],
                    // teacherComment: '',
                    hintCount: 0,
                    attemptCount: 1,
                  },
                },
              },
              actions: [
                {
                  actionType: 'PROBLEM_STARTED_ACTION',
                  timestamp: 1674662837082,
                  pathInfo: '/PSABSVSE/PSA1374465/PRA1982635',
                },
                {
                  actionType: 'STUDENT_SUBMISSION_ACTION',
                  timestamp: 1674662914398,
                  pathInfo: '/PSABSVSE/PSA1374465/PRA1982635',
                  response:
                    '<p><img src="//resources.assistments.org/fetch/SW/8788aa8d-9cd0-4dc6-9202-d84a5aa5a023.jpeg" width="75" /></p>',
                },
                {
                  actionType: 'PROBLEM_FINISHED_ACTION',
                  timestamp: 1674662914399,
                  pathInfo: '/PSABSVSE/PSA1374465/PRA1982635',
                },
              ],
            },
          ],
        },
        {
          studentXref: 'wNmUOMlw2AIqZ8bKUF9',
          asStartTime: 1674662080110,
          asEndTime: 1674662684063,
          psActions: [
            {
              actionType: 'ASSIGNMENT_STARTED_ACTION',
              timestamp: 1674662080110,
              pathInfo: '/',
            },
            {
              actionType: 'ASSIGNMENT_RESUMED_ACTION',
              timestamp: 1674662113253,
              pathInfo: '/',
            },
            {
              actionType: 'ASSIGNMENT_RESUMED_ACTION',
              timestamp: 1674662608913,
              pathInfo: '/',
            },
            {
              actionType: 'ASSIGNMENT_FINISHED_ACTION',
              timestamp: 1674662684063,
              pathInfo: '/',
            },
          ],
          problemLogAndActions: [
            {
              prLog: {
                prCeri: 'PRA1982639',
                id: 167858860,
                sawAnswer: false,
                startTime: 1674662647945,
                endTime: 1674662684060,
                pathInfo: '/PSABSVSE/PSA1374467/PRA1982639',
                attemptCount: 1,
                firstResponseTime: 36114,
                hintCount: 0,
                firstActionType: 'ANSWER',
                partLogData: {
                  1: {
                    response: ['IMG'],
                    // teacherComment: '',
                    hintCount: 0,
                    attemptCount: 1,
                  },
                },
              },
              actions: [
                {
                  actionType: 'PROBLEM_STARTED_ACTION',
                  timestamp: 1674662647945,
                  pathInfo: '/PSABSVSE/PSA1374467/PRA1982639',
                },
                {
                  actionType: 'STUDENT_SUBMISSION_ACTION',
                  timestamp: 1674662684059,
                  pathInfo: '/PSABSVSE/PSA1374467/PRA1982639',
                  response: 'IMG',
                },
                {
                  actionType: 'PROBLEM_FINISHED_ACTION',
                  timestamp: 1674662684060,
                  pathInfo: '/PSABSVSE/PSA1374467/PRA1982639',
                },
              ],
            },
            {
              prLog: {
                prCeri: 'PRA1982637',
                id: 167858848,
                sawAnswer: false,
                startTime: 1674662642697,
                endTime: 1674662646918,
                pathInfo: '/PSABSVSE/PSA1374467/PRA1982637',
                attemptCount: 1,
                continuousScore: 1,
                discreteScore: 1,
                firstResponseTime: 4220,
                hintCount: 0,
                firstActionType: 'ANSWER',
                partLogData: {
                  1: {
                    response: ['Prime'],
                    // teacherComment: '',
                    hintCount: 0,
                    attemptCount: 1,
                    continuousScore: 1,
                    discreteScore: 1,
                  },
                },
              },
              actions: [
                {
                  actionType: 'PROBLEM_STARTED_ACTION',
                  timestamp: 1674662642697,
                  pathInfo: '/PSABSVSE/PSA1374467/PRA1982637',
                },
                {
                  actionType: 'STUDENT_RESPONSE_ACTION',
                  timestamp: 1674662646917,
                  pathInfo: '/PSABSVSE/PSA1374467/PRA1982637',
                  partData: {
                    1: {
                      response: 'Prime',
                      correct: true,
                    },
                  },
                },
                {
                  actionType: 'PROBLEM_FINISHED_ACTION',
                  timestamp: 1674662646918,
                  pathInfo: '/PSABSVSE/PSA1374467/PRA1982637',
                },
              ],
            },
            {
              prLog: {
                prCeri: 'PRABSEHW',
                id: 167858788,
                sawAnswer: true,
                startTime: 1674662608948,
                endTime: 1674662640479,
                pathInfo: '/PSABSVSE/PRABSEHW',
                attemptCount: 1,
                continuousScore: 0,
                discreteScore: 0,
                firstResponseTime: 14414,
                hintCount: 2,
                firstActionType: 'HINT',
                partLogData: {
                  1: {
                    response: [
                      'A. 5 is a factor of 35, D. 35 is a multiple of 5',
                    ],
                    // teacherComment: '',
                    hintCount: 2,
                    attemptCount: 1,
                    continuousScore: 0,
                    discreteScore: 0,
                  },
                },
              },
              actions: [
                {
                  actionType: 'PROBLEM_STARTED_ACTION',
                  timestamp: 1674662608948,
                  pathInfo: '/PSABSVSE/PRABSEHW',
                },
                {
                  actionType: 'PROBLEM_RESUMED_ACTION',
                  timestamp: 1674662613876,
                  pathInfo: '/PSABSVSE/PRABSEHW',
                },
                {
                  actionType: 'HINT_REQUESTED_ACTION',
                  timestamp: 1674662623362,
                  pathInfo: '/PSABSVSE/PRABSEHW',
                  hintId: 1772323,
                },
                {
                  actionType: 'LIVE_TUTORING_REQUESTED_ACTION',
                  timestamp: 1674662625061,
                  pathInfo: '/PSABSVSE/PRABSEHW',
                },
                {
                  actionType: 'HINT_REQUESTED_ACTION',
                  timestamp: 1674662634238,
                  pathInfo: '/PSABSVSE/PRABSEHW',
                  hintId: 1772324,
                },
                {
                  actionType: 'ANSWER_REQUESTED_ACTION',
                  timestamp: 1674662635514,
                  pathInfo: '/PSABSVSE/PRABSEHW',
                },
                {
                  actionType: 'STUDENT_RESPONSE_ACTION',
                  timestamp: 1674662640478,
                  pathInfo: '/PSABSVSE/PRABSEHW',
                  partData: {
                    1: {
                      response:
                        'A. 5 is a factor of 35, D. 35 is a multiple of 5',
                      correct: true,
                    },
                  },
                },
                {
                  actionType: 'PROBLEM_FINISHED_ACTION',
                  timestamp: 1674662640479,
                  pathInfo: '/PSABSVSE/PRABSEHW',
                },
              ],
            },
            {
              prLog: {
                prCeri: 'PRA1982638',
                id: 167857587,
                sawAnswer: false,
                startTime: 1674662113272,
                endTime: 1674662400962,
                pathInfo: '/PSABSVSE/PSA1374465/PRA1982638',
                attemptCount: 1,
                firstResponseTime: 287688,
                hintCount: 0,
                firstActionType: 'ANSWER',
                partLogData: {
                  1: {
                    response: ['IMG'],
                    // teacherComment: '',
                    hintCount: 0,
                    attemptCount: 1,
                  },
                },
              },
              actions: [
                {
                  actionType: 'PROBLEM_STARTED_ACTION',
                  timestamp: 1674662113272,
                  pathInfo: '/PSABSVSE/PSA1374465/PRA1982638',
                },
                {
                  actionType: 'PROBLEM_RESUMED_ACTION',
                  timestamp: 1674662135032,
                  pathInfo: '/PSABSVSE/PSA1374465/PRA1982638',
                },
                {
                  actionType: 'STUDENT_SUBMISSION_ACTION',
                  timestamp: 1674662400960,
                  pathInfo: '/PSABSVSE/PSA1374465/PRA1982638',
                  response: 'IMG',
                },
                {
                  actionType: 'PROBLEM_FINISHED_ACTION',
                  timestamp: 1674662400962,
                  pathInfo: '/PSABSVSE/PSA1374465/PRA1982638',
                },
              ],
            },
            {
              prLog: {
                prCeri: 'PRA1982635',
                id: 167857489,
                sawAnswer: false,
                startTime: 1674662080131,
                endTime: 1674662103629,
                pathInfo: '/PSABSVSE/PSA1374465/PRA1982635',
                attemptCount: 1,
                firstResponseTime: 23497,
                hintCount: 0,
                firstActionType: 'ANSWER',
                partLogData: {
                  1: {
                    response: ['IMG'],
                    // teacherComment: '',
                    hintCount: 0,
                    attemptCount: 1,
                  },
                },
              },
              actions: [
                {
                  actionType: 'PROBLEM_STARTED_ACTION',
                  timestamp: 1674662080131,
                  pathInfo: '/PSABSVSE/PSA1374465/PRA1982635',
                },
                {
                  actionType: 'STUDENT_SUBMISSION_ACTION',
                  timestamp: 1674662103628,
                  pathInfo: '/PSABSVSE/PSA1374465/PRA1982635',
                  response: 'IMG',
                },
                {
                  actionType: 'PROBLEM_FINISHED_ACTION',
                  timestamp: 1674662103629,
                  pathInfo: '/PSABSVSE/PSA1374465/PRA1982635',
                },
              ],
            },
          ],
        },
      ],
    } as object);
    server.create('assignmentReport', {
      contentInfo: {
        xref: '61623176-3f3c-4423-a20d-ec499042276a',
        itemType: 'ASSIGNMENT',
        active: true,
      },
      summaryStatsAll: {
        avgScore: 1,
        avgCompletion: 0.125,
        avgTime: 19863,
        studentStats: [
          {
            studentXref: 'user57',
            score: 1,
            percentComplete: 0.2,
            timeSpent: 19863,
          },
        ],
      },
      prAllStats: [
        {
          prCeri: 'PRBGNRE',
          problemDbid: 402381,
          avgScore: 1,
          avgTime: 1580,
        },
        {
          prCeri: 'PRBGNPJ',
          problemDbid: 402280,
          avgTime: 4745,
        },
      ],
      studentLogs: [
        {
          studentXref: 'user57',
          asStartTime: 1721916233482,
          asEndTime: 1721917364836,
          lastWorkedOn: 1721917364836,
          psActions: [
            {
              timestamp: 1721916233482,
              actionType: 'ASSIGNMENT_STARTED_ACTION',
              targetXref: '61623176-3f3c-4423-a20d-ec499042276a',
              path: '/',
              assignmentXref: '61623176-3f3c-4423-a20d-ec499042276a',
              userXref: 'user57',
              tutorUid: 'a87c2ce9-795f-4ca6-a754-83da42e447c7',
              tutorUidSequence: 1,
            },
            {
              timestamp: 1721917351051,
              actionType: 'ASSIGNMENT_RESUMED_ACTION',
              targetXref: '61623176-3f3c-4423-a20d-ec499042276a',
              path: '/',
              assignmentXref: '61623176-3f3c-4423-a20d-ec499042276a',
              userXref: 'user57',
              tutorUid: '079b7f30-aa1a-49ed-8f57-05856c94613a',
              tutorUidSequence: 1,
            },
            {
              timestamp: 1721917364836,
              actionType: 'ASSIGNMENT_FINISHED_ACTION',
              targetXref: '61623176-3f3c-4423-a20d-ec499042276a',
              path: '/',
              assignmentXref: '61623176-3f3c-4423-a20d-ec499042276a',
              userXref: 'user57',
              tutorUid: '079b7f30-aa1a-49ed-8f57-05856c94613a',
              tutorUidSequence: 28,
            },
          ],
          problemLogAndActions: [
            {
              prLog: {
                prCeri: 'PRBGNRE',
                id: 190773181,
                problemDbid: 402381,
                endTime: 1721917364833,
                startTime: 1721917363253,
                pathInfo: '/PSABS7KB/PSBCMEK/PRBGNRE',
                attemptCount: 1,
                sawAnswer: false,
                continuousScore: 1,
                discreteScore: 1,
                firstActionType: 'ANSWER',
                firstResponseTime: 1579,
                hintCount: 0,
                partLogData: {
                  '1': {
                    response: ['18'],
                    teacherComment: null,
                    hintCount: null,
                    attemptCount: null,
                    continuousScore: 1,
                    discreteScore: 1,
                  },
                },
              },
              actions: [
                {
                  timestamp: 1721917363253,
                  actionType: 'PROBLEM_STARTED_ACTION',
                  targetXref: 'PRBGNRE',
                  path: '/PSABS7KB/PSBCMEK/PRBGNRE',
                  assignmentXref: '61623176-3f3c-4423-a20d-ec499042276a',
                  userXref: 'user57',
                  tutorUid: '079b7f30-aa1a-49ed-8f57-05856c94613a',
                  tutorUidSequence: 23,
                  problemId: 402381,
                },
                {
                  timestamp: 1721917364832,
                  actionType: 'STUDENT_RESPONSE_ACTION',
                  targetXref: 'PRBGNRE',
                  path: '/PSABS7KB/PSBCMEK/PRBGNRE',
                  assignmentXref: '61623176-3f3c-4423-a20d-ec499042276a',
                  userXref: 'user57',
                  tutorUid: '079b7f30-aa1a-49ed-8f57-05856c94613a',
                  tutorUidSequence: 24,
                  problemId: 402381,
                  isFirstResponse: true,
                  partData: {
                    '1': {
                      response: ['18'],
                      correct: true,
                      responseCorrectness: [],
                      score: {
                        continuousScore: 1,
                        discreteScore: 1,
                      },
                      answerType: 'NUMERIC',
                    },
                  },
                  problemScore: {
                    continuousScore: 1,
                    discreteScore: 1,
                  },
                },
                {
                  timestamp: 1721917364833,
                  actionType: 'PROBLEM_FINISHED_ACTION',
                  targetXref: 'PRBGNRE',
                  path: '/PSABS7KB/PSBCMEK/PRBGNRE',
                  assignmentXref: '61623176-3f3c-4423-a20d-ec499042276a',
                  userXref: 'user57',
                  tutorUid: '079b7f30-aa1a-49ed-8f57-05856c94613a',
                  tutorUidSequence: 25,
                  problemId: 402381,
                  problemScore: {
                    continuousScore: 1,
                    discreteScore: 1,
                  },
                  partScore: {
                    '1': {
                      continuousScore: 1,
                      discreteScore: 1,
                    },
                  },
                },
              ],
            },
            {
              prLog: {
                prCeri: 'PRBGNPJ',
                id: 190773180,
                problemDbid: 402280,
                endTime: 1721917361180,
                startTime: 1721917356435,
                pathInfo: '/PSABS7KB/PSBCMEK/PRBGNPJ',
                attemptCount: 1,
                sawAnswer: false,
                firstActionType: 'ANSWER',
                firstResponseTime: 4744,
                hintCount: 0,
                partLogData: {
                  '1': {
                    response: ['<p>Test</p>'],
                    teacherComment: null,
                    hintCount: null,
                    attemptCount: null,
                    continuousScore: null,
                    discreteScore: null,
                  },
                },
              },
              actions: [
                {
                  timestamp: 1721917356435,
                  actionType: 'PROBLEM_STARTED_ACTION',
                  targetXref: 'PRBGNPJ',
                  path: '/PSABS7KB/PSBCMEK/PRBGNPJ',
                  assignmentXref: '61623176-3f3c-4423-a20d-ec499042276a',
                  userXref: 'user57',
                  tutorUid: '079b7f30-aa1a-49ed-8f57-05856c94613a',
                  tutorUidSequence: 20,
                  problemId: 402280,
                },
                {
                  timestamp: 1721917361179,
                  actionType: 'STUDENT_SUBMISSION_ACTION',
                  targetXref: 'PRBGNPJ',
                  path: '/PSABS7KB/PSBCMEK/PRBGNPJ',
                  assignmentXref: '61623176-3f3c-4423-a20d-ec499042276a',
                  userXref: 'user57',
                  tutorUid: '079b7f30-aa1a-49ed-8f57-05856c94613a',
                  tutorUidSequence: 21,
                  problemId: 402280,
                  isFirstResponse: true,
                  htmlMarker: 1,
                  response: '<p>Test</p>',
                },
                {
                  timestamp: 1721917361180,
                  actionType: 'PROBLEM_FINISHED_ACTION',
                  targetXref: 'PRBGNPJ',
                  path: '/PSABS7KB/PSBCMEK/PRBGNPJ',
                  assignmentXref: '61623176-3f3c-4423-a20d-ec499042276a',
                  userXref: 'user57',
                  tutorUid: '079b7f30-aa1a-49ed-8f57-05856c94613a',
                  tutorUidSequence: 22,
                  problemId: 402280,
                },
              ],
            },
          ],
        },
      ],
    } as object);
    server.create('assignmentReport', {
      contentInfo: {
        xref: '5e7f3084-4f11-414e-8637-d7ff962c4e8e',
        itemType: 'ASSIGNMENT',
        active: true,
      },
      summaryStatsAll: {
        avgScore: 0.8325,
        avgCompletion: 0.125,
        avgTime: 4590750,
        studentStats: [
          {
            studentXref: 'user57',
            score: 0.8325,
            scoreStatus: 'PENDING',
            percentComplete: 1,
            timeSpent: 4590750,
          },
        ],
      },
      prAllStats: [
        {
          prCeri: 'PRABMR6M',
          problemDbid: 339447,
          avgScore: 0.33,
          avgTime: 77733,
        },
        {
          prCeri: 'PRBFPBU',
          problemDbid: 339137,
          avgScore: 1,
          avgTime: 61195,
        },
        {
          prCeri: 'PRABMR6Q',
          problemDbid: 339664,
          avgScore: 1,
          avgTime: 105924,
        },
        {
          prCeri: 'PRABMR6P',
          problemDbid: 339587,
          avgTime: 6798,
        },
        {
          prCeri: 'PRABMR6R',
          problemDbid: 339788,
          avgScore: 1,
          avgTime: 60523,
        },
        {
          prCeri: 'PRBFPDP',
          problemDbid: 339273,
          avgTime: 5606,
        },
      ],
      studentLogs: [
        {
          studentXref: 'user57',
          asStartTime: 1724167899088,
          asEndTime: 1729097893241,
          lastWorkedOn: 1729097893241,
          psActions: [
            {
              timestamp: 1724167899088,
              actionType: 'ASSIGNMENT_STARTED_ACTION',
              targetXref: '5e7f3084-4f11-414e-8637-d7ff962c4e8e',
              path: '/',
              assignmentXref: '5e7f3084-4f11-414e-8637-d7ff962c4e8e',
              userXref: 'user57',
              tutorUid: '7fd85934-beb7-46c2-8117-a0c1c348a3b6',
              tutorUidSequence: 1,
            },
            {
              timestamp: 1729093302511,
              actionType: 'ASSIGNMENT_RESUMED_ACTION',
              targetXref: '5e7f3084-4f11-414e-8637-d7ff962c4e8e',
              path: '/',
              assignmentXref: '5e7f3084-4f11-414e-8637-d7ff962c4e8e',
              userXref: 'user57',
              tutorUid: 'cd1384a9-ef97-4094-b162-bf9958a2a154',
              tutorUidSequence: 1,
            },
            {
              timestamp: 1729097893241,
              actionType: 'ASSIGNMENT_FINISHED_ACTION',
              targetXref: '5e7f3084-4f11-414e-8637-d7ff962c4e8e',
              path: '/',
              assignmentXref: '5e7f3084-4f11-414e-8637-d7ff962c4e8e',
              userXref: 'user57',
              tutorUid: 'a3f141ed-d367-46e9-94e1-1246fa35deae',
              tutorUidSequence: 1,
            },
          ],
          problemLogAndActions: [
            {
              prLog: {
                prCeri: 'PRABMR6R',
                id: 193645647,
                problemDbid: 339788,
                endTime: 1729093650549,
                startTime: 1729093590026,
                pathInfo: '/PSABC8CA/PRABMR6R',
                attemptCount: 1,
                sawAnswer: false,
                continuousScore: 1,
                discreteScore: 1,
                firstActionType: 'ANSWER',
                firstResponseTime: 60522,
                hintCount: 0,
                partLogData: {
                  '1': {
                    response: ['AD = 2AC', 'BC = CD'],
                    teacherComment: null,
                    hintCount: null,
                    attemptCount: null,
                    continuousScore: 1,
                    discreteScore: 1,
                  },
                },
              },
              actions: [
                {
                  timestamp: 1729093590026,
                  actionType: 'PROBLEM_STARTED_ACTION',
                  targetXref: 'PRABMR6R',
                  path: '/PSABC8CA/PRABMR6R',
                  assignmentXref: '5e7f3084-4f11-414e-8637-d7ff962c4e8e',
                  userXref: 'user57',
                  tutorUid: 'cd1384a9-ef97-4094-b162-bf9958a2a154',
                  tutorUidSequence: 23,
                  problemId: 339788,
                },
                {
                  timestamp: 1729093650548,
                  actionType: 'STUDENT_RESPONSE_ACTION',
                  targetXref: 'PRABMR6R',
                  path: '/PSABC8CA/PRABMR6R',
                  assignmentXref: '5e7f3084-4f11-414e-8637-d7ff962c4e8e',
                  userXref: 'user57',
                  tutorUid: 'cd1384a9-ef97-4094-b162-bf9958a2a154',
                  tutorUidSequence: 24,
                  problemId: 339788,
                  isFirstResponse: true,
                  partData: {
                    '1': {
                      response: ['AD = 2AC', 'BC = CD'],
                      correct: true,
                      responseCorrectness: [],
                      score: {
                        continuousScore: 1,
                        discreteScore: 1,
                      },
                      answerType: 'CHOOSE_N',
                    },
                  },
                  problemScore: {
                    continuousScore: 1,
                    discreteScore: 1,
                  },
                },
                {
                  timestamp: 1729093650549,
                  actionType: 'PROBLEM_FINISHED_ACTION',
                  targetXref: 'PRABMR6R',
                  path: '/PSABC8CA/PRABMR6R',
                  assignmentXref: '5e7f3084-4f11-414e-8637-d7ff962c4e8e',
                  userXref: 'user57',
                  tutorUid: 'cd1384a9-ef97-4094-b162-bf9958a2a154',
                  tutorUidSequence: 25,
                  problemId: 339788,
                  problemScore: {
                    continuousScore: 1,
                    discreteScore: 1,
                  },
                  partScore: {
                    '1': {
                      continuousScore: 1,
                      discreteScore: 1,
                    },
                  },
                },
              ],
            },
            {
              prLog: {
                prCeri: 'PRABMR6Q',
                id: 193645266,
                problemDbid: 339664,
                endTime: 1729093588296,
                startTime: 1729093482372,
                pathInfo: '/PSABC8CA/PRABMR6Q',
                attemptCount: 1,
                sawAnswer: false,
                continuousScore: 1,
                discreteScore: 1,
                firstActionType: 'ANSWER',
                firstResponseTime: 105923,
                hintCount: 0,
                partLogData: {
                  '1': {
                    response: [
                      'Construct a circle centered at C',
                      'Construct a circle centered at D',
                      'Label the intersection points of the circles A and B',
                      'Draw the line through points A and B',
                    ],
                    teacherComment: null,
                    hintCount: null,
                    attemptCount: null,
                    continuousScore: 1,
                    discreteScore: 1,
                  },
                },
              },
              actions: [
                {
                  timestamp: 1729093482372,
                  actionType: 'PROBLEM_STARTED_ACTION',
                  targetXref: 'PRABMR6Q',
                  path: '/PSABC8CA/PRABMR6Q',
                  assignmentXref: '5e7f3084-4f11-414e-8637-d7ff962c4e8e',
                  userXref: 'user57',
                  tutorUid: 'cd1384a9-ef97-4094-b162-bf9958a2a154',
                  tutorUidSequence: 20,
                  problemId: 339664,
                },
                {
                  timestamp: 1729093588295,
                  actionType: 'STUDENT_RESPONSE_ACTION',
                  targetXref: 'PRABMR6Q',
                  path: '/PSABC8CA/PRABMR6Q',
                  assignmentXref: '5e7f3084-4f11-414e-8637-d7ff962c4e8e',
                  userXref: 'user57',
                  tutorUid: 'cd1384a9-ef97-4094-b162-bf9958a2a154',
                  tutorUidSequence: 21,
                  problemId: 339664,
                  isFirstResponse: true,
                  partData: {
                    '1': {
                      response: [
                        'Construct a circle centered at C',
                        'Construct a circle centered at D',
                        'Label the intersection points of the circles A and B',
                        'Draw the line through points A and B',
                      ],
                      correct: true,
                      responseCorrectness: [],
                      score: {
                        continuousScore: 1,
                        discreteScore: 1,
                      },
                      answerType: 'CHOOSE_N',
                    },
                  },
                  problemScore: {
                    continuousScore: 1,
                    discreteScore: 1,
                  },
                },
                {
                  timestamp: 1729093588296,
                  actionType: 'PROBLEM_FINISHED_ACTION',
                  targetXref: 'PRABMR6Q',
                  path: '/PSABC8CA/PRABMR6Q',
                  assignmentXref: '5e7f3084-4f11-414e-8637-d7ff962c4e8e',
                  userXref: 'user57',
                  tutorUid: 'cd1384a9-ef97-4094-b162-bf9958a2a154',
                  tutorUidSequence: 22,
                  problemId: 339664,
                  problemScore: {
                    continuousScore: 1,
                    discreteScore: 1,
                  },
                  partScore: {
                    '1': {
                      continuousScore: 1,
                      discreteScore: 1,
                    },
                  },
                },
              ],
            },
            {
              prLog: {
                prCeri: 'PRABMR6P',
                id: 193645250,
                problemDbid: 339587,
                endTime: 1729093481684,
                startTime: 1729093474886,
                pathInfo: '/PSABC8CA/PRABMR6P',
                attemptCount: 1,
                sawAnswer: false,
                firstActionType: 'ANSWER',
                firstResponseTime: 6797,
                hintCount: 0,
                partLogData: {
                  '1': {
                    response: ['<p>This is a test</p>'],
                    teacherComment: null,
                    hintCount: null,
                    attemptCount: null,
                    continuousScore: null,
                    discreteScore: null,
                  },
                },
              },
              actions: [
                {
                  timestamp: 1729093474886,
                  actionType: 'PROBLEM_STARTED_ACTION',
                  targetXref: 'PRABMR6P',
                  path: '/PSABC8CA/PRABMR6P',
                  assignmentXref: '5e7f3084-4f11-414e-8637-d7ff962c4e8e',
                  userXref: 'user57',
                  tutorUid: 'cd1384a9-ef97-4094-b162-bf9958a2a154',
                  tutorUidSequence: 17,
                  problemId: 339587,
                },
                {
                  timestamp: 1729093481683,
                  actionType: 'STUDENT_SUBMISSION_ACTION',
                  targetXref: 'PRABMR6P',
                  path: '/PSABC8CA/PRABMR6P',
                  assignmentXref: '5e7f3084-4f11-414e-8637-d7ff962c4e8e',
                  userXref: 'user57',
                  tutorUid: 'cd1384a9-ef97-4094-b162-bf9958a2a154',
                  tutorUidSequence: 18,
                  problemId: 339587,
                  isFirstResponse: true,
                  htmlMarker: 1,
                  response: '<p>This is a test</p>',
                },
                {
                  timestamp: 1729093481684,
                  actionType: 'PROBLEM_FINISHED_ACTION',
                  targetXref: 'PRABMR6P',
                  path: '/PSABC8CA/PRABMR6P',
                  assignmentXref: '5e7f3084-4f11-414e-8637-d7ff962c4e8e',
                  userXref: 'user57',
                  tutorUid: 'cd1384a9-ef97-4094-b162-bf9958a2a154',
                  tutorUidSequence: 19,
                  problemId: 339587,
                },
              ],
            },
            {
              prLog: {
                prCeri: 'PRBFPDP',
                id: 193645236,
                problemDbid: 339273,
                endTime: 1729093474177,
                startTime: 1729093468571,
                pathInfo: '/PSABC8CA/PSBB9CA/PRBFPDP',
                attemptCount: 1,
                sawAnswer: false,
                firstActionType: 'ANSWER',
                firstResponseTime: 5605,
                hintCount: 0,
                partLogData: {
                  '1': {
                    response: ['<p>Testing</p>'],
                    teacherComment: null,
                    hintCount: null,
                    attemptCount: null,
                    continuousScore: null,
                    discreteScore: null,
                  },
                },
              },
              actions: [
                {
                  timestamp: 1729093468571,
                  actionType: 'PROBLEM_STARTED_ACTION',
                  targetXref: 'PRBFPDP',
                  path: '/PSABC8CA/PSBB9CA/PRBFPDP',
                  assignmentXref: '5e7f3084-4f11-414e-8637-d7ff962c4e8e',
                  userXref: 'user57',
                  tutorUid: 'cd1384a9-ef97-4094-b162-bf9958a2a154',
                  tutorUidSequence: 13,
                  problemId: 339273,
                },
                {
                  timestamp: 1729093474176,
                  actionType: 'STUDENT_SUBMISSION_ACTION',
                  targetXref: 'PRBFPDP',
                  path: '/PSABC8CA/PSBB9CA/PRBFPDP',
                  assignmentXref: '5e7f3084-4f11-414e-8637-d7ff962c4e8e',
                  userXref: 'user57',
                  tutorUid: 'cd1384a9-ef97-4094-b162-bf9958a2a154',
                  tutorUidSequence: 14,
                  problemId: 339273,
                  isFirstResponse: true,
                  htmlMarker: 1,
                  response: '<p>Testing</p>',
                },
                {
                  timestamp: 1729093474177,
                  actionType: 'PROBLEM_FINISHED_ACTION',
                  targetXref: 'PRBFPDP',
                  path: '/PSABC8CA/PSBB9CA/PRBFPDP',
                  assignmentXref: '5e7f3084-4f11-414e-8637-d7ff962c4e8e',
                  userXref: 'user57',
                  tutorUid: 'cd1384a9-ef97-4094-b162-bf9958a2a154',
                  tutorUidSequence: 15,
                  problemId: 339273,
                },
              ],
            },
            {
              prLog: {
                prCeri: 'PRBFPBU',
                id: 193645075,
                problemDbid: 339137,
                endTime: 1729093467320,
                startTime: 1729093406125,
                pathInfo: '/PSABC8CA/PSBB9CA/PRBFPBU',
                attemptCount: 1,
                sawAnswer: false,
                continuousScore: 1,
                discreteScore: 1,
                firstActionType: 'ANSWER',
                firstResponseTime: 61194,
                hintCount: 0,
                partLogData: {
                  '1': {
                    response: ['Point E is closer to Point A'],
                    teacherComment: null,
                    hintCount: null,
                    attemptCount: null,
                    continuousScore: 1,
                    discreteScore: 1,
                  },
                },
              },
              actions: [
                {
                  timestamp: 1729093406125,
                  actionType: 'PROBLEM_STARTED_ACTION',
                  targetXref: 'PRBFPBU',
                  path: '/PSABC8CA/PSBB9CA/PRBFPBU',
                  assignmentXref: '5e7f3084-4f11-414e-8637-d7ff962c4e8e',
                  userXref: 'user57',
                  tutorUid: 'cd1384a9-ef97-4094-b162-bf9958a2a154',
                  tutorUidSequence: 10,
                  problemId: 339137,
                },
                {
                  timestamp: 1729093467319,
                  actionType: 'STUDENT_RESPONSE_ACTION',
                  targetXref: 'PRBFPBU',
                  path: '/PSABC8CA/PSBB9CA/PRBFPBU',
                  assignmentXref: '5e7f3084-4f11-414e-8637-d7ff962c4e8e',
                  userXref: 'user57',
                  tutorUid: 'cd1384a9-ef97-4094-b162-bf9958a2a154',
                  tutorUidSequence: 11,
                  problemId: 339137,
                  isFirstResponse: true,
                  partData: {
                    '1': {
                      response: ['Point E is closer to Point A'],
                      correct: true,
                      responseCorrectness: [],
                      score: {
                        continuousScore: 1,
                        discreteScore: 1,
                      },
                      answerType: 'CHOOSE_ONE',
                    },
                  },
                  problemScore: {
                    continuousScore: 1,
                    discreteScore: 1,
                  },
                },
                {
                  timestamp: 1729093467320,
                  actionType: 'PROBLEM_FINISHED_ACTION',
                  targetXref: 'PRBFPBU',
                  path: '/PSABC8CA/PSBB9CA/PRBFPBU',
                  assignmentXref: '5e7f3084-4f11-414e-8637-d7ff962c4e8e',
                  userXref: 'user57',
                  tutorUid: 'cd1384a9-ef97-4094-b162-bf9958a2a154',
                  tutorUidSequence: 12,
                  problemId: 339137,
                  problemScore: {
                    continuousScore: 1,
                    discreteScore: 1,
                  },
                  partScore: {
                    '1': {
                      continuousScore: 1,
                      discreteScore: 1,
                    },
                  },
                },
              ],
            },
            {
              prLog: {
                prCeri: 'PRABMR6M',
                id: 190949399,
                problemDbid: 339447,
                endTime: 1729093380269,
                startTime: 1724167899108,
                pathInfo: '/PSABC8CA/PRABMR6M',
                attemptCount: 3,
                sawAnswer: false,
                continuousScore: 0.33,
                discreteScore: 0,
                firstActionType: 'ANSWER',
                firstResponseTime: 4925467179,
                hintCount: 0,
                partLogData: {
                  '1': {
                    response: [
                      'Line CD is perpendicular to segment AB',
                      'Point M is the midpoint of segment AB',
                    ],
                    teacherComment: null,
                    hintCount: null,
                    attemptCount: null,
                    continuousScore: 0.33,
                    discreteScore: 0,
                  },
                },
              },
              actions: [
                {
                  timestamp: 1724167899108,
                  actionType: 'PROBLEM_STARTED_ACTION',
                  targetXref: 'PRABMR6M',
                  path: '/PSABC8CA/PRABMR6M',
                  assignmentXref: '5e7f3084-4f11-414e-8637-d7ff962c4e8e',
                  userXref: 'user57',
                  tutorUid: '7fd85934-beb7-46c2-8117-a0c1c348a3b6',
                  tutorUidSequence: 3,
                  problemId: 339447,
                },
                {
                  timestamp: 1729093302536,
                  actionType: 'PROBLEM_RESUMED_ACTION',
                  targetXref: 'PRABMR6M',
                  path: '/PSABC8CA/PRABMR6M',
                  assignmentXref: '5e7f3084-4f11-414e-8637-d7ff962c4e8e',
                  userXref: 'user57',
                  tutorUid: 'cd1384a9-ef97-4094-b162-bf9958a2a154',
                  tutorUidSequence: 3,
                  problemId: 339447,
                },
                {
                  timestamp: 1729093366287,
                  actionType: 'STUDENT_RESPONSE_ACTION',
                  targetXref: 'PRABMR6M',
                  path: '/PSABC8CA/PRABMR6M',
                  assignmentXref: '5e7f3084-4f11-414e-8637-d7ff962c4e8e',
                  userXref: 'user57',
                  tutorUid: 'cd1384a9-ef97-4094-b162-bf9958a2a154',
                  tutorUidSequence: 4,
                  problemId: 339447,
                  isFirstResponse: true,
                  partData: {
                    '1': {
                      response: [
                        'Line CD is perpendicular to segment AB',
                        'Point M is the midpoint of segment AB',
                      ],
                      correct: false,
                      responseCorrectness: [],
                      score: {
                        continuousScore: 0.67,
                        discreteScore: 0,
                      },
                      answerType: 'CHOOSE_N',
                    },
                  },
                  problemScore: {
                    continuousScore: 0.67,
                    discreteScore: 0,
                  },
                },
                {
                  timestamp: 1729093370037,
                  actionType: 'VIEWED_AVAILABLE_SUPPORTS_ACTION',
                  targetXref: 'PRABMR6M',
                  path: '/PSABC8CA/PRABMR6M',
                  assignmentXref: '5e7f3084-4f11-414e-8637-d7ff962c4e8e',
                  userXref: 'user57',
                  tutorUid: 'cd1384a9-ef97-4094-b162-bf9958a2a154',
                  tutorUidSequence: 5,
                  problemId: 339447,
                  availableSupports: ['TSBDPUK'],
                },
                {
                  timestamp: 1729093375275,
                  actionType: 'STUDENT_RESPONSE_ACTION',
                  targetXref: 'PRABMR6M',
                  path: '/PSABC8CA/PRABMR6M',
                  assignmentXref: '5e7f3084-4f11-414e-8637-d7ff962c4e8e',
                  userXref: 'user57',
                  tutorUid: 'cd1384a9-ef97-4094-b162-bf9958a2a154',
                  tutorUidSequence: 6,
                  problemId: 339447,
                  isFirstResponse: false,
                  partData: {
                    '1': {
                      response: [
                        'Line CD is perpendicular to segment AB',
                        'Point M is the midpoint of segment AB',
                        'Segment AM is perpendicular to segment BM',
                      ],
                      correct: false,
                      responseCorrectness: [],
                      score: {
                        continuousScore: 0.33,
                        discreteScore: 0,
                      },
                      answerType: 'CHOOSE_N',
                    },
                  },
                  problemScore: {
                    continuousScore: 0.33,
                    discreteScore: 0,
                  },
                },
                {
                  timestamp: 1729093380267,
                  actionType: 'STUDENT_RESPONSE_ACTION',
                  targetXref: 'PRABMR6M',
                  path: '/PSABC8CA/PRABMR6M',
                  assignmentXref: '5e7f3084-4f11-414e-8637-d7ff962c4e8e',
                  userXref: 'user57',
                  tutorUid: 'cd1384a9-ef97-4094-b162-bf9958a2a154',
                  tutorUidSequence: 7,
                  problemId: 339447,
                  isFirstResponse: false,
                  partData: {
                    '1': {
                      response: [
                        'Line CD is perpendicular to segment AB',
                        'Point M is the midpoint of segment AB',
                        'CB + BD &gt; CD',
                      ],
                      correct: true,
                      responseCorrectness: [],
                      score: {
                        continuousScore: 0.33,
                        discreteScore: 0,
                      },
                      answerType: 'CHOOSE_N',
                    },
                  },
                  problemScore: {
                    continuousScore: 0.33,
                    discreteScore: 0,
                  },
                },
                {
                  timestamp: 1729093380269,
                  actionType: 'PROBLEM_FINISHED_ACTION',
                  targetXref: 'PRABMR6M',
                  path: '/PSABC8CA/PRABMR6M',
                  assignmentXref: '5e7f3084-4f11-414e-8637-d7ff962c4e8e',
                  userXref: 'user57',
                  tutorUid: 'cd1384a9-ef97-4094-b162-bf9958a2a154',
                  tutorUidSequence: 8,
                  problemId: 339447,
                  problemScore: {
                    continuousScore: 0.33,
                    discreteScore: 0,
                  },
                  partScore: {
                    '1': {
                      continuousScore: 0.33,
                      discreteScore: 0,
                    },
                  },
                },
              ],
            },
          ],
        },
      ],
    } as object);
  }
}
