import { tinymceImages } from './tinymceImages';

export default {
  relative_urls: false,
  browser_spellcheck: true,
  invalid_elements: 'script',
  image_advtab: true,
  automatic_uploads: false,
  default_link_target: '_blank',
  toolbar:
    'fontsizeselect bold italic underline | superscript subscript | alignleft aligncenter alignright | bullist numlist | image table | tiny_mce_wiris_formulaEditor charmap | undo redo ',
  plugins:
    'advlist lists paste media table charmap link code preview astImagePlugins image',
  file_picker_types: 'image background_image',
  images_upload_credentials: true,
  imagetools_cors_hosts: [process.env.VUE_APP_RESOURCES_URL],
  imagetools_credentials_hosts: [process.env.VUE_APP_RESOURCES_URL],
  image_caption: true,
  paste_data_images: true,
  images_upload_url: process.env.VUE_APP_RESOURCES_URL + '/upload/C',
  file_picker_callback: tinymceImages.file_picker_callback,
  paste_preprocess: tinymceImages.paste_preprocess,
  paste_postprocess: tinymceImages.paste_postprocess,
  assistments_upload_type: 'C',
  ast_base_plugin_url:
    process.env.VUE_APP_RESOURCES_URL +
    '/assets/js/common/tinymce/5.0/tinymce/plugins/astImagePlugins/',
  // In Tiny5.0, resize is set to true by default if autoresize plugin is NOT enabled,
  // false if enabled.
  resize: 'both',
  height: 450,
  image_uploadtab: false,
  external_plugins: {
    assistcompress:
      process.env.VUE_APP_RESOURCES_URL +
      '/assets/js/common/tinymce/5.0/tinymce/plugins/assistcompress/plugin.js',
    astImagePlugins:
      process.env.VUE_APP_RESOURCES_URL +
      '/assets/js/common/tinymce/5.0/tinymce/plugins/astImagePlugins/plugin.js',
    //add wiris
    tiny_mce_wiris:
      process.env.VUE_APP_RESOURCES_URL +
      '/assets/js/common/tinymce/5.0/tinymce/plugins/tiny_mce_wiris/plugin.min.js',
  },
  mathTypeParameters: {
    serviceProviderProperties: {
      URI:
        process.env.VUE_APP_WIRIS_URL +
        '/pluginwiris_engine/app/configurationjs',
      server: 'java',
    },
  },
};
