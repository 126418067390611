import { User } from '@/domain/User';

export function sortUndefined<T>(a?: T, b?: T): number | undefined {
  if (a === undefined && b === undefined) {
    return 0;
  } else if (a === undefined) {
    // Put it after B.
    return -1;
  } else if (b === undefined) {
    // Put it after A.
    return 1;
  }
  return undefined;
}

export const sortByLastFirstName = (a?: User, b?: User): number => {
  if (a == undefined || b == undefined) {
    return sortUndefined<User>(a, b) as number;
  }
  const sortableNameA = `${a.lastName},${a.firstName}`;
  const sortableNameB = `${b.lastName},${b.firstName}`;
  return sortableNameA.localeCompare(sortableNameB);
};

export const sortBySortableString = (a: string, b: string): number =>
  a.localeCompare(b);

export const sortBySortableNumber = (a?: number, b?: number): number => {
  if (a == undefined || b == undefined) {
    return sortUndefined<number>(a, b) as number;
  }
  return a > b ? 1 : -1;
};
