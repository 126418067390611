import { render, staticRenderFns } from "./DeleteFolderDialog.vue?vue&type=template&id=79eb255e&scoped=true&"
import script from "./DeleteFolderDialog.vue?vue&type=script&lang=ts&setup=true&"
export * from "./DeleteFolderDialog.vue?vue&type=script&lang=ts&setup=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "79eb255e",
  null
  
)

export default component.exports