import { RETURN_TEXT, RETURN_URL } from '@/domain/PageParams';
import { computed, Ref } from 'vue';
import { Route } from 'vue-router';

interface RouteLogic {
  returnUrl: Ref<string | undefined>;
  returnText: Ref<string | undefined>;
}

export function useRouteLogic(route: Route): RouteLogic {
  const returnUrl = computed((): string | undefined => {
    return route.query[RETURN_URL] as string;
  });

  const returnText = computed((): string | undefined => {
    return route.query[RETURN_TEXT] as string;
  });

  return {
    returnUrl,
    returnText,
  };
}
