import { GetterTree } from 'vuex';
import { InsightsHubState } from './types';
import { RootState } from '../types';
import { CurriculumDefinition, ModuleDefinition } from '@/domain/Curriculum';
import { User } from '@/domain/User';
import { CourseDefinition } from '@/domain/Course';
import { School } from '@/domain/School';
import { FolderDefinition } from '@/domain/Folder';
import { TotalStandardStats } from '@/domain/ReportData/InsightsHub';
import { StandardNodeDefinition } from '@/domain/Skill';

export const getters: GetterTree<InsightsHubState, RootState> = {
  supportedCurricula(_state, _getters, rootState) {
    const supportedXrefs = [
      'cb031912-8c16-1d17-53be-d5473fcc3e4f',
      'a9beff09-6e2a-8eef-d36a-4ebac161ca69',
      '619db8db-5e63-5391-ed95-83d658597d13',
    ];
    return rootState.curricula.downloadedDefinitions.filter(
      (curriculum: CurriculumDefinition) =>
        supportedXrefs.includes(curriculum.xref)
    );
  },
  selectedCurriculum(state, _getters, rootState): CurriculumDefinition | null {
    return (
      rootState.curricula.downloadedDefinitions.find(
        (curriculum) => curriculum === state.selectedCurriculum
      ) ?? null
    );
  },
  rootFolderForSelectedCurriculum(
    _state,
    getters,
    rootState
  ): FolderDefinition | null {
    const rootFolderXref = getters.selectedCurriculum?.rootFolderXref;
    return rootState.folder.folderMap[rootFolderXref] ?? null;
  },

  gradeFoldersForSelectedCurriculum(
    _state,
    getters,
    rootState
  ): FolderDefinition[] {
    const folderXrefs = getters.rootFolderForSelectedCurriculum?.children ?? [];
    return folderXrefs
      .map((xref: string) => rootState.folder.folderMap[xref])
      .filter((val: any) => val);
  },

  selectedGradeFolder(state): FolderDefinition | null {
    return (
      state.gradeFolders.find(
        (gradeFolder) => gradeFolder.xref === state.selectedGradeXref
      ) || null
    );
  },
  selectedModuleDefinition(state): ModuleDefinition | null {
    if (
      state.curriculumGrade &&
      state.curriculumGrade.modules &&
      state.selectedModuleId
    ) {
      return state.curriculumGrade.modules.get(state.selectedModuleId) || null;
    }
    return null;
  },
  xrefToGradeTeacherMap(state, getters): Map<string, User> {
    const res = new Map();
    const gradeFolder = getters.selectedGradeFolder;
    if (gradeFolder) {
      const gradeLevels = gradeFolder?.attributes?.gradeLevel ?? [];
      for (const teacher of state.menteeTeachers) {
        const teacherGradeLevels = teacher?.attributes?.gradeLevel ?? [];
        const inGradeLevel = teacherGradeLevels.some((grade) =>
          gradeLevels.includes(grade)
        );
        if (inGradeLevel) {
          res.set(teacher.xref, teacher);
        }
      }
    }

    return res;
  },
  xrefToCourseMap(state): Map<string, CourseDefinition> {
    const res = new Map();
    for (const course of state.menteeCourses) {
      res.set(course.xref, course);
    }
    return res;
  },
  xrefToSchoolMap(state): Map<string, School> {
    const res = new Map();
    for (const school of state.menteeSchools) {
      res.set(school.ncesId, school);
    }
    return res;
  },
  ownerXrefToCourseXrefsMap(state): Map<string, string[]> {
    const res = new Map();
    for (const course of state.menteeCourses) {
      const courseXrefs = res.get(course.ownerXref);
      if (courseXrefs) {
        courseXrefs.push(course.xref);
      } else {
        res.set(course.ownerXref, [course.xref]);
      }
    }
    return res;
  },
  currentTimeData(state): Map<string, TotalStandardStats> {
    return state.currentTimeData;
  },
  getHasDownloadedSkills(state): boolean {
    return state.hasDownloadedSkills;
  },
  getIsDownloadingSkills(state): boolean {
    return state.isDownloadingSkills;
  },
  getStandardNodesMap(state): Map<string, StandardNodeDefinition> {
    const res = new Map<string, StandardNodeDefinition>();
    state.standardNodes.forEach((node) => res.set(node.xref, node));
    return res;
  },
  // FIXME: Mapped to multiple standard nodes?
  getSkillNodesMap(state): Map<string, string> {
    const res = new Map<string, string>();
    for (const node of state.standardNodes) {
      const skills = node.skills;
      skills?.forEach((skill) => res.set(skill, node.xref));
    }
    return res;
  },
};
