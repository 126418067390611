var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('v-card',{staticClass:"d-print-none"},[(_setup.isContentAdminUser)?_c('v-tabs',{attrs:{"background-color":"white","color":"primary","fixed-tabs":""}},[_c('v-tabs-slider',{attrs:{"color":"primary"}}),_c('v-tab',{attrs:{"to":{
          name: 'mainHierarchyPage',
        },"data-cy":"mainHierarchyTab"}},[_vm._v(" Main Hierarchy ")]),_c('v-tab',{attrs:{"to":{
          name: 'collectionSettingsPage',
        },"data-cy":"collectionSettingsTab"}},[_vm._v(" Collection Settings ")]),_c('v-tab',{attrs:{"to":{
          name: 'attributionsPage',
        },"data-cy":"attributionsPageTab"}},[_vm._v(" Attributions ")]),_c('v-tab',{attrs:{"to":{
          name: 'globalProblemSetsPage',
        },"data-cy":"globalProblemSetsPageTab"}},[_vm._v(" Global Problem Set Types ")])],1):_vm._e()],1),_c('div',{attrs:{"id":"builder-container"}},[_c('router-view')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }