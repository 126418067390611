import { ActionTree } from 'vuex';
import { CourseState } from './types';
import { RootState } from '../types';
import { LmsProviderType } from '@/domain/LmsProviderType';
import { CourseDefinition } from '@/domain/Course';
import { findCourses } from '@/api/core/course.api';
import { IsActiveFilterType } from '@/domain/State';

export const actions: ActionTree<CourseState, RootState> = {
  requestCourseDefinitions(
    context,
    forceRedownload = false
  ): Promise<CourseDefinition[]> {
    if (
      forceRedownload ||
      (!context.state.hasDownloaded && !context.state.isDownloading)
    ) {
      context.commit('setIsDownloading', true);

      return findCourses({ teacher: 'me' }).then(
        (courses: CourseDefinition[]) => {
          let importedCourses: CourseDefinition[] = courses;

          if (
            context.rootState.auth.user?.lmsProviderType ===
            LmsProviderType.LTI_ENABLED
          ) {
            const contextXref = context.rootState.lti.launch?.contextXref;
            importedCourses = importedCourses.filter(
              (course: CourseDefinition) => course.xref === contextXref
            );
          }
          context.commit('setCourseList', importedCourses);
          context.commit('setHasDownloaded', true);
          context.commit('setIsDownloading', false);

          return importedCourses;
        }
      );
    }

    return Promise.resolve(context.state.courses);
  },
};
