import { render, staticRenderFns } from "./LegacyLinkRedirectionPage.vue?vue&type=template&id=ae8190de&scoped=true&"
import script from "./LegacyLinkRedirectionPage.vue?vue&type=script&lang=ts&setup=true&"
export * from "./LegacyLinkRedirectionPage.vue?vue&type=script&lang=ts&setup=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ae8190de",
  null
  
)

export default component.exports