import { AclPermissionType, AclResourceType } from '@/domain/Acls';
import { AttributeNames } from '@/domain/Attributes';
import {
  FolderDefinition,
  FolderMember,
  FolderMemberType,
  FolderProperties,
  FolderStats,
  UserFolderType,
} from '@/domain/Folder';
import { ProblemSetDefinition } from '@/domain/ProblemSet';
import { coreAxios } from '@/plugins/axios';
import { ProblemSetDefinitionDTO, transformProblemSet } from './content.api';
import { AssignmentDefinitionDTO, transformAssignment } from './assignment.api';
import { GroupChildDepthType, GroupType } from '@/domain/Group';
import {
  DefinitionInclude,
  DefinitionParams,
  ObjectList,
  PagingParams,
} from './base.api';
import { CancelToken } from 'axios';
import { AttributesMapDTO, transformAttributes } from './attributes.api';
import { isEmpty } from 'lodash';

const END_POINT = '/folders';

export interface FolderDefinitionDTO
  extends Omit<
    FolderDefinition,
    'memberType' | 'children' | 'attributes' | 'permissions'
  > {
  attributes?: AttributesMapDTO;
  permissions?: AclPermissionType[];
}

export interface IFolderOperationParamCommon {
  attributes?: AttributeNames[];
  settings?: string[];
}

export interface FolderOperationParams {
  keep?: IFolderOperationParamCommon;
  skip?: IFolderOperationParamCommon;
}

export interface FoldersFilterParams {
  xrefs?: string[];
  types?: (GroupType.CONTENT_FOLDER | GroupType.ASSIGNMENTS_FOLDER)[];
  ufTypes?: UserFolderType[];

  // 'me' or xref
  owner?: string;

  name?: string;
  curricula?: string[];
}

const getFolder = (
  xref: string,
  include?: DefinitionInclude[]
): Promise<FolderDefinition> => {
  validateParams({ xrefs: [xref] });
  return coreAxios
    .get(`${END_POINT}/${xref}`, {
      params: {
        include: include ?? [],
      },
    })
    .then((res) => {
      return transformFolder(res.data);
    });
};

const searchForFolders = (
  filterParams?: FoldersFilterParams,
  pagingParams?: PagingParams,
  definitionParams?: DefinitionParams,
  cancelToken?: CancelToken
): Promise<ObjectList<FolderDefinition>> => {
  // MUST have some params.
  validateParams(filterParams);
  return coreAxios
    .get(`${END_POINT}`, {
      params: { ...filterParams, ...pagingParams, ...definitionParams },
      cancelToken,
    })
    .then((res) => {
      return {
        nextPageToken: res.data.nextPageToken,
        data: res.data.data.map(transformFolder),
        count: res.data.count,
      };
    });
};

// Folder Paths returned exclude member as a path element/part (do not end with member).
const getFolderPathsTo = (
  sources: string[],
  target: string,
  controller?: AbortController
): Promise<(FolderDefinition | ProblemSetDefinition)[][]> => {
  return coreAxios
    .get(`${END_POINT}/find`, {
      params: {
        sources,
        // Folder, Assignment, Problem Set, or Problem
        contains: target,
      },
      signal: controller?.signal,
    })
    .then((res) => {
      return res.data.map(
        (path: (FolderDefinitionDTO | ProblemSetDefinitionDTO)[]) =>
          path.map(transformFolderMember)
      );
    });
};

const getFolderStats = (
  xref: string,
  cdType: GroupChildDepthType
): Promise<FolderStats> => {
  return coreAxios
    .get(`${END_POINT}/${xref}/fstats`, { params: { cdType } })
    .then((res) => {
      return res.data;
    });
};

export enum CopyPermissionMode {
  CURRENT = 'CURRENT',
  INHERIT = 'INHERIT',
  NONE = 'NONE',
}

const copyFolder = (
  source: string,
  destination: string,
  copyPermissionMode: CopyPermissionMode,
  sourceOverride: EditableFolderFields,
  copyProperties = true,
  operationParams?: FolderOperationParams
): Promise<string> => {
  return coreAxios
    .post(
      `${END_POINT}/${source}/copy`,
      { sourceOverride, operationParams },
      {
        params: {
          destination,
          copyPermissionMode,
          copyProperties,
        },
      }
    )
    .then((res) => {
      return res.data;
    });
};

const moveFolder = (
  source: string,
  target: string,
  destination: string,
  inheritPermissions = false,
  operationParams?: FolderOperationParams
): Promise<void> => {
  return coreAxios.post(`${END_POINT}/${source}/move`, operationParams, {
    params: {
      target,
      destination,
      inheritPermissions,
    },
  });
};

export interface EditableFolderFields {
  name?: string;
  properties?: Partial<FolderProperties>;
}

const createFolder = (
  destination: string,
  fields: EditableFolderFields,
  inheritPermissions = true
): Promise<string> => {
  return coreAxios
    .post(`${END_POINT}/${destination}`, fields, {
      params: {
        inheritPermissions,
      },
    })
    .then((res) => {
      return res.data;
    });
};

const updateFolder = (
  xref: string,
  fields: EditableFolderFields
): Promise<void> => {
  return coreAxios.patch(`${END_POINT}/${xref}`, fields);
};

const deleteFolder = (xref: string, mustBeEmpty: boolean): Promise<void> => {
  return coreAxios.delete(`${END_POINT}/${xref}`, {
    params: {
      mustBeEmpty,
    },
  });
};

// FIXME: Implement and handle pagination and sort.
const getFolderMembers = (
  xref: string,
  include?: DefinitionInclude[],
  pagingParams?: PagingParams
): Promise<ObjectList<FolderMember>> => {
  return coreAxios
    .get(`${END_POINT}/${xref}/members`, {
      params: {
        include: include ?? [],
        ...pagingParams,
      },
    })
    .then((res) => {
      return {
        data: res.data.data.map(transformFolderMember),
        nextPageToken: res.data.nextPageToken,
      };
    });
};

const updateFolderMemberPosition = (
  source: string,
  member: string,
  position: number
): Promise<void> => {
  return coreAxios.patch(`${END_POINT}/${source}/members/${member}`, {
    position,
  });
};

const addMembersToFolder = (
  source: string,
  resources: string[]
): Promise<void> => {
  return coreAxios.post(`${END_POINT}/${source}/members`, {
    members: resources,
  });
};

const removeMembersFromFolder = (
  folderXref: string,
  members: string[]
): Promise<void> => {
  return coreAxios.delete(`${END_POINT}/${folderXref}/members`, {
    params: { members },
  });
};

////////////
// Helper //
////////////
function transformFolder(folder: FolderDefinitionDTO): FolderDefinition {
  const { attributes, permissions, ...rest } = folder;
  return {
    ...rest,
    memberType: FolderMemberType.FOLDER,
    attributes: attributes ? transformAttributes(attributes) : undefined,
    resourceType: AclResourceType.SDK3_FOLDER,
    permissions: permissions ?? [],
    // Internal processing only. Will not included in DTO if not set manually.
    children: [],
  };
}

function transformFolderMember(
  member:
    | ProblemSetDefinitionDTO
    | FolderDefinitionDTO
    | AssignmentDefinitionDTO
): FolderMember {
  // If this groupType field is present, it must be a Folder.
  // If Assignment Folder, the Folder must contain only Folder and/or Assignment members.
  // If Content Folder, the Folder must contain only Folder and/or Problem Set members.
  // FIXME: A better to do this? Otherwise, we'll probably want the backend to send some
  // kind of identifier so the frontend can do the correct conversion similar to contentType.
  if ('problemSetType' in member) {
    return transformProblemSet(member);
  } else if ('groupType' in member) {
    return transformFolder(member);
  } else {
    return transformAssignment(member);
  }
}

function validateParams(filterParams?: FoldersFilterParams): void {
  if (isEmpty(filterParams)) {
    throw new Error('MUST provide filter params.');
  } else if (
    filterParams?.xrefs &&
    !filterParams.xrefs.every((xref) => xref?.length)
  ) {
    throw new Error('xrefs contain invalid values: ' + filterParams.xrefs);
  }
}

export {
  getFolder,
  searchForFolders,
  getFolderPathsTo,
  getFolderStats,
  copyFolder,
  moveFolder,
  createFolder,
  updateFolder,
  deleteFolder,
  getFolderMembers,
  updateFolderMemberPosition,
  addMembersToFolder,
  removeMembersFromFolder,
  transformFolder,
};
