/* eslint-disable no-undef */
//NOT FIXING "errors" yet
//TODO: Rewrite this in typescript or in the tinyMCE vue file we are creating

export const tinymceImages = (function () {
  const file_picker_callback = function (cb, _value, _meta) {
    // Need `this` as a variable to reference it in the anonymous function used for input.onchange
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const currentEditor = this;
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');

    // Note: In modern browsers input[type="file"] is functional without
    // even adding it to the DOM, but that might not be the case in some older
    // or quirky browsers like IE, so you might want to add it to the DOM
    // just in case, and visually hide it. And do not forget do remove it
    // once you do not need it anymore.
    input.onchange = function () {
      const file = this.files[0];

      const reader = new FileReader();
      reader.onload = function () {
        // Note: Now we need to register the blob in TinyMCEs image blob
        // registry. In the next release this part hopefully won't be
        // necessary, as we are looking to handle it internally.
        const id = 'blobid' + tinymce.DOM.uniqueId();
        const blobCache = currentEditor.editorUpload.blobCache;
        const base64 = reader.result.split(',')[1];
        const blobInfo = blobCache.create(id, file, base64);
        blobCache.add(blobInfo);

        // call the callback
        cb(blobInfo.blobUri(), currentEditor.settings.assistments_image_data);
      };
      reader.readAsDataURL(file);
    };

    input.click();
  };

  const paste_preprocess = function (plugin, args) {
    // eslint-disable-next-line no-control-regex
    args.content = args.content.replace(/[\x00-\x1F\x7F-\x9F]/g, '');
  };

  const paste_postprocess = function (plugin, args) {
    const editor = args.target;
    const data = editor.settings.assistments_image_data;

    if (data) {
      const imgs = args.node.getElementsByTagName('img');

      for (let i = 0; i < imgs.length; i++) {
        const img = imgs[i];

        if (img.classList.contains('Wirisformula')) {
          continue;
        }

        for (const prop in data) {
          if (Object.hasOwn(data, prop)) {
            if (prop === 'width') {
              const naturalWidth = img.naturalWidth || img.width;
              if (data[prop] < naturalWidth) {
                img.setAttribute(prop, data[prop]);
              }
            } else {
              img.setAttribute(prop, data[prop]);
            }
          }
        }
      }
    }
  };

  const setupPromise = function () {
    if (window.tinymce) {
      window.Promise = window.Promise ? window.Promise : tinymce.util.Promise;
    } else {
      setTimeout(setupPromise, 50);
    }
  };

  setupPromise();

  return {
    file_picker_callback: file_picker_callback,
    paste_preprocess: paste_preprocess,
    paste_postprocess: paste_postprocess,
  };
})();
