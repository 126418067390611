import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import FindProblemsPage from '../views/FindProblems/FindProblemsPage.vue';
import SearchResults from '../views/FindProblems/SearchResults.vue';
import SearchResultsProblems from '../views/FindProblems/SearchResultsProblems.vue';
import SearchResultsSkillBuilders from '../views/FindProblems/SearchResultsSkillBuilders.vue';
import MyAssignmentsPage from '../views/Assignments/MyAssignmentsPage.vue';
import StudentDetailsPage from '../views/Assignments/StudentDetailsPage.vue';
import MyBookmarksPage from '../views/MyBookmarksPage.vue';
import LessonPage from '../views/FindProblems/LessonPage.vue';
import NotFoundPage from '../views/NotFoundPage.vue';
import PassThrough from '../components/base/PassThrough.vue';
import SettingsPage from '../views/Settings/SettingsPage.vue';
import AccountSettings from '../views/Settings/AccountSettings.vue';
import Preferences from '../views/Settings/Preferences.vue';
import FeatureSettings from '../views/Settings/FeatureSettings.vue';
import UnauthenticatedPage from '../views/UnauthenticatedPage.vue';
import UnauthorizedPage from '../views/UnauthorizedPage.vue';
import {
  enterIfAuthenticated,
  enterIfTeacher,
  enterIfHasOneOfRoles,
  afterEachReset,
} from '../utils/router.utils';
import TutorParent from '../views/Tutor/TutorParent.vue';
import TutorReportPage from '../views/Tutor/TutorReportPage.vue';
import TutorProblemsByStandardPage from '../views/Tutor/TutorProblemsByStandardPage.vue';
import SelectSchool from '../views/AdditionalRegistration/SelectSchool.vue';
import SelectGC from '../views/AdditionalRegistration/SelectGC.vue';
import LtiDeepLinkResponsePage from '../views/LtiDeepLinkResponsePage.vue';
import reloadOnChangeMiddleware from './middleware/reloadOnChange';
import InsightsHubParent from '../views/InsightsHub/InsightsHubParent.vue';
import InsightsHubLandingPage from '../views/InsightsHub/InsightsHubLandingPage.vue';
import BuilderParent from '../views/Builder/BuilderParent.vue';
import MainHierarchyPage from '../views/Builder/MainHierarchyPage.vue';
import AttributionsPage from '../views/Builder/AttributionsPage.vue';
import CollectionSettingsPage from '../views/Builder/CollectionSettingsPage.vue';
import GlobalProblemSetsPage from '../views/Builder/GlobalProblemSetsPage.vue';
import DashboardParent from '../views/InsightsHub/DashboardParent.vue';
import ActivityByCurriculumPage from '../views/InsightsHub/ActivityByCurriculumPage.vue';
import ActivityByStandardPage from '../views/InsightsHub/ActivityByStandardPage.vue';
import AchievementPage from '../views/InsightsHub/AchievementPage.vue';
import ContentBuilderPage from '../views/Builder/ContentBuilderPage.vue';
import LegacyLinkRedirectionPage from '../views/LegacyLinkRedirectionPage.vue';
import { UserRole } from '@/domain/User';
import { PageView } from '@/utils/navigation.util';
import { RETURN_URL } from '@/domain/PageParams';
import getAlertMessagesMiddleware from '@/router/middleware/getAlertMessages';
import OverTimeLandingPage from '@/views/Reports/OverTimeLandingPage.vue';
import OverTimeReportParent from '@/views/Reports/OverTimeReportParent.vue';
import CourseOverTimePage from '@/views/Reports/CourseOverTimePage.vue';
import StudentOverTimePage from '@/views/Reports/StudentOverTimePage.vue';
import AssignmentReportParent from '@/views/Assignments/AssignmentReportParent.vue';
import AssignmentReportPage from '@/views/Assignments/AssignmentReportPage.vue';
import StandardsReportPage from '@/views/Assignments/StandardsReportPage.vue';
import ProblemsReportPage from '@/views/Assignments/ProblemsReportPage.vue';
import SkillbuilderReportPage from '@/views/Assignments/SkillbuilderReportPage.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  { path: '/', redirect: '/find' },
  {
    path: '/find',
    component: PassThrough,
    children: [
      {
        path: '',
        name: 'findProblems',
        component: FindProblemsPage,
      },
      {
        path: 'lv/:type/:id/:psid?',
        name: 'LegacyLinkRedirectionPage',
        component: LegacyLinkRedirectionPage,
      },
      {
        path: 'folders/:folderXref/:problemSetXref?',
        name: 'LessonPageFolders',
        component: LessonPage,
        meta: { page: PageView.LESSON_VIEW },
      },
      {
        path: 'problemSets/:problemSetXref',
        name: 'LessonPageProblemSet',
        component: LessonPage,
        meta: { page: PageView.LESSON_VIEW },
      },
      {
        path: 'search-skills/:xref',
        name: 'Search Skills View Problems',
        component: SearchResults,
        meta: { page: PageView.SEARCH_RESULTS },
        children: [
          {
            path: 'problems',
            name: 'searchProblems',
            component: SearchResultsProblems,
            meta: { page: PageView.SEARCH_RESULTS },
          },
          {
            path: 'skill-builders',
            name: 'searchSkillBuilders',
            component: SearchResultsSkillBuilders,
            meta: { page: PageView.SEARCH_RESULTS },
          },
        ],
      },
    ],
  },
  {
    path: '/lti/response',
    beforeEnter: enterIfTeacher,
    name: 'LtiDeepLinkResponsePage',
    component: LtiDeepLinkResponsePage,
  },
  {
    path: '/assignments',
    component: PassThrough,
    children: [
      {
        path: '',
        beforeEnter: enterIfTeacher,
        name: 'myAssignments',
        component: MyAssignmentsPage,
      },
      {
        path: ':xref/reports',
        beforeEnter: (to, from, next) =>
          enterIfHasOneOfRoles(to, from, next, [
            UserRole.TEACHER,
            UserRole.MENTOR,
          ]),
        component: AssignmentReportParent,
        children: [
          {
            path: '',
            name: 'assignmentReportPage',
            component: AssignmentReportPage,
            children: [
              {
                path: 'problems',
                name: 'problemsReportPage',
                component: ProblemsReportPage,
              },
              {
                path: 'standards',
                name: 'standardsReportPage',
                component: StandardsReportPage,
              },
              {
                path: 'skillbuilder',
                name: 'skillbuilderReportPage',
                component: SkillbuilderReportPage,
              },
            ],
          },
          {
            path: 'student-details/:studentXref',
            name: 'studentDetailsPage',
            component: StudentDetailsPage,
          },
        ],
      },
    ],
  },
  // FIXME: Can we remove this?
  {
    path: '/bookmarks',
    beforeEnter: enterIfAuthenticated,
    component: MyBookmarksPage,
  },
  {
    path: '/tutor',
    beforeEnter: (to, from, next) =>
      enterIfHasOneOfRoles(
        to,
        from,
        next,
        [UserRole.TEACHER],
        'accessToLDOEReport'
      ),
    component: TutorParent,
    children: [
      {
        path: 'report',
        name: 'tutorReport',
        component: TutorReportPage,
      },
      {
        path: 'report/skill/:xref/problems',
        name: 'tutorProblemsByStandard',
        component: TutorProblemsByStandardPage,
      },
      // Handles empty /ldoe route
      {
        path: '',
        redirect: 'report',
      },
      // Catch all for /tutor/XXX routes
      {
        path: '*',
        redirect: 'report',
      },
    ],
  },
  {
    path: '/settings',
    // FIXME: Figure out if TEACHER only.
    beforeEnter: enterIfAuthenticated,
    component: SettingsPage,
    children: [
      {
        name: 'accountSettings',
        path: 'account-settings',
        component: AccountSettings,
      },
      {
        name: 'preferencesSettings',
        path: 'preferences',
        component: Preferences,
      },
      {
        name: 'featureSettings',
        path: 'feature-settings',
        component: FeatureSettings,
      },
      // Handles empty /settings route
      {
        path: '',
        redirect: 'account-settings',
      },
      // Catch all for /settings/XXX routes
      {
        path: '*',
        redirect: 'account-settings',
      },
    ],
  },
  {
    path: '/registration',
    // CANNOT limit to TEACHER only because the User may NOT have the role at the time they reach here.
    beforeEnter: enterIfAuthenticated,
    component: PassThrough,
    children: [
      {
        path: 'school',
        name: 'select-school',
        component: SelectSchool,
      },
      {
        path: 'gc',
        name: 'select-grade-curriculum',
        component: SelectGC,
      },
    ],
  },
  {
    path: '/insights-hub',
    component: InsightsHubParent,
    beforeEnter: (to, from, next) =>
      enterIfHasOneOfRoles(
        to,
        from,
        next,
        [UserRole.MENTOR],
        'accessToInsightsHub'
      ),
    children: [
      {
        path: '',
        name: 'insightsHubLanding',
        component: InsightsHubLandingPage,
      },
      {
        path: 'report',
        component: DashboardParent,
        children: [
          {
            path: 'curricula/activity',
            name: 'ActivityByCurriculumPage',
            component: ActivityByCurriculumPage,
          },
          {
            path: 'curricula/achievement',
            name: 'achievementPage',
            component: AchievementPage,
          },
          {
            path: 'standard',
            name: 'ActivityByStandardPage',
            component: ActivityByStandardPage,
          },
        ],
      },
    ],
  },
  {
    path: '/builder/problem-sets/:psXref',
    name: 'contentBuilder',
    component: ContentBuilderPage,
    beforeEnter: (to, from, next) =>
      enterIfHasOneOfRoles(to, from, next, [
        UserRole.CONTENT_ADMIN,
        UserRole.TRUSTED_BUILDER,
        UserRole.TEACHER,
        UserRole.RESEARCHER,
      ]),
  },
  {
    path: '/builder',
    component: BuilderParent,
    beforeEnter: (to, from, next) =>
      enterIfHasOneOfRoles(to, from, next, [
        UserRole.CONTENT_ADMIN,
        UserRole.TRUSTED_BUILDER,
        UserRole.TEACHER,
        UserRole.RESEARCHER,
      ]),
    children: [
      {
        path: 'main-hierarchy',
        name: 'mainHierarchyPage',
        component: MainHierarchyPage,
        meta: { page: PageView.MAIN_HIERARCHY },
      },
      {
        path: 'collections',
        name: 'collectionSettingsPage',
        component: CollectionSettingsPage,
      },
      {
        path: 'attributions',
        name: 'attributionsPage',
        component: AttributionsPage,
      },
      {
        path: 'global-problem-sets',
        name: 'globalProblemSetsPage',
        component: GlobalProblemSetsPage,
      },
    ],
  },
  {
    path: '/reports',
    name: 'reports',
    component: OverTimeLandingPage,
    beforeEnter: enterIfTeacher,
  },
  {
    path: '/reports/over-time',
    component: OverTimeReportParent,
    beforeEnter: enterIfTeacher,
    children: [
      {
        path: 'courses/:cxref',
        name: 'courseOverTimePage',
        component: CourseOverTimePage,
      },
      {
        path: 'courses/:cxref/students/:sxref?',
        name: 'studentOverTimePage',
        component: StudentOverTimePage,
      },
    ],
  },
  { path: '/401', component: UnauthenticatedPage },
  { path: '/403', component: UnauthorizedPage },
  { path: '/404', component: NotFoundPage },
  {
    path: '*',
    redirect: (to) => {
      // grab the target path in 'to' and add it as a param for support
      return {
        path: '/404',
        query: { [RETURN_URL]: to.fullPath },
      };
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

// Middleware application
router.afterEach(getAlertMessagesMiddleware);
router.beforeEach(reloadOnChangeMiddleware);

router.afterEach(afterEachReset);

export default router;
