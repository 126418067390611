import { IHasPermissions } from './Acls';

export enum ContentType {
  PROBLEM_SET = 'PROBLEM_SET',
  PROBLEM = 'PROBLEM',
  TUTOR_STRATEGY = 'TUTOR_STRATEGY',
}

export enum PersistableStateType {
  NEW_ITEM = 'NEW_ITEM',
  WORK_IN_PROGRESS = 'WORK_IN_PROGRESS',
  PUBLISHED = 'PUBLISHED',
}

export enum TransitionStateType {
  NEW_TO_WIP = 'NEW_TO_WIP',
  WIP_TO_WIP = 'WIP_TO_WIP',
  WIP_TO_PUB = 'WIP_TO_PUB',
  PUB_TO_WIP = 'PUB_TO_WIP',
  PUB_TO_PUB = 'PUB_TO_PUB',
  NEW_TO_PUB = 'NEW_TO_PUB',
}

export enum UserToContentRelationshipType {
  AUTHOR = 'AUTHOR',
  OWNER = 'OWNER',
  EDITOR = 'EDITOR',
}

export interface IBuilderContent extends IHasPermissions {
  xref: string;
  name?: string | null;
  contentType: ContentType;
  author: string;
  editor: string;
  owner: string;
  createdAt: number;
  updatedAt: number;
  enabled: boolean;
  // For WIP content, refers to Published version if any. For Published content, refers to WIP version if any.
  mappedCeri?: string;
}
