var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('v-dialog',{attrs:{"persistent":"","width":"600","scrollable":""},scopedSlots:_vm._u([{key:"activator",fn:function(scopeProps){return [_vm._t("activator",null,{"on":scopeProps.on,"attrs":scopeProps.attrs})]}}],null,true),model:{value:(_setup.showDialog),callback:function ($$v) {_setup.showDialog=$$v},expression:"showDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h3 font-weight-regular pl-10 pt-6 pr-4 pb-2"},[_c('span',[_vm._v(_vm._s(_vm.title))]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","large":"","disabled":_setup.assigning || _setup.updating},on:{"click":function($event){_setup.showDialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),(!_setup.assignmentMade)?_c('v-stepper',{staticClass:"stepper elevation-0",attrs:{"alt-labels":""},model:{value:(_setup.dialogStepper),callback:function ($$v) {_setup.dialogStepper=$$v},expression:"dialogStepper"}},[_c('v-stepper-header',{attrs:{"color":"white"}},[_c('v-stepper-step',{attrs:{"complete":_setup.dialogStepper > 1,"step":"1"}},[_c('span',{class:_setup.dialogStepper === 1
                  ? 'd-inline-block text-caption primary--text text--darken-3'
                  : 'd-inline-block text-caption black--text',staticStyle:{"white-space":"nowrap"}},[_vm._v(" Assignment Options ")])]),_c('v-divider'),(_vm.mode === _setup.Mode.ASSIGN || _vm.mode === _setup.Mode.REASSIGN)?_c('v-tooltip',{attrs:{"disabled":!_setup.isSkillBuilderPS,"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('div',_vm._g({},on),[_c('v-stepper-step',_vm._g({attrs:{"complete":_setup.dialogStepper > 2,"disabled":_setup.isSkillBuilderPS,"step":"2"}},on),[_c('span',{class:_setup.dialogStepper === 2
                        ? 'text-caption primary--text text--darken-3 '
                        : 'text-caption black--text'},[_vm._v(" Student Experience ")])])],1)]}}],null,false,1451508637)},[_c('span',[_vm._v("No Student Experience options available")])]):_vm._e(),(
              _setup.validClassCategoriesMap && _setup.validClassCategoriesMap.length > 0
            )?_c('v-divider'):_vm._e(),(
              _setup.validClassCategoriesMap && _setup.validClassCategoriesMap.length > 0
            )?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('div',_vm._g({},on),[_c('v-stepper-step',{attrs:{"complete":_setup.dialogStepper > 3,"step":"3"}},[_c('span',{class:_setup.dialogStepper === 3
                        ? 'text-caption primary--text text--darken-3'
                        : 'text-caption black--text'},[_vm._v(" Grading Category ")])])],1)]}}],null,false,3797042448)}):_vm._e()],1),_c('v-stepper-items',[_c('v-stepper-content',{attrs:{"step":"1"}},[_c('v-form',{model:{value:(_setup.formIsValid),callback:function ($$v) {_setup.formIsValid=$$v},expression:"formIsValid"}},[_c('v-card-text',{staticClass:"px-10"},[_c('v-container',{staticClass:"pa-0"},[_c('v-row',{staticClass:"mb-3"},[_c('v-col',[_c('v-text-field',{ref:"nameField",staticClass:"pt-2",attrs:{"data-cy":"assign-dialog-name","label":"Assignment Name*","required":"","outlined":"","rules":_setup.nameRules,"counter":_setup.nameCounter,"hide-details":"auto"},model:{value:(_setup.assignmentName),callback:function ($$v) {_setup.assignmentName=$$v},expression:"assignmentName"}})],1)],1),(!_setup.isLtiUser)?_c('v-row',[_c('v-col',{staticClass:"py-0"},[_c('v-select',{attrs:{"label":"Class*","data-cy":"assign-dialog-class","item-text":"name","item-value":"xref","required":"","rules":[
                          () =>
                            _setup.chosenClasses.length > 0 ||
                            'Choose classes to assign',
                        ],"outlined":"","multiple":"","items":_setup.importedClasses,"disabled":_vm.mode === _setup.Mode.EDIT || !_setup.chosenLms,"menu-props":{
                          offsetY: true,
                          offsetOverflow: true,
                          contentClass: 'class-menu',
                        }},scopedSlots:_vm._u([{key:"selection",fn:function({ item, index }){return [(
                              _setup.chosenClasses &&
                              _setup.chosenClasses.length === 1 &&
                              index === 0
                            )?_c('div',{staticClass:"v-select__select v-select__selection--comma",attrs:{"data-cy":"assign-dialog-class-option"}},[_vm._v(" "+_vm._s(item.name)+" ")]):(index === 0)?_c('div',{staticClass:"v-select__select v-select__selection--comma",attrs:{"data-cy":"assign-dialog-class-option"}},[_vm._v(" "+_vm._s(_setup.chosenClasses.length)+" Classes ")]):_vm._e()]}}],null,false,2583535951),model:{value:(_setup.chosenClasses),callback:function ($$v) {_setup.chosenClasses=$$v},expression:"chosenClasses"}}),(_vm.mode === _setup.Mode.ASSIGN || _vm.mode === _setup.Mode.REASSIGN)?_c('div',[_c('a',{staticClass:"font-weight-medium",on:{"click":function($event){if (_setup.chosenLms) {
                              _setup.importSyncDialog = true;
                            }}}},[_vm._v(" "+_vm._s(_setup.importSyncDialogButtonText)+" ")]),_c('v-alert',{attrs:{"dismissible":"","dense":"","type":_setup.alert.type},model:{value:(_setup.alert.show),callback:function ($$v) {_vm.$set(_setup.alert, "show", $$v)},expression:"alert.show"}},[_vm._v(" "+_vm._s(_setup.alert.msg)+" ")])],1):_vm._e()],1)],1):_vm._e(),(
                      _vm.mode !== _setup.Mode.EDIT && _setup.chosenClassesToAssign.length > 0
                    )?_c('v-row',[_c('v-col',[_c(_setup.AssigneeSelector,{attrs:{"enableStudentSelection":!_setup.isLtiUser,"courses":_setup.chosenClassesToAssign},model:{value:(_setup.assigneeMap),callback:function ($$v) {_setup.assigneeMap=$$v},expression:"assigneeMap"}})],1)],1):_vm._e(),(!_setup.isLtiUser)?_c('v-row',[_c('v-col',{staticClass:"pe-2",attrs:{"cols":"7"}},[_c('v-menu',{attrs:{"offset-y":"","bottom":"","offset-overflow":"","min-width":"0","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Release Date","append-icon":"mdi-calendar","outlined":"","clearable":"","disabled":_vm.mode === _setup.Mode.EDIT && _setup.releaseDatePast,"data-cy":"release-date-text-field","rules":[
                              () =>
                                _setup.relaseDateIsValid ||
                                'Please select a release date in future',
                            ],"hide-details":""},on:{"click:append":function($event){_setup.releaseDateMenu = true}},model:{value:(_setup.formattedReleaseDate),callback:function ($$v) {_setup.formattedReleaseDate=$$v},expression:"formattedReleaseDate"}},'v-text-field',attrs,false),on))]}}],null,false,2817838018),model:{value:(_setup.releaseDateMenu),callback:function ($$v) {_setup.releaseDateMenu=$$v},expression:"releaseDateMenu"}},[_c('v-date-picker',{attrs:{"data-cy":"release-date-date-picker","min":_setup.todayDate},on:{"input":function($event){_setup.releaseDateMenu = false}},model:{value:(_setup.releaseDate),callback:function ($$v) {_setup.releaseDate=$$v},expression:"releaseDate"}})],1)],1),_c('v-col',{attrs:{"cols":"5"}},[_c('v-combobox',{attrs:{"label":"Release Time","data-cy":"release-time-dropdown","items":_setup.time,"disabled":_setup.disableReleaseTime ||
                          (_vm.mode === _setup.Mode.EDIT && _setup.releaseDatePast),"append-icon":"mdi-clock","outlined":"","hide-details":""},model:{value:(_setup.formattedReleaseTime),callback:function ($$v) {_setup.formattedReleaseTime=$$v},expression:"formattedReleaseTime"}})],1)],1):_vm._e(),(!_setup.isLtiUser)?_c('v-row',[_c('v-col',{staticClass:"pe-2",attrs:{"cols":"7"}},[_c('v-menu',{attrs:{"offset-y":"","bottom":"","offset-overflow":"","min-width":"0","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":_setup.isEdlinkAssignment ? 'Due Date*' : 'Due Date',"append-icon":"mdi-calendar","outlined":"","required":_setup.isEdlinkAssignment,"rules":_setup.isEdlinkAssignment
                                ? [
                                    () =>
                                      !!_setup.formattedDueDate ||
                                      'Choose a due date',
                                  ]
                                : [],"clearable":"","data-cy":"due-date-text-field"},on:{"click:append":function($event){_setup.dueDateMenu = true}},model:{value:(_setup.formattedDueDate),callback:function ($$v) {_setup.formattedDueDate=$$v},expression:"formattedDueDate"}},'v-text-field',attrs,false),on))]}}],null,false,2840432804),model:{value:(_setup.dueDateMenu),callback:function ($$v) {_setup.dueDateMenu=$$v},expression:"dueDateMenu"}},[_c('v-date-picker',{attrs:{"data-cy":"due-date-date-picker","min":_setup.releaseDate ? _setup.releaseDate : _setup.todayDate},on:{"input":function($event){_setup.dueDateMenu = false}},model:{value:(_setup.dueDate),callback:function ($$v) {_setup.dueDate=$$v},expression:"dueDate"}})],1)],1),_c('v-col',{attrs:{"cols":"5"}},[_c('v-combobox',{attrs:{"label":"Due Time","data-cy":"due-time-dropdown","items":_setup.time,"disabled":_setup.disableDueTime,"append-icon":"mdi-clock","outlined":"","hide-details":""},model:{value:(_setup.formattedDueTime),callback:function ($$v) {_setup.formattedDueTime=$$v},expression:"formattedDueTime"}})],1)],1):_vm._e()],1)],1)],1)],1),_c('v-stepper-content',{attrs:{"step":"2"}},[_c('v-card-text',{staticClass:"px-10"},[_c('div',[_c('v-col',{staticClass:"pa-0 mb-8",attrs:{"cols":"8"}},[_c('v-select',{attrs:{"hide-details":"","label":"Problem Order","required":"","outlined":"","items":_setup.problemOrder,"disabled":_vm.mode === _setup.Mode.EDIT ||
                      (_setup.testMode && _setup.testModeAllowRevisions),"menu-props":{ offsetY: true, offsetOverflow: true }},scopedSlots:_vm._u([{key:"selection",fn:function({ item }){return [_vm._v(" "+_vm._s(_setup.getProblemSetTypeDisplayName(item))+" ")]}},{key:"item",fn:function({ item, on, attrs }){return [_c('v-list-item',_vm._g(_vm._b({},'v-list-item',attrs,false),on),[_vm._v(" "+_vm._s(_setup.getProblemSetTypeDisplayName(item))+" ")])]}}],null,false,2729360217),model:{value:(_setup.chosenProblemOrder),callback:function ($$v) {_setup.chosenProblemOrder=$$v},expression:"chosenProblemOrder"}})],1)],1),_c('div',{staticClass:"d-flex mb-2",staticStyle:{"gap":"8px"},attrs:{"data-cy":"test-mode-toggle"}},[_c('v-switch',{staticClass:"mt-0",attrs:{"label":"Test Mode","hide-details":"","disabled":_vm.mode === _setup.Mode.EDIT},model:{value:(_setup.testMode),callback:function ($$v) {_setup.testMode=$$v},expression:"testMode"}}),_c('v-icon',{attrs:{"color":"blue"},on:{"click":function($event){_setup.settingsGroup = _setup.SettingsGroup.TEST_MODE;
                    _setup.showInfoDialog = true;}}},[_vm._v("mdi-information-outline")])],1),_c('v-expand-transition',[(_setup.testMode)?_c('div',{staticClass:"pl-6 mb-4 test-mode-options-container"},[_c('v-checkbox',{staticClass:"mt-0",attrs:{"data-cy":"revisions-checkbox","hide-details":!_setup.testModeAllowRevisions,"messages":_setup.testModeAllowRevisions
                        ? 'Problem order must be student choice when allowing revisions'
                        : ''},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{class:_setup.testModeAllowRevisions
                            ? 'primary--text text--darken-3'
                            : ''},[_vm._v(" Allow revisions before final submission ")])]},proxy:true}],null,false,452284630),model:{value:(_setup.testModeAllowRevisions),callback:function ($$v) {_setup.testModeAllowRevisions=$$v},expression:"testModeAllowRevisions"}}),_c('v-checkbox',{staticClass:"mt-1",attrs:{"hide-details":"","data-cy":"test-mode-report-checkbox"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{class:_setup.testModeStudentReport
                            ? 'primary--text text--darken-3'
                            : ''},[_vm._v(" Deliver student assignment report upon completion ")])]},proxy:true}],null,false,1767557789),model:{value:(_setup.testModeStudentReport),callback:function ($$v) {_setup.testModeStudentReport=$$v},expression:"testModeStudentReport"}})],1):_vm._e()]),(!_setup.isSkillBuilderPS)?_c('div',{staticClass:"d-flex justify-space-between mb-2",attrs:{"data-cy":"time-limit-toggle"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"gap":"8px"}},[_c('v-switch',{staticClass:"mt-0",attrs:{"label":"Set Time Limit","hide-details":"","disabled":_vm.mode === _setup.Mode.EDIT},model:{value:(_setup.hasTimeLimit),callback:function ($$v) {_setup.hasTimeLimit=$$v},expression:"hasTimeLimit"}}),_c('v-icon',{staticStyle:{"height":"24px"},attrs:{"color":"blue"},on:{"click":function($event){_setup.settingsGroup = _setup.SettingsGroup.TIME_LIMIT;
                      _setup.showInfoDialog = true;}}},[_vm._v(" mdi-information-outline ")])],1),(_setup.hasTimeLimit)?_c('div',{staticClass:"d-flex justify-space-between align-center",staticStyle:{"gap":"8px"}},[_c('v-text-field',{attrs:{"label":"Time Limit","outlined":"","type":"number","max":"60","rules":[_setup.timeLimitRules.amount, _setup.timeLimitRules.length],"data-cy":"time-limit-input"},on:{"focus":function($event){return $event.target.select()}},model:{value:(_setup.timeLimit),callback:function ($$v) {_setup.timeLimit=$$v},expression:"timeLimit"}}),_vm._v(" minutes ")],1):_vm._e()]):_vm._e(),_c('div',{staticClass:"d-flex mb-2",staticStyle:{"gap":"8px"},attrs:{"data-cy":"redo-toggle"}},[_c('v-switch',{staticClass:"mt-0",attrs:{"label":"Redo","hide-details":"","disabled":_vm.mode === _setup.Mode.EDIT},model:{value:(_setup.useRedo),callback:function ($$v) {_setup.useRedo=$$v},expression:"useRedo"}}),_c('v-icon',{attrs:{"color":"blue"},on:{"click":function($event){_setup.settingsGroup = _setup.SettingsGroup.REDO;
                    _setup.showInfoDialog = true;}}},[_vm._v(" mdi-information-outline ")])],1),(_setup.caitEnabled)?_c('div',{staticClass:"d-flex mb-2",staticStyle:{"gap":"8px"},attrs:{"data-cy":"cait-toggle"}},[_c('v-switch',{staticClass:"mt-0",attrs:{"label":"Cait","hide-details":"","disabled":_vm.mode === _setup.Mode.EDIT},model:{value:(_setup.useCait),callback:function ($$v) {_setup.useCait=$$v},expression:"useCait"}}),_c('v-icon',{attrs:{"color":"blue"},on:{"click":function($event){_setup.settingsGroup = _setup.SettingsGroup.CAIT;
                    _setup.showInfoDialog = true;}}},[_vm._v(" mdi-information-outline ")])],1):_vm._e(),_c('v-row',[_c('v-col',[_c('v-container',{staticClass:"px-0",attrs:{"fluid":""}},[_c('span',{staticClass:"mr-2"},[_vm._v("Student Score Delivery")]),_c('v-icon',{attrs:{"color":"blue"},on:{"click":function($event){_setup.settingsGroup = _setup.SettingsGroup.SCORE_DELIVERY;
                        _setup.showInfoDialog = true;}}},[_vm._v(" mdi-information-outline ")]),_c('v-radio-group',{staticClass:"mt-2 mb-0",attrs:{"row":""},model:{value:(_setup.deliveryOfStudentScore),callback:function ($$v) {_setup.deliveryOfStudentScore=$$v},expression:"deliveryOfStudentScore"}},[_c('v-radio',{attrs:{"label":"Success Symbols","data-cy":"show-symbols-radio-btn","value":false}}),_c('v-radio',{attrs:{"label":"Score and Class Average","data-cy":"show-scores-radio-btn","value":true}})],1)],1)],1)],1)],1)],1),_c('v-stepper-content',{attrs:{"step":"3"}},[_c('v-card-text',{staticClass:"px-10"},[_c('div',{staticClass:"d-flex justify-center"},[_c('v-card-title',{staticClass:"text-h5 font-weight-regular"},[_vm._v(" Select a grading category for each class. ")])],1),_c('v-list',_vm._l((_setup.validClassCategoriesMap),function(entry){return _c('v-list-item',{key:entry.courseXref},[_c('v-row',{staticClass:"w-100",attrs:{"align":"center"}},[_c('v-col',{staticClass:"d-flex justify-center mb-6",attrs:{"cols":"3"}},[_c('span',{staticClass:"text-subtitle-2 black--text"},[_vm._v(" "+_vm._s(_setup.courseTitles[entry.courseXref])+" ")])]),_c('v-col',{attrs:{"cols":"9"}},[_c('v-select',{attrs:{"items":entry.categories,"item-text":"title","item-value":"uid","label":"Grading Category*","data-cy":"assign-dialog-categories","outlined":"","required":"","rules":_setup.categoryRules,"menu-props":{
                          offsetY: true,
                          offsetOverflow: true,
                          contentClass: 'class-menu',
                        }},model:{value:(_setup.selectedClassCategory[entry.courseXref]),callback:function ($$v) {_vm.$set(_setup.selectedClassCategory, entry.courseXref, $$v)},expression:"selectedClassCategory[entry.courseXref]"}})],1)],1)],1)}),1)],1)],1)],1)],1):_vm._e(),(!_setup.assignmentMade)?_c('div',[_c('v-card-actions',{staticClass:"ma-2"},[(_setup.dialogStepper === 1)?_c('v-card-text',{staticClass:"px-6 pb-0 mt-2 neutral--text text--darken-1"},[_c('span',[_vm._v("*Required")])]):_vm._e(),_c('v-spacer'),(_setup.dialogStepper > 1)?_c('v-btn',{attrs:{"data-cy":"assign-dialog-back-btn","color":"primary","text":""},on:{"click":function($event){_setup.dialogStepper -= 1}}},[_vm._v(" Back ")]):_vm._e(),_c('v-btn',{attrs:{"data-cy":"assign-dialog-cancel-btn","color":"primary","text":"","disabled":_setup.assigning || _setup.updating},on:{"click":function($event){_setup.showDialog = false}}},[_vm._v(" Cancel ")]),(_vm.mode === _setup.Mode.ASSIGN || _vm.mode === _setup.Mode.REASSIGN)?_c('v-tooltip',{attrs:{"top":"","disabled":_setup.formIsValid},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [(
                  _setup.isSkillBuilderPS ||
                  (_setup.dialogStepper === 2 &&
                    _setup.validClassCategoriesMap &&
                    _setup.validClassCategoriesMap.length === 0) ||
                  _setup.dialogStepper === 3
                )?_c('v-btn',_vm._g({attrs:{"color":"primary","depressed":"","data-cy":"assign-dialog-completed-button","disabled":(!_setup.isLtiUser &&
                    (_setup.assigneeMap.size === 0 ||
                      _setup.chosenClassesAreNotAssigned)) ||
                  (_setup.hasTimeLimit && !_setup.isTimeLimitValid) ||
                  _setup.isWipPS ||
                  !_setup.isCategoriesSelected,"loading":_setup.assigning},on:{"click":_setup.assign}},on),[_vm._v(" "+_vm._s(_setup.assignButtonText)+" ")]):(_setup.isNextButtonVisible)?_c('v-btn',_vm._g({attrs:{"color":"primary","data-cy":"assign-dialog-button","disabled":(!_setup.isLtiUser && !_setup.formIsValid) ||
                  (_setup.hasTimeLimit && !_setup.isTimeLimitValid)},on:{"click":function($event){_setup.dialogStepper += 1}}},on),[_vm._v(" Next ")]):_vm._e()]}}],null,false,1977498516)},[_c('span',[_vm._v("Complete all required fields")])]):_vm._e(),(_vm.mode === _setup.Mode.EDIT)?_c('v-btn',{attrs:{"data-cy":"update-assignment-button","color":"primary","depressed":"","disabled":!_setup.hasModifiedFields,"loading":_setup.updating},on:{"click":_setup.update}},[_vm._v(" Update Assignment ")]):_vm._e()],1)],1):_c('div',[_c('v-card-text',{staticClass:"px-10"},[_c('span',[_vm._v("Assignment successfully created.")])]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","data-cy":"assign-dialog-completed-button","depressed":""},on:{"click":function($event){_setup.showDialog = false}}},[_vm._v(" DONE ")])],1)],1)],1)],1),_c(_setup.ImportSyncDialog,{attrs:{"value":_setup.importSyncDialog,"defaultLms":_setup.chosenLms},on:{"closeImportSyncDialog":function($event){_setup.importSyncDialog = false},"showAlert":_setup.showAlert,"popupBlocked":_setup.popupBlocked}}),_c(_setup.PopupBlockedDialog,{attrs:{"error":_setup.assignError},model:{value:(_setup.popupBlockedDialog),callback:function ($$v) {_setup.popupBlockedDialog=$$v},expression:"popupBlockedDialog"}}),_c(_setup.SettingsInformationDialog,{attrs:{"settingsGroup":_setup.settingsGroup},model:{value:(_setup.showInfoDialog),callback:function ($$v) {_setup.showInfoDialog=$$v},expression:"showInfoDialog"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }